import { Component, OnInit,Input, Output, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core'; 
import { MessageService } from '@core';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { STComponent, STColumn, STData, STChange, STPage } from '@delon/abc';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { tap, map } from 'rxjs/operators';
import { mockUrl, baseUrl, access_token } from '@core';
@Component({
  selector: 'app-poschange',
  templateUrl: './poschange.component.html',
  styleUrls: ['./poschange.component.less']
})
export class PoschangeComponent implements OnInit {
  i: any;
  //父组件传值（之前选中的岗位列表）
  @Input() jobList:any;
  //父组件传值（主岗位）
  @Input() mainJob: any;;
  /**
   * tree
   */
  treeLoading = false;
  //tree 数据源
  nodes = [];
  //当前选中的节点
  selectNode: any = {};
  searchParams: any = {};
  searchValue = '';

  
  style = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };

  /**
   * 加载 tree 数据
   */
  loadTreeData() {
    this.treeLoading = true;
    this.http.get<any>(`${baseUrl}/admin/v1/depts/tree`).subscribe(res => {
      this.treeLoading = false;

      if (res.code == 0) {
        this.nodes = res.data || [];

        //标识叶子节点
        // const recursion = (arr) => {
        const recursion = (arr, lv) => {
          arr.forEach(node => {
            // 前端数据转换
            node.title = node.name;
            node.key = node.code;
            this.selectNode.key == null && (this.selectNode = node);

            if (Array.isArray(node.children) && node.children.length) {
              // 展开第一层节点
              lv == 1 && (node.expanded = true);
              recursion(node.children, ++lv);
            } else {
              node.isLeaf = true;

            }

          });
        };

        recursion(this.nodes, 1);

        //加载表格数据
        if (this.selectNode.key) {
          this.getData(true);
        }

      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

  /**
     * 节点选中事件 => 加载对应的表格数据
     */
  onSelectNode(node) {
    if (node.key != this.selectNode.key) {
      this.selectNode = node;
      this.getData(true);
    }
    this.selectNode = {};
    setTimeout(() => {
      this.selectNode = node;
    }, 0);
  }
  constructor(public msg: MessageService,
    private http: _HttpClient,
    private cdr: ChangeDetectorRef, private modal: NzModalRef
  ) { }

  /**
    * table
    */
  table = {
    loading: false,
    searchParams: <any>{},
    rows: [],
    allRows: [],
    pageIndex: 1,
    total: 0,
    pageSize: 10,
    isAllChecked: false,
    isIndeterminate: false,
    currentPageRows: [],
    mapOfCheckedId: {},
    checkedRows: []
  };
  ngOnInit() {
    this.loadTreeData();

    /**
     * 添加要回显的数据
     */
    // (this.i.jobList || []).forEach(row => {
    //   this.table.mapOfCheckedId[row.id] = true;
    //   this.table.checkedRows.push(row);
    // });
    // this.mainJob = this.i.mainJob || null;
    (this.jobList || []).forEach(row => {
      this.table.mapOfCheckedId[row.id] = true;
      this.table.checkedRows.push(row);
    });
    this.mainJob = this.mainJob || null;
  }

  getData(reset: boolean = false) {
    if (reset) {
      this.table.pageIndex = 1;
    }

    let searchParams = {
      ...this.searchParams,
      deptCode: this.selectNode.key,
      current: this.table.pageIndex,
      size: this.table.pageSize
    };

    this.table.loading = true;
    this.http.get<any>(`${baseUrl}/admin/v1/jobs`, searchParams).subscribe(res => {
      this.table.loading = false;
      if (res.code == 0) {
        this.table.rows = res.data.records || [];
        this.table.rows.forEach((v, i) => v.no = (i + 1));
        this.table.total = res.data.total || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }

    });
  }
  /**
   * 当前页数据改变
   * @param $event 
   */
  onCurrentPageDataChange($event) {
    this.table.currentPageRows = $event;
    this.refreshCheckedStatus(this.table);
  }

  /**
   * 刷新表格的 checked 状态
   */
  refreshCheckedStatus(table) {
    table.isAllChecked = table.currentPageRows.filter(item => !item.disabled).every(item => table.mapOfCheckedId[item.id]);
    table.isIndeterminate = table.currentPageRows.filter(item => !item.disabled).some(item => table.mapOfCheckedId[item.id]) && !table.isAllChecked;

    table.currentPageRows.forEach(row => {
      let currentIndex = table.checkedRows.findIndex(v => v.id == row.id);
      if (table.mapOfCheckedId[row.id]) {
        currentIndex == -1 && table.checkedRows.push(row);
      } else {
        currentIndex != -1 && table.checkedRows.splice(currentIndex, 1);
      }
    });
    table.checkedRows = [...table.checkedRows];

    this.updateMainJob();
  }

  /**
   * 全选 checkbox nzCheckedChange事件
   */
  checkAll(table, isAllChecked) {
    table.currentPageRows.filter(item => !item.disabled).forEach(item => (table.mapOfCheckedId[item.id] = isAllChecked));
    this.refreshCheckedStatus(table);
  }

  /**
   * 移除已选中的岗位
   * @param row 行数据
   */
  onRemoveJob(row) {
    delete this.table.mapOfCheckedId[row.id];

    let currentIndex = this.table.checkedRows.findIndex(v => v.id == row.id);
    currentIndex != null && this.table.checkedRows.splice(currentIndex, 1);

    this.refreshCheckedStatus(this.table);
  }

  /**
   * 更新主岗位
   */
  updateMainJob() {
    if (!this.table.checkedRows.find(row => row.id == this.mainJob) && this.table.checkedRows.length) {
      this.mainJob = this.table.checkedRows[0].id;
    }
  }


  submit() {
    // this.i.mainJob = this.mainJob;
    // this.i.jobList = this.table.checkedRows;
    // this.i.jobId = this.i.jobList.map(v => v.id);
    // this.i.jobName = this.i.jobList.map(v => v.jobName).join(',');
    // this.i.deptName = this.i.jobList.map(v => v.deptName).join(',');
    // this.i.postName = this.i.jobList.map(v => v.postName).join(',');
    let res = {
      mainJob:this.mainJob,
      jobList:this.table.checkedRows
    }

    this.modal.destroy(res);
  }

  close() {
    this.modal.destroy();
  }
}

