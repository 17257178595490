import { tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { CommonService } from './common.service';
import { baseUrl } from './constant';
import { MessageService } from './message.service';

/**
 * 有关用户权限（比如登录，登出，查询权限等）的服务。
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private commonService: CommonService,
    private http: HttpClient,
    private router: Router,
    private message: MessageService,
  ) {}

  /**
   * 退出登录
   */
  logout(redirectUrl?: string) {
    const loginUrl = redirectUrl || this.tokenService.login_url;
    return this.http.delete(`${baseUrl}/auth/token/logout`).pipe(
      tap(() => {
        this.tokenService.clear();
        this.commonService.loginBeforeUrl = window.location.hash.slice(1);
        this.router.navigateByUrl(loginUrl);
        this.message.success('退出成功！');
      }),
    );
  }
}
