import { isToday, format } from 'date-fns';
const list = [];
export const STATIONS = {
  '/stations': {
    total: 12,
    list: [
      {
        key: 1,
        sort: 3,
        position: '行政',
        stations: '8',
        postnums: '6',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 2,
        sort: 2,
        position: '开发',
        stations: '22',
        postnums: '18',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 3,
        sort: 3,
        position: '人事',
        stations: '6',
        postnums: '8',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 4,
        sort: 3,
        position: '行政',
        stations: '8',
        postnums: '6',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 5,
        sort: 2,
        position: '开发',
        stations: '22',
        postnums: '18',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 6,
        sort: 3,
        position: '人事',
        stations: '6',
        postnums: '8',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 7,
        sort: 3,
        position: '行政',
        stations: '8',
        postnums: '6',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 8,
        sort: 2,
        position: '开发',
        stations: '22',
        postnums: '18',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 9,
        sort: 3,
        position: '人事',
        stations: '6',
        postnums: '8',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 10,
        sort: 3,
        position: '行政',
        stations: '8',
        postnums: '6',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 11,
        sort: 2,
        position: '开发',
        stations: '22',
        postnums: '18',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      },
      {
        key: 12,
        sort: 3,
        position: '人事',
        stations: '6',
        postnums: '8',
        creater: '王建',
        createdat:format(new Date(), 'YYYY-MM-DD')
      }
    ],
  },
};
export const STATION = {
  '/station': {
    list: [
      {id:1,name:'安全员'},
      {id:2,name:'班组长'},
      {id:3,name:'车间长'},
      {id:4,name:'主任'},
      {id:5,name:'部长'},
      {id:6,name:'总经理'}
    ],
  },
};
