import { Injectable, Inject } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import {
  CanActivate, CanActivateChild, CanDeactivate, Resolve, CanLoad,
  ActivatedRouteSnapshot, RouterStateSnapshot, Router
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { baseUrl } from '@core/constant';
// 具有离开守卫组件的接口
export interface CanDeactivateComponent {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RouterGuardService implements CanActivate {
  datav2: any = [{
    'url': 'data-lsd-v2',
    'name': '安全信息公示平台',
    'data': 0
  }, {
    'url': 'data-edu-v2',
    'name': '校园安全信息公示平台',
    'data': 5
  }, {
    'url': 'data-lsd-build',
    'name': '建筑业安全信息公示平台',
    'data': 1
  }, {
    'url': 'data-traffic-v2',
    'name': '安全生产信息公示平台',
    'data': 3
  }, {
    'url': 'data-health',
    'name': '卫生行业安全信息公示平台',
    'data': 2
  }, {
    'url': 'data-culture',
    'name': '文化旅游行业安全信息公示平台',
    'data': 4
  }, {
    'url': 'data-lsd-v3',
    'name': '安全信息公示平台',
    'data': 6
  },{
      'url': 'data-lsd-v4',
      'name': '安全信息公示平台',
      'data': 7
    }];
  constructor(private http: HttpClient,
    private router: Router,
    private msg: NzMessageService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    /*
    *  大屏类型  0工贸，1建筑，2卫生，3交通，4文化，5教育，6私有化公司
   */
    return this.http.get(`${baseUrl}/basics/v1/companys/type`).pipe(map((res: any) => {

      if (res.code == '0') {
        // res.data
        let cur = this.datav2.filter(item => item.url == route.routeConfig.path) [0].data;
       // if ((cur === res.data)) {
         //卫生和文化直接跳转默认路由
        if (cur === res.data) {
          return true;
        } else {
          this.router.navigateByUrl(`/`);
          return false;
        }
      }
    }));
  }

}

