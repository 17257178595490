import { HiddenStatistics } from '../interface/api.interface';
import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { _HttpClient } from '@delon/theme';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {  baseUrl } from '@core';
import {
  DataService,
  compareRiskTypes,
  HiddenAndRiskNotification,
} from '../../big-screen/health/services/data.service';
import { FormControl } from '@angular/forms';
import { startWith, map, tap } from 'rxjs/operators';
import { EChartOption } from 'echarts';
import { format, startOfYear } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, DocumentService } from '@core';
type HiddenRiskStatisticItem = [string, number | string];

type CompareRiskType = 'LS' | 'LEC' | 'MES';

@Component({
  selector: 'app-data-lsd-v5',
  templateUrl: './data-lsd-v5.component.html',
  styleUrls: ['./data-lsd-v5.component.scss'],
})
export class DataLsdV5Component implements OnInit {
  /*
   *  公司名称和logo
   */
  companyInfo: any;
  /*
   *  系统消息
   */
  systemNotice: any;
  /**
   * left side data source
   */
  hiddenAndRiskNotifications: Observable<HiddenAndRiskNotification[]>;

  hiddenTypeStatistics: Observable<EChartOption>;

  hiddenByDepartment: Observable<HiddenStatistics[]>;

  hiddenByLocation: Observable<HiddenStatistics[]>;

  /**
   * middle bottom statistics
   */
  hiddenRiskStatistics: Observable<HiddenRiskStatisticItem[]>;

  /**
   * right side charts data source
   */
  hiddenPolylineChartOptions: Observable<EChartOption>;

  riskByLocationChartOptions: Observable<EChartOption>;

  compareRiskChartOptions: Observable<EChartOption>;

  compareRiskValueControl: FormControl = new FormControl('LS');

  //第三方token
  token: any;

  realTime:any;
  unaccept:any;
  unprocessed:any;
  processing:any;
  finished:any

  //设备总览 
  option:any;
  optionState:any;
  normal:any;
  disconnect:any;

  //设备系统
  option1:any;
  systemCount:any;
  systemList:any;
  systemSum:any;
  timer;
  index = 0;
  slist:any;
  
  /**
   * control
   */
  isRadarChartDisplay = false;

  compareRiskValues: string[] = [
    compareRiskTypes[1],
    compareRiskTypes[2],
    compareRiskTypes[3],
  ];

  constructor(
    private http: _HttpClient,
    private dataService: DataService,
    private translate: TranslateService,
    private authService: AuthService,
    private documentService: DocumentService,
    private httpClient: HttpClient,
  ) {
        this.timer = setInterval(() => {//设置定时刷新事件，每隔3s刷新
          // this.setoption(this.index); 
          this.scrollBar(this.index);
        }, 6000)
  }

  ngOnInit() {
    this.translate.setDefaultLang('zh-CN');
    this.translate.use('zh-CN');
    this.initModel();
    this.getToken();
    // this.setoption(this.index); 
  }

  initModel() {
    const dateFormat = 'YYYY-MM-DD';
    const endDay = format(new Date(), dateFormat);
    const startDay = format(startOfYear(new Date()), dateFormat);
    const sortByTotal = (data: HiddenStatistics[]): HiddenStatistics[] => {
      data.sort(
        (statistic1: HiddenStatistics, statistic2: HiddenStatistics) => {
          const value1 = statistic1.greatCount + statistic1.generalCount;
          const value2 = statistic2.greatCount + statistic2.generalCount;

          if (value1 < value2) {
            return 1;
          } else if (value1 === value2) {
            return 0;
          } else {
            return -1;
          }
        },
      );
      return data;
    };

    this.hiddenAndRiskNotifications = this.dataService.getHiddenAndRiskNotifications();

    this.hiddenTypeStatistics = this.dataService
      .getHiddenTypeRadarOptions()
      .pipe(tap(res => console.log(res)));

    this.hiddenByDepartment = this.dataService
      .getHiddenStatisticsByDepartment({
        startDay,
        endDay,
      })
      .pipe(
        map(sortByTotal),
        startWith([]),
      );

    this.hiddenByLocation = this.dataService
      .getHiddenStatisticsByLocation({
        startDay,
        endDay,
      })
      .pipe(
        map(sortByTotal),
        startWith([]),
      );

    this.hiddenRiskStatistics = this.dataService
      .getHiddenAndRiskStatistics([
        'redRiskPointCount',
        'orangeRiskPointCount',
        'yellowRiskPointCount',
        'blueRiskPointCount',
        'riskPointCount',
        'notClosedPotentialCount',
        'currentYearMajorNumber',
        'currentYearGeneralNumber',
        'currentMonthTotalNumber',
        'currentMonthRectificationRate',
      ])
      .pipe(map(data => data.map(obj => Object.entries(obj)[0]))); // only one field in obj;

    this.hiddenPolylineChartOptions = this.dataService.getHiddenPolylineChartOptions(
      ['generalCount', 'greatCount', 'total'],
    );

    this.riskByLocationChartOptions = this.dataService.getHealthRiskColumnChartOptions(
      ['greatRiskCount', 'relativeRiskCount', 'generalRiskCount'],
    );

    this.compareRiskChartOptions = combineLatest(
      this.compareRiskValueControl.valueChanges.pipe(startWith('LS')),
      this.dataService.getActivityRiskColumnChartOptions(),
      (selectedType: CompareRiskType, options) => {
        const idx = +compareRiskTypes[selectedType] - 1;
        return options[idx];
      },
    );

    this.dataService.getEnterpriseNameAndLogoRequest().subscribe((res: any) => {
      this.companyInfo = res.fullName;
    });
    this.dataService.getSystemNotice().subscribe((res: any) => {
      this.systemNotice = res.notice[0];
    });
  }
  onShut() {
    this.authService.logout().subscribe();
  }
  ngAfterViewInit() {
    this.documentService.setBodyOverflowHidden();
  }

  ngOnDestroy() {
    this.documentService.setBodyOverflowScroll();
  }
  getToken() {
    this.http
      .get<any>(`${baseUrl}/admin/lins/auth`)
      .subscribe(res => {
        if (res.code == 0) {
          this.token = res.data.token;
          this.getRealTime(res.data.token);
          this.getOverview(res.data.token);
          this.getSystem(res.data.token);
          this.getSystemSum(res.data.token);
        }
      });
  }
  getRealTime(token) {
    //请求头
    let headers = new HttpHeaders({
      Authorization:
      'Bearer ' + token,
      token: '',
    });
    function $(id){
      return typeof id==='string'?document.getElementById(id):id;
    }

      // var index=0;
      var timer=null;
      var lis=$('notice-tit').getElementsByTagName('li');
      // for(var i=0;i<lis.length;i++){
      //   lis[i].id=i;
      //   lis[i].onmouseover=function(){
      //     clearInterval(timer);
      //     this.changeOption(lis[i].id);
      //   }
      //   lis[i].onmouseout=function(){ 
      //     timer=setInterval(()=>this.autoPlay(lis),3000);   
      //   }
      // }
      // if(timer){
      //   clearInterval(timer);
      //   timer=null;
      // }
      timer=setInterval(()=>this.autoPlay(lis),3000);


    
    this.httpClient.get(
        `https://lins-api.sensoro.com/statistics/v1/statistics/alarms/statusCounts`,
        { headers },
      )
      .subscribe((res:any) => {
   
        if (res.code == 0) {
          this.realTime = res.data;
          this.unaccept = res.data.unaccept;
          this.unprocessed = res.data.unprocessed;
          this.processing = res.data.processing;
          this.finished = res.data.finished;
        } else {
          console.log('error');
        }
      });
  }

   autoPlay(lis){
     var num = this.index;
     this.index++;
    if(num==lis.length-1){
      this.index=0;
    }
    this.changeOption(num,lis);
  }

   changeOption(num,lis){
    for(var j=0;j<lis.length;j++){
      lis[j].className='';
    }
    console.log(num,'rrrrrrrr');
    lis[num].className='select';
    this.slist = lis;
    this.setoption(num);
  }
  selectSystem(i){
    for(var j=0;j<this.slist.length;j++){
      this.slist[j].className='';
    }
    this.slist[i].className='select';
    this.setoption(i);
  }
  //滚动条 自动滚动
  scrollBar(index) {
    var parent = document.getElementById('notice-u');
       		if( index+1 > 9 ) {
               parent.scrollTop = 0;
          } else {
           		parent.scrollTop+=30;
          }
    }

  //设备总览
  getOverview(token){
    //请求头
    let headers = new HttpHeaders({
      Authorization:
      'Bearer ' + token,
      token: '',
    });
    this.httpClient.get(
      `https://lins-api.sensoro.com/statistics/v1/statistics/devices/status/count`,
      { headers },
      )
      .subscribe((res:any) => {
        console.log(res, 'ttttttttime');
        if (res.code == 0) {
        // this.optionState=res.data;
        // this.normal = !!res.data[4] ? res.data[4].value : 0;
        // this.disconnect =  !!res.data[5] ? res.data[5].value : 0;
        for (let i = 0; i< res.data.length;i++){
          if(res.data[i].status=="NORMAL"){
            this.normal = !!res.data[i] ? res.data[i].value : 0;
          }else if (res.data[i].status=="DISCONNECT"){
            this.disconnect = !!res.data[i] ? res.data[i].value : 0;
          }
        }

        this.option = {
          tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c}'
          },
          title: {
            text: '设备总览',  //图形标题，配置在中间对应效果图的80%
            left: "center",
            top: "53%",
            textStyle: {
              color: "rgb(50,197,233)",
              fontSize: 12,
              align: "center"
            }
          },
          graphic: {
            type: 'text',      
            top: 'center',   
            left: 'center',            
            style: {
                text: this.normal + this.disconnect,       
                fill: '#fff',           
                fontSize: 16,           
                fontWeight: 'bold'		
            }
          },
          series: [
              {
                  name: '设备',
                  type: 'pie',
                  radius: ['50%', '64%'],
                  avoidLabelOverlap: false,
                  label: {
                      show: false,
                      position: 'center'
                  },
                  labelLine: {
                      show: false
                  },
                  data: [
                      {value: this.normal, name: '正常'},
                      {value: this.disconnect, name: '离线'},
                  ]
              }
          ]
      };
      } else {
        console.log('error');
      }
    });
  }

  //设备数量
  getSystemSum(token){
    let headers = new HttpHeaders({
      Authorization:
      'Bearer ' + token,
      token: '',
    });
    
    this.httpClient.get(
      `https://lins-api.sensoro.com/statistics/v1/statistics/devices/domains`,
      { headers },
    ).subscribe((res:any) => {
      if (res.code == 0) {
        this.systemSum = Object.values(res.data);
      }
    });
  }
  //设备系统
  getSystem(token){
    let headers = new HttpHeaders({
      Authorization:
      'Bearer ' + token,
      token: '',
    });
    
    this.httpClient.get(
      `https://lins-api.sensoro.com/device/v1/deviceConfig`,
      { headers },
    ).subscribe((res:any) => {
      if (res.code == 0) {
        this.systemCount = Object.values(res.data.domain);
      }
    });
  }
  setoption(num){
    this.option1 = {
      tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}'
      },
      title: {
        text: '设备数量',  //图形标题，配置在中间对应效果图的80%
        left: "center",
        top: "53%",
        textStyle: {
          color: "rgb(50,197,233)",
          fontSize: 12,
          align: "center"
        }
      },
      graphic: {
        type: 'text',      
        top: 'center',   
        left: 'center',            
        style: {
            text: this.systemSum[num] || 0,    
            fill: '#fff',           
            fontSize: 16,           
            fontWeight: 'bold'		
        }
      },
      series: [
          {
              name: '设备',
              type: 'pie',
              radius: ['50%', '62%'],
              avoidLabelOverlap: false,
              label: {
                  show: false,
                  position: 'center'
              },
              labelLine: {
                  show: false
              },
              data: [
                  {value: this.systemSum[num] || 0, name: '设备数量'},
                  // {value: this.optionState[1].value, name: '离线'},
              ]
          }
      ]
  };
  }

  
}
