import {
  Component,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '@core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { baseUrl, CommonService } from '@core';
import { LazyService } from '@delon/util';
import { CacheService } from '@delon/cache';
import { AgreeComponent } from './agree/agree.component';
import { UploadChangeParam, NzModalService } from 'ng-zorro-antd';
import { ActivatedRoute, Router } from '@angular/router';
declare var AMap: any; //高德地图
@Component({
  selector: 'app-enterprise-apply',
  templateUrl: './enterprise-apply.component.html',
  styleUrls: ['./enterprise-apply.component.scss'],
})
export class EnterpriseApplyComponent implements AfterViewInit {
  validateForm!: FormGroup;

  // 省市区数据
  provinceData: any[] = [];
  cityData: any[] = [];
  districtData: any[] = [];
  townData: any[] = [];
  villageData: any[] = [];
  // 经纬度模态框
  isVisible = false;
  isOkLoading = false;
  map: any = {};
  zoom: number = 14;
  longitude: any = null;
  latitude: any = null;
  loading = false;
  uploadAction = `${baseUrl}/basics/v1/file`;
  fileList = [];
  file = [];
  operationCenterId = null;
  isShow = false;
  referrerId = null;
  posterId = null;

  aiBusinessData: any = null;

  spinLoading: boolean = false;

  isKefuShow: boolean = true;

  isVisibleKefu: boolean = false;

  aiBusinessLicenseMsg: string = '';

  count = 0;
  interval$: any;

  isCreditCode=false;

  check ={
    loading: false
  }

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private http: HttpClient,
    private httpClient: HttpClient,
    private msg: MessageService,
    private cdr: ChangeDetectorRef,
    private modal: ModalHelper,
    private lazy: LazyService,
    public srv: CacheService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NzModalService,
  ) {
    this.validateForm = this.fb.group({
      fullName: [null, [Validators.required]],
      industry: [null, [Validators.required]],
      province: [null, [Validators.required]],
      city: [null, [Validators.required]],
      district: [null, [Validators.required]],
      town: [null, [Validators.required]],
      village: [null, [Validators.required]],
      addressDetail: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
      latitude: [null, [Validators.required]],
      account: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      code: [null, [Validators.required]],
      passWord: [
        null,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15),
        ],
      ],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      agree: [null],
      enterpriseId: [null, [Validators.required]],
    });
  }

  get fullName() {
    return this.validateForm.controls.fullName;
  }

  get province() {
    return this.validateForm.controls.province;
  }

  get city() {
    return this.validateForm.controls.city;
  }

  get district() {
    return this.validateForm.controls.district;
  }

  get addressDetail() {
    return this.validateForm.controls.addressDetail;
  }

  get account() {
    return this.validateForm.controls.account;
  }

  get enterpriseId() {
    return this.validateForm.controls.enterpriseId;
  }

  

  ngAfterViewInit() {
    // 获取推广链接的参数
    this.route.queryParams.subscribe(map => {
      this.operationCenterId = map.operateId ? map.operateId : null;
      this.referrerId = map.referrerId ? map.referrerId : null;
      this.posterId = map.posterId ? map.posterId : null;
    });

    // 加载省市区
    this.LoadProvince();
    // this.commonService.getOneOSSParams();
    this.lazy
      .loadScript(
        'https://webapi.amap.com/maps?v=1.4.14&key=9b6aa8c00d6ef8ea8fd14d082df78eb4&plugin=AMap.MouseTool,AMap.ControlBar,AMap.RectangleEditor,AMap.Autocomplete',
      )
      .then(() => {
        // this.getLangLat(1);

        // 高德地图
        this.map = new AMap.Map('allmap', {
          center: [113.698816, 34.788032],
          zoom: this.zoom,
          expandZoomRange: true,
          zooms: [3, 20],
          mapStyle: 'amap://styles/macaron',
          resizeEnable: true,
        });
      });
  }
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.passWord.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  /**
   * 文件上传
   */
  beforeUpload = (file): boolean => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.msg.info('图片不能大于5M!');
      return false;
    }

  };

  /**
   * 加载省数据
   *
   */
  LoadProvince() {
    let params = {
      name: 'province',
      parentCode: '0',
    };
    const headers = new HttpHeaders()
      .set('Authorization', '')
      .delete('Authorization')
      .set('token', '');

    this.http
      .get<any>(`${baseUrl}/basics/v1/districts/data`, {
        params: params,
        headers: headers,
      })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.provinceData = res.data || [];

          this.cdr.detectChanges();
        } else {
          this.msg.warning(res.msg);
        }
      });
  }
  /**
   * 选择省时加载市
   *
   */
  loadCities(pid: string, cleanCity = true) {
    if (pid) {
      let params = {
        name: 'city',
        parentCode: pid,
      };
      const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
      this.http
        .get<any>(`${baseUrl}/basics/v1/districts/data`, {
          params: params,
          headers: headers,
        })
        .subscribe((res: any) => {
          if (res.code == 0) {
            this.cityData = res.data || [];

            if (this.aiBusinessData) {
              let cityCode = this.cityData.filter(
                item =>
                  item.name ===
                  this.aiBusinessData.enterpriseRegisterAddressCity,
              )[0];

              setTimeout(() => {
                this.city.setValue(!!cityCode ? cityCode.code : '');
              }, 500);
            }

            if (cleanCity) {
              this.validateForm.patchValue({
                city: null,
                district: null,
                town: null,
                village: null,
              });
              this.districtData = [];
              this.townData = [];
              this.villageData = [];
            }
            this.cdr.detectChanges();
          } else {
            this.msg.warning(res.msg);
          }
        });
    }
  }
  /**
   * 选择市时加载区
   *
   */
  loadDistrict(pid: string, cleanDistrict = true) {
    if (pid) {
      let params = {
        name: 'district',
        parentCode: pid,
      };
      const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
      this.http
        .get<any>(`${baseUrl}/basics/v1/districts/data`, {
          params: params,
          headers: headers,
        })
        .subscribe((res: any) => {
          if (res.code == 0) {
            this.districtData = res.data || [];

            if (this.aiBusinessData) {
              let districtCode = this.districtData.filter(
                item =>
                  item.name ===
                  this.aiBusinessData.enterpriseRegisterAddressDistrict,
              )[0];
              setTimeout(() => {
                this.district.setValue(!!districtCode ? districtCode.code : '');
              }, 500);
            }

            if (cleanDistrict) {
              this.validateForm.patchValue({
                district: null,
                town: null,
                village: null,
              });
              this.townData = [];
              this.villageData = [];
            }
            this.cdr.detectChanges();
          } else {
            this.msg.warning(res.msg);
          }
        });
    }
  }
  /**
   * 选择区时加载镇
   *
   */
  loadTown(pid: string, cleanTown = true) {
    if (pid) {
      let params = {
        name: 'town',
        parentCode: pid,
      };
      const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
      this.http
        .get<any>(`${baseUrl}/basics/v1/districts/data`, {
          params: params,
          headers: headers,
        })
        .subscribe((res: any) => {
          if (res.code == 0) {
            this.townData = res.data || [];

            this.map.setCity(
              this.districtData.filter(
                item => item.code === this.district.value,
              )[0].name,
            );

            if (cleanTown) {
              // this.i.town = null;
              // this.i.village = null;
              this.validateForm.patchValue({ town: null, village: null });
            }
            this.cdr.detectChanges();
          } else {
            this.msg.warning(res.msg);
          }
        });
    }

    // this.getAddressLangLat()
  }
  /**
   * 选择镇时加载村
   *
   */
  loadVillage(pid: string, cleanVillage = true) {
    if (pid) {
      let params = {
        name: 'village',
        parentCode: pid,
      };
      const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
      this.http
        .get<any>(`${baseUrl}/basics/v1/districts/data`, {
          params: params,
          headers: headers,
        })
        .subscribe((res: any) => {
          if (res.code == 0) {
            this.villageData = res.data || [];
            if (cleanVillage) this.validateForm.patchValue({ village: null });
            this.cdr.detectChanges();
          } else {
            this.msg.warning(res.msg);
          }
        });
    }
  }

  //根据地址解析坐标
  getAddressLangLat(code: string) {
    if (!code) {
      return;
    }
    let $that = this;
    const province = this.provinceData.filter(
      k => k.code == this.validateForm.controls.province.value,
    )[0].name;
    const city = this.cityData.filter(
      k => k.code == this.validateForm.controls.city.value,
    )[0].name;
    const district = this.districtData.filter(
      k => k.code == this.validateForm.controls.district.value,
    )[0].name;
    let address = province + city + district;
    if (this.validateForm.controls.town.value != 0) {
      const town = this.townData.filter(
        k => k.code == this.validateForm.controls.town.value,
      )[0].name;
      address = province + city + district + town;
    }
    //高德地图
    $that.map = new AMap.Map('allmap', {
      center: [113.698816, 34.788032],
      zoom: this.zoom,
      expandZoomRange: true,
      zooms: [3, 20],
      mapStyle: 'amap://styles/macaron',
      resizeEnable: true,
    });

    //根据地址解析坐标
    var geocoder = null;
    AMap.plugin(['AMap.Geocoder', 'AMap.ToolBar'], function () {
      geocoder = new AMap.Geocoder({});

      geocoder.getLocation(address, function (status, result) {
        var marker = new AMap.Marker();
        console.log(result);
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location;
          $that.validateForm.patchValue({ longitude: lnglat.lng });
          $that.validateForm.patchValue({ latitude: lnglat.lat });

          marker.setPosition(lnglat);
          $that.map.add(marker);
          $that.map.setFitView(marker);
        } else {
          $that.msg.warning(
            result.info || '该地址获取不到经纬度，请手动点击地图区域选取！',
          );

          $that.map.setCity(
            $that.districtData.filter(
              item => item.code === $that.district.value,
            )[0].name,
          );

          $that.validateForm.patchValue({ longitude: '' });
          $that.validateForm.patchValue({ latitude: '' });
        }
      });

      $that.map.addControl(
        new AMap.ToolBar({
          // 简易缩放模式，默认为 false
          liteStyle: true,
        }),
      );
    });

    AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function () {
      var autoOptions = {
        city: '郑州', //城市，默认全国
        input: 'keyword', //使用联想输入的input的id
      };
      var autocomplete = new AMap.Autocomplete(autoOptions);
      var marker = new AMap.Marker();
      AMap.event.addListener(autocomplete, 'select', function (e) {
        let placeSearch = new AMap.PlaceSearch({
          city: '郑州',
          map: $that.map,
        });
        //TODO 针对选中的poi实现自己的功能
        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name, () => {
          var lnglat = e.poi.location;
          marker.setPosition(lnglat);
          // 设置标签
          marker.setLabel({
            offset: new AMap.Pixel(20, 20),
            content: '位置：' + e.poi.address + '<p>坐标：' + lnglat + '</p>',
          });
          $that.map.add(marker);
          $that.map.setFitView(marker);
          // 其实这个地方是我最主要讲的，请往下看
          placeSearch.render.markerList.clear(); // 这个为清除搜索结果的点，不想清除注释即可
        });
      });
      //为地图注册click事件获取鼠标点击出的经纬度坐标
      $that.map.on('click', function (e) {
        marker.setPosition(e.lnglat);
        // 设置标签
        marker.setLabel({
          offset: new AMap.Pixel(20, 20),
          content: '<p>坐标：' + e.lnglat + '</p>',
        });
        $that.map.add(marker);
        $that.map.setFitView(marker);
        $that.longitude = e.lnglat.getLng();
        $that.latitude = e.lnglat.getLat();
        // 点击鼠标获取经纬度就给输入框赋值
        $that.validateForm.patchValue({ longitude: $that.longitude });
        $that.validateForm.patchValue({ latitude: $that.latitude });
        $that.isVisible = false;
      });
      // 根据鼠标点击获取坐标
      var auto = new AMap.Autocomplete({
        input: 'tipinput',
      });
      AMap.event.addListener(auto, 'select', function (e) {
        if (e.poi && e.poi.location) {
          $that.map.setZoom(15);
          $that.map.setCenter(e.poi.location);
        }
      }); //注册监听，当选中某条记录时会触发
    });
  }
  /**
   * 获取经纬度
   */
  getLangLat() {
    if (
      this.validateForm.controls.city.value == '' ||
      this.validateForm.controls.city.value == undefined ||
      this.validateForm.controls.district.value == '' ||
      this.validateForm.controls.district.value == 'undefined' ||
      this.validateForm.controls.town.value == null
    ) {
      this.msg.warning('请依次选择省/直辖市、市、区/县、乡镇/街道、村。');
      return;
    }

    this.isVisible = true;

    setTimeout(() => {
      let $that = this;

      const province = this.provinceData.filter(
        k => k.code == this.validateForm.controls.province.value,
      )[0].name;
      const city = this.cityData.filter(
        k => k.code == this.validateForm.controls.city.value,
      )[0].name;
      const district = this.districtData.filter(
        k => k.code == this.validateForm.controls.district.value,
      )[0].name;
      let address = province + city + district;
      if (this.validateForm.controls.town.value != 0) {
        const town = this.townData.filter(
          k => k.code == this.validateForm.controls.town.value,
        )[0].name;
        address = province + city + district + town;
      }

      // 高德地图
      $that.map = new AMap.Map('allmap', {
        center: [113.698816, 34.788032],
        zoom: this.zoom,
        expandZoomRange: true,
        zooms: [3, 20],
        mapStyle: 'amap://styles/macaron',
        resizeEnable: true,
      });
      //获取当前地址的坐标位置
      var geocoder = null;
      AMap.plugin(['AMap.Geocoder'], function () {
        geocoder = new AMap.Geocoder();
        var marker = new AMap.Marker();
        geocoder.getLocation(address, function (status, result) {
          if (status === 'complete' && result.geocodes.length) {
            var lnglat = result.geocodes[0].location;
            $that.validateForm.patchValue({ longitude: lnglat.lng });
            $that.validateForm.patchValue({ latitude: lnglat.lat });
            marker.setPosition(lnglat);
            $that.map.add(marker);
            $that.map.setFitView(marker);
          } else {
            $that.validateForm.patchValue({ longitude: '' });
            $that.validateForm.patchValue({ latitude: '' });
          }
        });
      });

      //search
      AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function () {
        var autoOptions = {
          city: '郑州', //城市，默认全国
          input: 'keyword', //使用联想输入的input的id
        };
        var autocomplete = new AMap.Autocomplete(autoOptions);
        var marker = new AMap.Marker();
        AMap.event.addListener(autocomplete, 'select', function (e) {
          let placeSearch = new AMap.PlaceSearch({
            city: '郑州',
            map: $that.map,
          });
          //TODO 针对选中的poi实现自己的功能
          placeSearch.setCity(e.poi.adcode);
          placeSearch.search(e.poi.name, () => {
            var lnglat = e.poi.location;
            marker.setPosition(lnglat);
            // 设置标签
            marker.setLabel({
              offset: new AMap.Pixel(20, 20),
              content: '位置：' + e.poi.address + '<p>坐标：' + lnglat + '</p>',
            });
            $that.map.add(marker);
            $that.map.setFitView(marker);
            // 其实这个地方是我最主要讲的，请往下看
            placeSearch.render.markerList.clear(); // 这个为清除搜索结果的点，不想清除注释即可
          });
        });
        //为地图注册click事件获取鼠标点击出的经纬度坐标
        $that.map.on('click', function (e) {
          marker.setPosition(e.lnglat);
          // 设置标签
          marker.setLabel({
            offset: new AMap.Pixel(20, 20),
            content: '<p>坐标：' + e.lnglat + '</p>',
          });
          $that.map.add(marker);
          $that.map.setFitView(marker);
          $that.longitude = e.lnglat.getLng();
          $that.latitude = e.lnglat.getLat();
          // 点击鼠标获取经纬度就给输入框赋值
          $that.validateForm.patchValue({ longitude: $that.longitude });
          $that.validateForm.patchValue({ latitude: $that.latitude });
          $that.isVisible = false;
        });
        // 根据鼠标点击获取坐标
        var auto = new AMap.Autocomplete({
          input: 'tipinput',
        });
        AMap.event.addListener(auto, 'select', function (e) {
          if (e.poi && e.poi.location) {
            $that.map.setZoom(15);
            $that.map.setCenter(e.poi.location);
          }
        }); //注册监听，当选中某条记录时会触发
      });
    }, 500);
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (!this.validateForm.valid) {
      return;
    }
    // console.log('q')
    if (this.isCreditCode){
      this.msg.warning('请校验企业名称和统一社会信用代码是否一致')
      return;
    }
    // console.log('qwq')

    const data = {
      ...this.validateForm.value,
      operationCenterId: this.operationCenterId,
      referrerId: this.referrerId,
      posterId : this.posterId
    };
    if (data.province == null) {
      data.province = '';
    }
    if (data.city == null) {
      data.city = '';
    }
    if (data.district == null) {
      data.district = '';
    }
    if (data.town == null) {
      data.town = '';
    }
    if (data.village == null) {
      data.village = '';
    }
    this.fileList = this.file;
    if (this.fileList.length > 0) {
      data.fileList = this.fileList.map(file => ({
        fileName: file.name,
        urlPath: file.urlPath,
      }));
    } else {
      this.msg.warning('请上传营业执照');
      return;
    }

    if (!data.agree) {
      this.msg.warning('请勾选协议');
      return;
    }
    this.loading = true;
    const headers = new HttpHeaders()
      .set('Authorization', '')
      .delete('Authorization')
      .set('token', '');
    this.http
      .post<any>(`${baseUrl}/platform/v1/crmcompanyextend/free`, data, {
        headers,
      })
      .subscribe(res => {
        this.loading = false;
        if (res.code == 0) {
          this.msg.success('操作成功');
          (window as any).dataLayer.push({ '企业名称': this.fullName.value, 'event': 'sign_up_success' });
          var ua = window.navigator.userAgent.toLowerCase();
          if (/iphone|ipad|ipod|android/.test(ua)) {
            this.modalService.info({
              nzTitle:
                '您的账号已申请成功！请用电脑打开xinanyun.cn登录系统体验。',
              nzOnOk: () => {
                window.location.reload();
              },
            });
          } else {
            setTimeout(() => {
              this.router.navigateByUrl(`/passport/login`);
            }, 2000);
          }
        } else {
          this.msg.warning(res.msg);
        }
      });
  }
  handleCancel() {
    this.isVisible = false;
  }
  handleOk() {
    this.validateForm.patchValue({ longitude: this.longitude });
    this.validateForm.patchValue({ latitude: this.latitude });
    this.isVisible = false;
  }
  /**
   * 图片上传检测
   * @param info
   */
  handleChange(info: UploadChangeParam): void {
    if (info.type === 'removed') {
      return;
    }
    if (info.file.status !== 'uploading') {
      this.file = [
        {
          uid: this.file.length,
          status: 'done',
          name: info.file.name,
          urlPath: info.file.response.data[0].fileName,
        },
      ];
    }
    if (info.file.status === 'done') {
      this.getAiBusinessLicense(info.file.response.data[0].fileName);

      // this.msg.success(`${info.file.name} 上传成功`);
    } else if (info.file.status === 'error') {
      this.msg.warning(`${info.file.name} 上传失败.`);
    }
  }
  /**
   * 文件上传
   */
  //   beforeUpload = (file): boolean => {
  //       let OSS = this.commonService.OSS;
  //       let path = this.commonService.path + this.commonService.getFileType(file);
  //       OSS.put(path, file).then((res) => {
  //         this.fileList = [
  //           ...this.fileList,
  //           {
  //             uid: this.fileList.length,
  //             status: 'done',
  //             name: file.name,
  //             url: this.commonService.OssSignatureUrl(path),
  //             filePath: this.commonService.getFilePath(path, file),
  //             urlPath: this.commonService.getFilePath(path, file)
  //           }

  //         ];
  //       }).catch(() => {
  //         this.msg.error('文件上传不成功!');
  //       });
  //       return false;
  //     }
  /**
   * 打开电子协议
   */
  openAgree() {
    this.modal
      .create(
        AgreeComponent,
        {},
        {
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(() => { });
  }
  checkshow(value) {
    this.isShow = !value;
    var node = document.getElementById('qr_show');
    if (!!this.isShow) {
      node.style.display = 'block';
    } else {
      node.style.display = 'none';
    }
  }

  getAiBusinessLicense(fileUrl) {
    this.spinLoading = true;
    this.http
      .get<any>(
        `${baseUrl}/platform/v1/crmcompanyextend/aiBusinessLicense?url=${fileUrl}`,
      )
      .subscribe(res => {
        this.aiBusinessData = res.data;
        if (res.code === 0) {
          this.fullName.setValue(this.aiBusinessData.enterpriseNameCh);
          this.enterpriseId.setValue(this.aiBusinessData.enterpriseId)

          let provinceCode = this.provinceData.filter(
            item => item.name === this.aiBusinessData.enterpriseProvince,
          )[0];
          this.province.setValue(!!provinceCode ? provinceCode.code : '');

          this.addressDetail.setValue(
            this.aiBusinessData.enterpriseRegisterAddress,
          );

          this.aiBusinessLicenseMsg = '';
          this.msg.success(`上传成功`);

          this.spinLoading = false;
        } else {
          this.aiBusinessLicenseMsg = res.msg;
          this.spinLoading = false;

          this.validateForm.patchValue({
            fullName: null,
            industry: null,
            province: null,
            city: null,
            district: null,
            town: null,
            village: null,
            longitude: null,
            latitude: null,
            addressDetail: null,
            account: null,
            passWord: null,
            checkPassword: null,
            agree: null,
            enterpriseId: null,
          });
        }
        if(res.code == 9){
          
          this.isCreditCode = true;
          this.fullName.setValue(this.aiBusinessData.enterpriseNameCh);
          this.enterpriseId.setValue(this.aiBusinessData.enterpriseId);
          let provinceCode = this.provinceData.filter(
            item => item.name === this.aiBusinessData.enterpriseProvince,
          )[0];
          this.province.setValue(!!provinceCode ? provinceCode.code : '');

          this.addressDetail.setValue(
            this.aiBusinessData.enterpriseRegisterAddress,
          );

        }
      });
  }

  getCaptcha() {

    // this.count = 59;
    // this.interval$ = setInterval(() => {
    //   this.count -= 1;
    //   if (this.count <= 0) clearInterval(this.interval$);
    // }, 1000);
    this.http.get<any>(`${baseUrl}/cooperation/v1/message/sms?smsType=commonCode&mobile=` + this.account.value)
      .subscribe(res => {
        if (res.code == 0) {
          this.count = 59;
          this.interval$ = setInterval(() => {
            this.count -= 1;
            if (this.count <= 0) clearInterval(this.interval$);
          }, 1000);
          this.msg.success(res.msg);
        }else{
          this.msg.info(res.msg);
        }
      })
  }

  goKefu() {

    window.open(
      'https://cschat-ccs.aliyun.com/index.htm?tntInstId=_2GO6ptY&scene=SCE00009775#/',
      '_blank',
    );
  }
  editCreditCode(){
    let data ={
      companyName: this.fullName.value || '',
      enterpriseId: this.enterpriseId.value || ''
    }
    this.check.loading = true;
    this.http.get<any>(`${baseUrl}/platform/v1/crmcompanyextend/businessLicenseCheck?companyName=${data.companyName}&enterpriseId=${data.enterpriseId}`)
    .subscribe(res => {
    this.check.loading = false;
      if (res.code == 0) {
        this.isCreditCode = false;
        this.msg.success(res.msg);
      }else{
        this.msg.warning(res.msg);
      }
    })
  }
}
