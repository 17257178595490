import { Subject } from 'rxjs';

import { Injector, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';

export class Base implements OnDestroy {
  protected unsubscribe$ = new Subject();
  protected appConfig = environment;
  constructor(protected injector: Injector) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
