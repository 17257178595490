import { Component, OnInit } from '@angular/core'; 
import { baseUrl } from '@core';
import { NzModalRef,NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
export class IHttpResponse<T> {
  code: number;
  requestId: string;
  data: T;
  msg?: string;
}
export interface PersonItemResponse {
  current: number;
  pages: number;
  records: [];
  searchCount: boolean;
  size: number;
  total: number;
}

export interface PersonItem {
  form:{};
  deptCode: string;
}

export interface PersonRequest  {
  limit?: number,
  size: number,
  current: number,
  deptCode: string,
  condition?: string;
};
@Component({
  selector: 'app-change-person',
  templateUrl: './change-person.component.html',
  styleUrls: ['./change-person.component.scss'],
})
export class ChangePersonComponent implements OnInit {

  loading = false;
  rows = [];
  limit = 10;
  total = 0;
  pageIndex = 1;
  pageSize = 10;
  totalPages = 0;
  
  searchParams:any = {};

  person: PersonItem;

  constructor(
    private message: NzMessageService,
    private ref: NzModalRef,
    private http: _HttpClient
  ) {}

  ngOnInit() {
    this.search();
  }

  /**
   * 加载数据
   * @param reset
   */
  search(reset:boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    const searchParams:PersonRequest = {
      ...this.searchParams,
      limit:  this.pageSize || this.limit,
      size:  this.pageSize || this.limit,
      current: this.pageIndex,
      deptCode:this.person.deptCode || '',
    };

    this.loading = true;
    this.http.get<IHttpResponse<PersonItemResponse>>(`${baseUrl}/admin/v1/users`,searchParams).subscribe(res => {
      this.loading=false;
      if (res.code === 0) {
        this.rows = res.data.records || [];
        this.total = res.data.total || 0;
      } else {
        this.message.error(res.msg);
      }
      
    });
  }


   /**
   * 选中
   */
  onStart(data) {
    this.ref.destroy(data)
  }



}
