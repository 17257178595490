import { Component, ChangeDetectionStrategy } from '@angular/core'; import { MessageService } from '@core';
import { SettingsService } from '@delon/theme';
import { CommonService } from '@core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http'
import { ElementRef } from '@angular/core';
import { mockUrl, baseUrl, access_token } from '@core';
import { CacheService } from '@delon/cache';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    .logo{
      color: #fff;
      font-size: 19px;
      vertical-align: -3px;
      margin-left: 16px;
    }
    .logo img{
      width: 34px;
      height: 34px;
      vertical-align: middle;
      margin-right: 8px;
    }
  `]
})
export class HeaderComponent {
  searchToggleStatus: boolean;
  type: number;
  // 是否是免费申请的快速部署
  isFreeData:string;
  datav2: any = [{
    'url': '/data-lsd-v2',
    'name': '安全信息公示平台',
    'data': 0
  }, {
    'url': '/data-edu-v2',
    'name': '校园安全信息公示平台',
    'data': 5
  }, {
    'url': '/data-lsd-build',
    'name': '建筑业安全信息公示平台',
    'data':1
  }, {
    'url': '/data-traffic-v2',
    'name': '安全生产信息公示平台',
    'data':3
  }, {
    'url': '/data-health',
    'name': '卫生行业安全信息公示平台',
    'data':2
  }, {
    'url': '/data-culture',
    'name': '文化旅游行业安全信息公示平台',
    'data':4
  },{
    'url': '/data-lsd-v3',
    'name': '安全信息公示平台',
    'data': 6
  },{
      'url': '/data-lsd-v4',
      'name': '安全信息公示平台',
      'data': 7
  },{
    'url': '/data-lsd-v5',
      'name': '卫生行业安全信息公示平台',
      'data': 8
  }
];

  constructor(public settings: SettingsService, public commonService: CommonService, public http: HttpClient,
    public msg: MessageService,
    private el: ElementRef,
    public srv: CacheService) {
      this.isFreeData = this.srv.getNone('auditStatus');
    this.getImg();
    this.getDataV2Type();
  }
  comname = '豫交安';
  toggleCollapsedSidebar() {
    this.commonService.isHideModuleSidebar = !this.commonService.isHideModuleSidebar;
  }

  searchToggleChange() {
  }

  getImg() {
    let domain = window.location.host;// 获取域名
    this.http.get(`${baseUrl}/basics/v1/basicsinfo`).subscribe((res: any) => {
      if (res.code == '0') {
        if (res.data && res.data.companyLogo) {
          this.el.nativeElement.querySelector('.logoint').src = this.commonService.OssSignatureUrl(res.data.companyLogo);
        }
        if (res.data && res.data.systemName) {
          this.comname = res.data.systemName;
        }

      }
    });
  }

  getDataV2Type() {
    /*
     *  大屏类型  0工贸，1建筑，2卫生，3交通，4文化，5教育
    */
    this.http.get(`${baseUrl}/basics/v1/companys/type`).subscribe((res: any) => {
      if (res.code == '0') {
        this.datav2 = this.datav2.filter(item => item.data === res.data);
        if(this.datav2.length ==0){
          this.datav2= [{
            'url': '/data-lsd-v2',
            'name': '安全信息公示平台',
            'data': 0
          }];
        }
      }
    });
  }

}
