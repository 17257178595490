import { Component, OnInit, ViewChild } from '@angular/core'; import { MessageService } from '@core';
import { _HttpClient, ModalHelper } from '@delon/theme'
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { baseUrl } from '@core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgetpwd',
  templateUrl: './forgetpwd.component.html',
  styleUrls: ['./forgetpwd.component.less']
})
export class UserForgetpwdComponent implements OnInit {
  current = 0;
  haserror = null;
  newInfo:any = {};
  validateForm: FormGroup;
  mobileForm:FormGroup;
  token = null;
  constructor(
    public msgr: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    ) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      newPwd: [null, [Validators.required,Validators.minLength(6),Validators.maxLength(15)]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
    this.mobileForm = this.fb.group({
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, [Validators.required]],
    });
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.newPwd.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  get mobile() {
    return this.mobileForm.controls.mobile;
  }
  get passWord() {
    return this.validateForm.controls.newPwd;
  }
  next(): void {
    for (const i in this.mobileForm.controls) {
      this.mobileForm.controls[i].markAsDirty();
      this.mobileForm.controls[i].updateValueAndValidity();
    }
   let mobile = this.mobileForm.value.mobile;
   let captcha = this.mobileForm.value.captcha;
    this.http.get<any>(`${baseUrl}/cooperation/v1/message/sms/check?smsType=forgetCode&mobile=` + mobile + `&code=` + captcha)
      .subscribe(res => {
        if (res.code == 0) {
          this.current += 1;
          this.token = res.data;
        } else {
          this.msgr.error(res.msg);
        }

      })

  }
 /**
   * 获取验证码
   */
  count = 0;
  interval$: any;

  getCaptcha() {

    // this.count = 59;
    // this.interval$ = setInterval(() => {
    //   this.count -= 1;
    //   if (this.count <= 0) clearInterval(this.interval$);
    // }, 1000);
    this.http.get<any>(`${baseUrl}/cooperation/v1/message/sms?smsType=forgetCode&mobile=` + this.mobile.value)
      .subscribe(res => {
        console.log(res);
        if (res.code == 0) {
          this.count = 59;
          this.interval$ = setInterval(() => {
            this.count -= 1;
            if (this.count <= 0) clearInterval(this.interval$);
          }, 1000);
          this.msgr.success(res.msg);
        }else{
          //alert(res.msg);
          this.msgr.error(res.msg);
        }
      })
  }
  
  /**
   * 重置密码表单提交
   */
  done(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    let parmas = {
      ...this.validateForm.value,
      'mobile':this.mobile.value,
      'token':this.token
    }
    this.http.put<any>(`${baseUrl}/admin/v1/member/forget`,parmas)
    .subscribe(res => {
      console.log(res);
      if(res.code == 0){
        this.msgr.success(res.msg);
        this.current += 1;
      }else{
        this.msgr.error(res.msg);
      }
    })
  }
}
