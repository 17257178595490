import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MessageService } from '@core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { baseUrl, mockUrl, riskUrl, uploadUrl } from '@core';
import { format } from 'date-fns';
import { DataService, CommonService } from '@core';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.less'],
})
export class CertificateComponent implements OnInit {
  @ViewChild(FileUploadComponent) fileUpload: FileUploadComponent;
  window = window;
  riskUrl = riskUrl;
  loading = false;
  params: any = {
    riskPointName: '',
  };
  selectedValue = null;
  selectData = null;
  selectfloorData = null;
  checkFloor: any;
  fileList = [];
  uploadParams = {
  };
  /**
   * tree
   */
  treeLoading = false;
  //tree 数据源
  nodes = [];
  userid: any;

  // tree 数据源区域
  floornodes = [];

  i: any = [];
  rows = [];
  limit = 20;
  order = '';
  total = 0;
  page = 1;
  sort = '';
  pageIndex = 1;
  pageSize = 20;
  totalPages = 0;
  searchParams: any = {};
  // 当前选中的节点
  selectNode: any = {};

  // 全选
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  numberOfChecked = 0;

  checkedIds: any;

  //对话框
  editDialog = {
    display: false,
    loading: false,
    data: <any>{},
    form: <FormGroup>null,
    uploadAction: <any>`${uploadUrl}`,
    fileList: [],
  };
  /**
   * 查看对话框
   */
  viewDialog = {
    display: false,
    data: <any>{},
  };

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private message: MessageService,
    private http: _HttpClient,
    private cdr: ChangeDetectorRef,
  ) {
    this.initEditDialogForm();
  }

  ngOnInit() {
    this.userid = JSON.parse(window.localStorage._token || '{}').user_id;
    this.search();
  }

  /**
   * 加载数据
   * @param reset
   */
  search(reset: any = '') {
    const searchParams = {
      limit: this.pageSize || this.limit,
      page: this.pageIndex,
    };
    // console.log(searchParams);
    this.loading = true;
    this.http
      .get<any>(`${baseUrl}/train/v1/studentInfo/studentCreds`, searchParams)
      .subscribe(res => {
        this.loading = false;
        // return;
        if (res.code === 0) {
          this.rows = res.data || [];
          this.rows.forEach(item => {
            item.photoUrlFile = this.commonService.OssSignatureUrl(
              item.photoUrl,
            );
          });
          this.total = res.data.totalElements || 0;
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }

  /**
   * 初始化表单
   */
  initEditDialogForm() {
    this.editDialog.form = this.fb.group({
      id: [null],
      checkDate: [null],
      credName: [null, [Validators.required]],
      organazition: [null, [Validators.required]],
      personalCredid: [null, [Validators.required]],
      userid: [null],
      validDate: [null],
    });
  }

  /**
   * 打开 编辑 对话框
   */
  onOpenEditDialog(data = null) {
    this.editDialog.display = true;
    this.editDialog.data = data;
    this.editDialog.form.reset();
    console.log(data);
    if (data) {
      this.editDialog.fileList = [
        {
          status: 'done',
          name: data.photoName,
          url: data.photoUrl,
          urlPath: data.photoUrl,
        },
      ];
      const _data = { ...data };
      this.editDialog.form.patchValue(_data);
    } else {
      this.editDialog.fileList = [];
    }
  }

  /**
   * 对话框 提交
   */
  onSubmitEditDialog() {
    Object.values(this.editDialog.form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });

    if (!this.editDialog.form.valid) {
      return;
    }

    const data = {
      ...this.editDialog.form.value,
    };
    console.log( this.fileUpload);
    // 附件图片
    data.fileList = this.fileUpload.fileList
      .map(file => ({ fileName: file.name, urlPath: file.url }));
    if (data.fileList.length > 0) {
      data.photoUrl = data.fileList[0].urlPath;
      data.photoName = data.fileList[0].fileName;
    } else {
      this.message.error('请上传照片');
      return;
    }

    data.userid = this.userid;
    data.checkDate = data.checkDate ? format(data.checkDate, 'YYYY-MM-DD') : '';
    data.validDate = data.validDate ? format(data.validDate, 'YYYY-MM-DD') : '';

    this.editDialog.loading = true;
    this.http
      .post<any>(`${baseUrl}/train/v1/studentInfo/studentCred`, data)
      .subscribe(res => {
        this.editDialog.loading = false;
        if (res.code == 0) {
          this.editDialog.display = false;
          this.message.success(res.msg);
          this.search();
        } else {
          this.message.error(res.msg || '操作失败');
        }
      });
  }

  /**
   * 删除
   */
  onDelete(data) {
    let ids = [];
    if (data) {
      ids.push(data.id);
    } else {
      for (var key in this.mapOfCheckedId) {
        if (this.mapOfCheckedId[key]) {
          ids.push(key);
        }
      }
    }

    this.http
      .delete(`${baseUrl}/train/v1/studentInfo/studentCred/` + ids)
      .subscribe(res => {
        if (res.code == 0) {
          this.message.success(res.msg);
          this.search();
        } else {
          this.message.error(res.msg || '操作失败');
        }
      });
  }

  /**
   * 打开查看对话框
   * @param data
   */
  onOpenViewDialog(data) {
    if (data.checkItems.length > 0) {
      data.checkItemName = data.checkItems[0].checkItemName;
      data.checkStandard = data.checkItems[0].checkStandard;
      data.checkDescription = data.checkItems[0].checkDescription;
    } else {
      data.checkItemName = '';
      data.checkStandard = '';
      data.checkDescription = '';
    }

    this.viewDialog.display = true;
    this.viewDialog.data = data;
    this.viewDialog.data._fileList = JSON.parse(
      this.viewDialog.data.fileList || '[]',
    );
  }
}
