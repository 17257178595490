import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { takeWhile, map } from 'rxjs/operators';
import { format, getDay } from 'date-fns';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit, OnDestroy {
  dateTime: Observable<string>;

  private isAlive = true;

  constructor() {}

  ngOnInit() {
    const ary = ['一', '二', '三', '四', '五', '六', '日'];

    this.dateTime = interval(1000).pipe(
      takeWhile(() => this.isAlive),
      map(_ => {
        const date = new Date();

        return `${format(date, 'YYYY-MM-DD HH:mm:ss')}  星期${
          ary[getDay(date) - 1]
        }`;
      }),
    );
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
