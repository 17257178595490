import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';

import { baseUrl } from '@core';
export interface ThreeTrain {
  code:string|number;
  data:[];
  msg:string;
}

@Component({
  selector: 'app-files-info',
  templateUrl: './files-info.component.html',
  styleUrls: ['./files-info.component.less'],
})
export class FilesInfoComponent implements OnInit {
  params: any = {};
  baseInfo: any = {};
  creds:any=[];
  rows:any=[];
  threeRows = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: _HttpClient,
   ) { }

  ngOnInit() {
    this.params = this.route.snapshot.params;
    
    if(this.params.userid){
      this.loadBaseInfo();
      this.loadTrainThree();
      this.loadTrainRecord();
    }

  }

    /**
   * 加载 三级培训记录
   */
  loadTrainThree() {

    const searchParams = {
      userId:this.params.userid,
      companyId:this.params.companyId,
    };
    this.http.get<ThreeTrain>(`${baseUrl}/train/v1/train-plan/three-train`, searchParams).subscribe(res => {
      if (res.code === 0) {
        this.threeRows = res.data || [];
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载 基本信息
   */
  loadBaseInfo() {
    if(this.params.userid){
      this.http.get<any>(`${baseUrl}/train/v1/studentInfo/info/`+this.params.userid).subscribe(res => {
        if (res.code === 0) {
          this.baseInfo = res.data||{};
          this.creds = res.data.creds||[];
        } else {
          this.msg.error(res.msg || '加载失败');
        }
      });
    }

  }

    /**
   * 加载 培训考试记录
   */
  loadTrainRecord() {
    let searchParams = {
      current: 1,
      size: 999
    };
    this.http.get<any>(`${baseUrl}/train/v1/exam-student/trainRecords/`+this.params.userid,searchParams).subscribe(res => {

      if (res.code === 0) {
        this.rows = res.data.records || [];
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/files-info')) {
          this.loadBaseInfo();
        }
      }
    });
  }

}
