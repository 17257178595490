import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-square-frame-container',
  templateUrl: './square-frame-container.component.html',
  styleUrls: ['./square-frame-container.component.scss'],
})
export class SquareFrameContainerComponent implements OnInit {
  @Input() squareColor: string = '#00e4ff';

  constructor() {}

  ngOnInit() {}
}
