import { Component, OnDestroy } from '@angular/core';
import { MessageService } from '@core';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { baseUrl } from '@core';
import { Button } from 'protractor';
@Component({
  selector: 'passport-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
})
export class UserRegisterComponent implements OnDestroy {
  form: FormGroup;
  error = '';
  loading = false;
  isVisible = false;
  constructor(
    fb: FormBuilder,
    private router: Router,
    public http: _HttpClient,
    public msg: MessageService,
    public modalService:NzModalService
  ) {
    this.form = fb.group({
      company: [null, [Validators.required]],
      passWord: [
        null,
        [
          Validators.required,
          Validators.minLength(6),
        ],
      ],
      confirmPassWord: [
        null,
        [
          Validators.required,
          Validators.minLength(6),
          UserRegisterComponent.passwordEquar,
        ],
      ],
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      activationCode: [null, [Validators.required]],
      contractNumber: [null]
    });
  }



  static passwordEquar(control: FormControl) {
    if (!control || !control.parent) {
      return null;
    }
    if (control.value !== control.parent.get('passWord').value) {
      return { equar: true };
    }
    return null;
  }

  // #region fields

  get company() {
    return this.form.controls.company;
  }
  get passWord() {
    return this.form.controls.passWord;
  }
  get confirmPassWord() {
    return this.form.controls.confirmPassWord;
  }
  get mobile() {
    return this.form.controls.mobile;
  }
  get activationCode() {
    return this.form.controls.activationCode;
  }

  // #endregion

  // #region get captcha

  interval$: any;


  // #endregion

  submit() {
    console.log(this.mobile);
    this.error = '';
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    const data = this.form.value;
    this.http.post(`${baseUrl}/admin/v1/users/enterprise`, data).subscribe((res:any) => {
      this.loading = false;
        if (res.code === 0) {
          if(res.data === '0'){
            // 安环家有豫交安无 弹窗提示
            this.modalService.create({
              nzWidth:320,
              nzContent: `<p>帐号${data.mobile}已成功激活豫交安系统成功，欢迎使用！</p>`,
              nzClosable:false,
              nzMaskClosable: false,
              nzOkText:'登录',
              nzCancelText:'取消',
              nzOkType:'button',
              nzOnOk: () => this.router.navigateByUrl('/passport/login'),
            });
          }else{
            // 安环家豫交安均无账号
           this.msg.success(res.msg);
           this.router.navigateByUrl('/passport/login');
          }
        } else {
          // 安环家豫交安均有账号
          this.error = res.msg;
          return;
        }
    });
  }
  handleOk(){
    this.router.navigateByUrl('/passport/login');
  }
  ngOnDestroy(): void {
    if (this.interval$) clearInterval(this.interval$);
  }
}
