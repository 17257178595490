import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MessageService } from '@core';
import { _HttpClient } from '@delon/theme';

import {
  HealthRiskLocationResponse, HiddenAmountResponse, HiddenTrendStatistics,
  HiddenTrendStatisticsResponse, MajorHiddenNotification, MajorHiddenNotificationResponse,
  MajorRiskNotification, MajorRiskNotificationResponse, RiskActivityCompareRequest,
  RiskActivityCompareResponse, RiskAmountResponse, EnterpriseNameAndLogoResponse,SystemNoticeRequest,RiskAmountRequest
} from '../../routes/interface/api.interface';
import { ApiService } from '../../routes/services/api.service';

export class BigScreenService extends ApiService {
  constructor(
    public http: _HttpClient,
    public msgService: MessageService,
  ) {
    super(http, msgService);
  }

  /* ---------------------------------------Api Request-------------------------------------------------*/

  getHiddenTrendStatistics(): Observable<HiddenTrendStatistics[]> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.companyscreens,
      this.subPath.trendStatistics,
    ]);

    return this.getWithToken<HiddenTrendStatisticsResponse>(url).pipe(
      map(res => res.trendStatistics),
    );
  }

  getRiskByLocation(): Observable<HealthRiskLocationResponse> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.risk,
      this.subPath.statistics,
      this.tertiaryPath.location,
    ]);

    return this.getWithToken<HealthRiskLocationResponse>(url);
  }

  getActivityRisk(
    req: RiskActivityCompareRequest,
  ): Observable<RiskActivityCompareResponse> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.companyscreens,
      this.subPath.building,
      this.tertiaryPath.activityRisk,
    ]);

    return this.get<RiskActivityCompareResponse>(url, {
      ...this.tokenParam,
      ...req,
    });
  }

  getMajorRiskNotification(): Observable<MajorRiskNotification[]> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.companyscreens,
      this.subPath.majorNotification,
    ]);

    return this.getWithToken<MajorRiskNotificationResponse>(url).pipe(
      map(res => res.riskNotification),
    );
  }

  getMajorHiddenNotification(): Observable<MajorHiddenNotification[]> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.companyscreens,
      this.subPath.majorHiddenDangerPublicity,
    ]);
    return this.getWithToken<MajorHiddenNotificationResponse>(url).pipe(
      map(res => res.hiddenDangerNotification),
    );
  }

  getHiddenAmountStatistics(): Observable<HiddenAmountResponse> {
    const url = this.completeUrl(this.hazard, [this.rootPath.companyscreens]);

    return this.getWithToken<HiddenAmountResponse>(url);
  }

  getRiskAmountStatistics(): Observable<RiskAmountResponse> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.risk,
      this.subPath.home,
      this.tertiaryPath.company,
      this.lastPath.overview,
    ]);
    return this.getWithToken<RiskAmountResponse>(url);
  }

  getEnterpriseNameAndLogoRequest(): Observable<EnterpriseNameAndLogoResponse> {
    const url = this.completeUrl(this.basics, [
      this.rootPath.companys,
      this.subPath.screen
    ]);

    return this.getWithToken<EnterpriseNameAndLogoResponse>(url);
  }
  getSystemNotice(): Observable<SystemNoticeRequest> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.companyscreens,
      this.subPath.systemNotice
    ]);

    return this.getWithToken<SystemNoticeRequest>(url);
  }

  


}
