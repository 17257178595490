import { Component, ChangeDetectionStrategy,OnInit } from '@angular/core';
import { CommonService, IQuickDeployData, IQuickDeployNode, QuickDeployService } from '@core';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl } from '@core';
import { _HttpClient } from '@delon/theme';
import { CacheService } from '@delon/cache';
@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  host: {
    '[class.ms-help]': 'true',
  },
   styleUrls: ['./help.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MSHelpComponent {

  panels =[
    {
      module:     'sys',
      moduleName: '系统初始化设置',
    },
    {
      module:     'risk',
      moduleName: '设置风险点信息',
    }
  ];
  dataStatus:any={
    addr:1,
    dept:1,
    fourColor:1,
    user:1,
    risk:1
  };
  dialogVisable = false;
  visible=false;
  checkVisible = false;
  sourceUrl = 'https://ahj-jiaoyu-test.oss-cn-beijing.aliyuncs.com/public/files/a4608e2cb2b06f5bdc6e12aecac6ccfb.MP4';
  showData:any = {
    auditStatus:-1,
    momo:''
  };
  // 是否显示申请审核按钮
  isShowAduit = true;
  isShowAduitWait = true;
  constructor(private quickDeployService: QuickDeployService,
              private commonService: CommonService,
              private router: Router,
              private route:  ActivatedRoute,
              public msg: NzMessageService,
              private http: _HttpClient,
              public srv: CacheService
              ) {}

  ngOnInit() {
    this.getStepStatus();
    this.getAduitStatus();
  }
  getStepStatus(){
    this.http.get<any>(`${baseUrl}/admin/v1/sysInit/status`).subscribe(res => {
      if (res.code == 0) {
        this.dataStatus = res.data;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  } 
  getAduitStatus(){
    let companyId = JSON.parse(window.localStorage._token || '{}').tenant_id;
    // this.http.get<any>(`${baseUrl}/platform/v1/sysAudit/status/${companyId}`).subscribe((res:any) => {
    //   if (res.code == 0) {
    //     this.showData = res.data || {};
    //     this.srv.set('auditStatus', res.data.auditStatus);
       
    //     if((this.dataStatus.addr == 0 && (this.dataStatus.dept == 0) && (this.dataStatus.fourColor == 0) && (this.dataStatus.user == 0) && (this.dataStatus.user == 0)) && (this.showData.auditStatus == -1 || this.showData.auditStatus == 2)){        
    //       this.isShowAduit = false;
    //     }
    //   } else {
    //     this.msg.error(res.msg || '加载失败');
    //   }
    // });
  }
   /**
   * 下载经典案例
   */
  download(url: string) {
    url = this.commonService.OssSignatureUrl(url);
    window.open(url, '_blank');
 }

 /**
  * 编辑修改设置
  * @param panel 分类
  * @param node 节点
  */
 edit(node: any) {
   this.router.navigate([node],{
     relativeTo: this.route.parent
   });  
 }

 /**
  * 导入
  * @param panel 分类
  * @param node 节点
  */
 importData(panel: any, node: any) {
   this.router.navigateByUrl(node.nodePath).then();

 }

 checkIsDisabled(panel: IQuickDeployData, node: IQuickDeployNode, index: number): boolean {

   for (let i = 0; i < index; i++) {
     let item = panel.nodes[i];
     if(item && !item.skip && !item.anyData){
       return true;
     }
   }
   return false;
 }
 openDialog(){
    this.dialogVisable = true;
    this.visible = false;
 }
 check(){
   this.checkVisible = !this.checkVisible;
 }
 /**
  * 申请审核
  */
 aduit(){
    let companyId = JSON.parse(window.localStorage._token || '{}').tenant_id;
    this.http.put<any>(`${baseUrl}/platform/v1/sysAudit/${companyId}`).subscribe(res => {
      if (res.code == 0) {
        this.getAduitStatus();
        this.msg.success(res.msg || '申请已提交');
        if(this.showData.auditStatus == '1'){        
          this.router.navigateByUrl('/');
        }
        
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
 }
}
