import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ahjApiUrl, MessageService, riskUrl, StartupService } from '@core';
import { ReuseTabService } from '@delon/abc';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { _HttpClient, SettingsService } from '@delon/theme';

@Component({
  selector: 'header-engineer',
  template: `
    <nz-dropdown nzPlacement="bottomRight">
      <div
        class="alain-default__nav-item d-flex align-items-center px-sm"
        nz-dropdown
      >
        <i nz-icon [iconfont]="'icon--'" style="font-size:24px!important"></i>
      </div>
      <div nz-menu class="width-md">
        <div nz-menu-item align="center">
          <img
            src="{{ this.engineerData.ServiceEngineerImageURL }}"
            style="width:80px;height:80px; border-radius:50%"
          />
        </div>
        <div nz-menu-item>
          <i nz-icon type="user" class="mr-sm"></i> 姓名：{{
            this.engineerData.ServiceEngineerName
          }}
        </div>
        <div nz-menu-item>
          <i nz-icon nzType="mobile" nzTheme="outline"></i> 电话：{{
            this.engineerData.ServiceEngineerMobile
          }}
        </div>
        <div nz-menu-item>
          <i nz-icon nzType="wechat" nzTheme="outline"></i> 微信号：{{
            this.engineerData.WeiXin
          }}
        </div>
      </div>
    </nz-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderEngineerComponent {
  constructor(
    public settings: SettingsService,
    private router: Router,
    private http: _HttpClient,
    public msg: MessageService,
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
  ) {
    this.getData();
  }
  engineerData: any = {};
  getData() {
    this.http
      .get<any>(
        `${riskUrl}/company-service/engineer`
      ).subscribe((res: any) => {
      if (res.code == 0) {
          this.engineerData = res.data || {};
          if (this.engineerData.ServiceEngineerMobile =='' || this.engineerData.ServiceEngineerMobile == null) {
            this.setDefaultEngineer();
          } else {
            if (
              this.engineerData.ServiceEngineerImageURL == null ||
              this.engineerData.ServiceEngineerImageURL == ''
            ) {
              this.engineerData.ServiceEngineerImageURL =
                'https://static.anhuanjia.com/avatar/default.png';
            }
            let user = {
              ...this.settings.user,
              ServiceEngineerID: this.engineerData.ServiceEngineerID,
            };
            this.settings.setUser(user);
          }
        } else {
         this.setDefaultEngineer();
        }
      });
  }
  setDefaultEngineer() {
    this.engineerData = {
      ServiceEngineerID: 169476,
      ServiceEngineerName: '安小慧',
      ServiceEngineerMobile: '18595601878',
      ServiceEngineerImageURL:
        'https://static.anhuanjia.com/avatar/default.png',
      WeiXin: '18595601878',
    };
    let user = {
      ...this.settings.user,
      ServiceEngineerID: this.engineerData.ServiceEngineerID,
    };
    this.settings.setUser(user);
  }
}
