import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MessageService } from '@core';
import { DomSanitizer } from '@angular/platform-browser'

import { _HttpClient } from '@delon/theme';
import { baseUrl, mockUrl, riskUrl, uploadUrl, domain } from '@core';
import { CommonService } from '@core';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { ActivatedRoute, Params, Router, ActivationEnd, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';

import * as G2 from '@antv/g2';
import * as moment from 'moment';

let hazardUrl = `${domain}/hazard/v1`;
let basicsUrl = `${domain}/basics/v1`;

@Component({
  selector: 'app-data-lsd-v4',
  templateUrl: './data-lsd-v4.component.html',
  styleUrls: ['./data-lsd-v4.component.scss']
})
export class DataLsdV4Component implements OnInit {
  window = window;

  company: string;

  systemNotice_carousel_autoPlay = true;
  risk_gongshi_carousel_autoPlay = true;
  yinhuan_gongshi_carousel_autoPlay = true;
  risk1_carousel_autoPlay = true;
  risk2_carousel_autoPlay = true;
  yh1_carousel_autoPlay = true;
  yh2_carousel_autoPlay = true;

  // logo
  logoData: any = {};
  // 日期时间
  datetime = '';
  // 是否全屏
  isFullscreen = false;
  // 统计数量
  totalData: any = {};
  // 通知公告
  systemNotice = [];
  // 重大风险公示
  majorNotificationList = [];
  // 重大隐患公示
  majorHiddenDangerPublicity = [];

  /**
   * echartsOption
   */
  //<风险统计>:
  //风险按区域统计
  qyEchartsOption = null;
  //风险按管辖部门统计
  bmEchartsOption = null;

  //风险点统计(设备设施)
  ssEchartsOption = null;
  //风险点统计(作业活动)
  zyEchartsOption = null;
  //风险点统计(工艺节点)
  gyEchartsOption = null;

  //<隐患统计>:
  //按责任单位统计
  zrdwEchartsOption = null;
  //按区域统计
  qyyhEchartsOption = null;
  //按类别统计
  lbyhEchartsOption = null;

  //隐患变化趋势
  yhqsEchartsOption = null;

  constructor(public sanitizer: DomSanitizer, private message: MessageService, private http: _HttpClient, private eleMentRef: ElementRef,
    private commonService: CommonService, private router: Router,
    private route: ActivatedRoute,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.company = params['c'];
      }
    );
    this.load_screen();
    this.setDateTime();
    this.load_companyscreens();
    this.load_systemNotice();
    this.load_majorNotification();
    this.load_majorHiddenDangerPublicity();

    this.load_checkItemLocationStatistics();
    this.load_checkItemDepartmentStatistics();

    this.load_objectStatistics(1);
    this.load_objectStatistics(2);
    this.load_objectStatistics(3);

    this.load_chartStatistics('area');
    this.load_chartStatistics('category');
    this.load_chartStatistics('duty');

    this.load_trendStatistics();

    //添加 轮播图 播放暂停 事件
    setTimeout(() => {
      Array.from(this.eleMentRef.nativeElement.querySelectorAll('[autoPlay_field]')).forEach((carousel: HTMLElement) => {
        let autoPlay_field = carousel.getAttribute('autoPlay_field');
        carousel.addEventListener('mouseenter', (e) => {
          this[autoPlay_field] = false;
        });
        carousel.addEventListener('mouseleave', (e) => {
          this[autoPlay_field] = true;
        });
      });
    }, 0);
  }

  //全屏切换
  onSwitchFullscreen() {
    //进入全屏
    const requestFullScreen = () => {
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de['mozRequestFullScreen']) {
        de['mozRequestFullScreen']();
      } else if (de['webkitRequestFullScreen']) {
        de['webkitRequestFullScreen']();
      }
    };
    //退出全屏
    const exitFullscreen = () => {
      var de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de['mozCancelFullScreen']) {
        de['mozCancelFullScreen']();
      } else if (de['webkitCancelFullScreen']) {
        de['webkitCancelFullScreen']();
      }
    };

    if (this.isFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }

  //获取请求参数
  getParams() {
    return {
      access_token: JSON.parse(window.localStorage.getItem('_token') || '{}').token
    };
  }

  //设置日期时间
  setDateTime() {
    let dayMap = {
      0: '星期日',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
    };
    this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    setInterval(() => {
      this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    }, 1000);
  }

  //加载 logo
  load_screen() {
    let params = this.getParams();
    this.http.get<any>(`${basicsUrl}/companys/screen`, params).subscribe(res => {
      if (res.code == 0) {
        this.logoData = res.data || {};

        // this.logoData.companyLogo = 'https://s2.ax1x.com/2019/08/05/e2H3cR.jpg';

        if (this.logoData.companyLogo) {
          this.logoData.companyLogo = this.sanitizer.bypassSecurityTrustStyle('url(' + this.logoData.companyLogo + ')')
        }
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 统计数量
  load_companyscreens() {
    /**
     * 隐患
     */
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens`, params).subscribe(res => {
      if (res.code == 0) {
        this.totalData = {
          ...this.totalData,
          ...res.data
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });

    /**
     * 风险
     */
    params = this.getParams();
    this.http.get<any>(`${hazardUrl}/risk/statistics/riskPointNum`).subscribe(res => {
      if (res.code == 0) {
        this.totalData = {
          ...this.totalData,
          ...res.data
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 通知公告
  load_systemNotice() {
    const params = this.getParams();
    let otherParam = '?isContainId=1&';
    this.http.get<any>(`${hazardUrl}/companyscreens/systemNotice${otherParam}`, params).subscribe(res => {
      if (res.code == 0) {
        this.systemNotice = res.data.notice || [];
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 重大风险公示
  load_majorNotification() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/majorNotification`, params).subscribe(res => {
      if (res.code == 0) {
        this.majorNotificationList = res.data.riskNotification || [];
        //取前10个
        this.majorNotificationList = this.majorNotificationList.slice(0, 10);
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 重大隐患公示
  load_majorHiddenDangerPublicity() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/majorHiddenDangerPublicity`, params).subscribe(res => {
      if (res.code == 0) {
        this.majorHiddenDangerPublicity = res.data.hiddenDangerNotification || [];
        //取前10个
        this.majorHiddenDangerPublicity = this.majorHiddenDangerPublicity.slice(0, 10);
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 风险按区域统计
  load_checkItemLocationStatistics() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/checkItemLocationStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        var riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        let arr = res.data.riskLocationStatistics || [];

        this.qyEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 风险按管辖部门统计
  load_checkItemDepartmentStatistics() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/checkItemDepartmentStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        var riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        let arr = res.data.riskDepartmentStatistics || [];

        this.bmEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 风险点统计
  load_objectStatistics(type) {
    let params = { ...this.getParams(), type };
    this.http.get<any>(`${hazardUrl}/companyscreens/objectStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        let option = '';
        switch (type) {
          case 1: { option = 'ssEchartsOption'; break; }
          case 2: { option = 'zyEchartsOption'; break; }
          case 3: { option = 'gyEchartsOption'; break; }
        }

        let arr = res.data.objectStatistics || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.typeName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          series: [{
            data: arr.map(v => v.count || 0),
            type: 'bar',
            barWidth: '5',
          }]
        };

      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 隐患统计
  load_chartStatistics(classifiedType) {
    let params = { ...this.getParams(), classifiedType };
    this.http.get<any>(`${hazardUrl}/companyscreens/chartStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        let option = '';
        let resField = '';
        switch (classifiedType) {
          case 'area': { option = 'qyyhEchartsOption'; resField = 'areaStatistics'; break; }
          case 'duty': { option = 'zrdwEchartsOption'; resField = 'dutyUnitStatistics'; break; }
          case 'category': { option = 'lbyhEchartsOption'; resField = 'potentialCategoryStatistics'; break; }
        }

        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        let arr = res.data[resField] || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 隐患变化趋势
  load_trendStatistics() {
    let params = { ...this.getParams() };
    this.http.get<any>(`${hazardUrl}/companyscreens/trendStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        let arr = res.data.trendStatistics || [];

        // arr = [{"greatCount":81,"generalCount":74,"yearAndMonth":"1月"},{"greatCount":77,"generalCount":20,"yearAndMonth":"2月"},{"greatCount":59,"generalCount":67,"yearAndMonth":"3月"},{"greatCount":44,"generalCount":78,"yearAndMonth":"4月"},{"greatCount":66,"generalCount":35,"yearAndMonth":"5月"},{"greatCount":54,"generalCount":79,"yearAndMonth":"6月"},{"greatCount":49,"generalCount":45,"yearAndMonth":"7月"},{"greatCount":40,"generalCount":91,"yearAndMonth":"8月"},{"greatCount":8,"generalCount":94,"yearAndMonth":"9月"},{"greatCount":80,"generalCount":32,"yearAndMonth":"10月"},{"greatCount":43,"generalCount":55,"yearAndMonth":"11月"},{"greatCount":9,"generalCount":69,"yearAndMonth":"12月"}];

        this.yhqsEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            },
            data: [{
              name: '重大隐患',
              icon: 'roundRect'
            }, {
              name: '一般隐患',
              icon: 'roundRect'
            }]
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.yearAndMonth),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            lineStyle: {
              width: 1
            }
          }))
        };

      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 退出登录
   */
  logout() {
    this.http.delete(`${baseUrl}/auth/token/logout`).subscribe(res => {
      this.tokenService.clear();
      this.commonService.loginBeforeUrl = window.location.hash.slice(1);
      if (this.company == 'mt1d') {
        // 如果是煤田一队进入， 退出返回到煤田一队登录页
        this.router.navigateByUrl('/demo/data-lsd-login');
      } else {
        this.router.navigateByUrl(this.tokenService.login_url);
      }
      this.message.success("退出成功！");
    });
  }

  goTo(url, params) {
    this.router.navigateByUrl(`/safety-publicity/${url}?${params}&backType=4`);
  }

  goDetail(params) {
    this.router.navigateByUrl(`/safety-publicity/read-detail?id=${params.id}&backType=4`);
  }

}
