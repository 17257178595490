import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzCollapseModule } from 'ng-zorro-antd';
import { baseUrl } from '@core';
import { DomSanitizer } from '@angular/platform-browser' 

declare var ehs: any;  //安卓附件下载函数
declare var webkit: any;  //ios附件下载函数
@Component({
  selector: 'app-notice-page',
  templateUrl: './notice-page.component.html',
  styleUrls: ['./notice-page.component.less']
})
export class NoticePageComponent implements OnInit {
  id: string;
  token: string;
  params: any = {};
  notice:any ={};
  //图片弹框属性
  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(private route: ActivatedRoute,
    public msg: NzMessageService,
    private sanitizer: DomSanitizer,
    private http: _HttpClient) { }

  ngOnInit() {
    this.params = this.route.snapshot.params;
    this.getData();
  }
/**
   * 获取系统公告详情内容
   */
  getData() {
   
    this.http.get<any>(`${baseUrl}/cooperation/v1/noticelogs/deal/${this.params['id']}?access_token=${this.params['token']}`).subscribe(res => {
      if (res.code == 0) {
        console.log(res,11)
        this.notice = res.data || {};
        this.notice.text = this.sanitizer.bypassSecurityTrustHtml(this.notice.text || '');
      } else {
        this.msg.error(res.msg);
      }

    })
  }
  /**
   * 图片弹框预览
   * @param event 
   */
  open(event:any){
    if(event.target.localName == 'img'){
      this.previewImage = event.target.currentSrc;
      this.previewVisible = true;
      
    }
    if(event.target.localName == 'a'){
      let fileUrl = event.target.href;
      let fileName = event.target.innerHTML;
     // window['ehs'].downLoadFile(fileName,fileUrl);
      //ehs.downLoadFile(fileName,fileUrl);
      var ua = window.navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        var obj = {
          "fileName":fileName, 
          "fileUrl":fileUrl
        };
        webkit.messageHandlers.downLoadFile.postMessage(obj);
      } else if (/android/.test(ua)) {
        ehs.downLoadFile(fileName,fileUrl);
      }
    }
  }
}
