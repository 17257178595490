import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-alien-line-bcg',
  templateUrl: './alien-line-bcg.component.html',
  styleUrls: ['./alien-line-bcg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlienLineBcgComponent implements OnInit {
  @Input() footerBcgColor: string = '';

  constructor() {}

  ngOnInit() {}
}
