import { Directive, ElementRef, HostListener } from '@angular/core';
import { CommonService } from '@core';
@Directive({
  selector: '[appSidebarMenu]'
})
export class SidebarMenuDirective {

  constructor(private el: ElementRef, private commonService: CommonService) { }

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight('#dbdbdb');
    this.compute('block');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null);
    this.compute('none');
  }

  @HostListener('click',['$event.target']) onClick(btn) {

    let menu = this.el.nativeElement.getElementsByTagName("div")[0].getElementsByTagName('ul')[0];
    menu.style.display = 'none';
  }

  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;


  }

  private compute(display) {
    // return this.el.nativeElement.clientWidth;
    let width = this.el.nativeElement.clientWidth;

    let height = this.el.nativeElement.clientHeight;

    let bodyHeight = document.documentElement.clientHeight;

    let clientRect = this.el.nativeElement.getBoundingClientRect().top;

    let menu = this.el.nativeElement.getElementsByTagName("div")[0].getElementsByTagName('ul')[0];

    let childTotalHeight = (menu.getElementsByTagName('li').length) * 30;

    // 设置隐藏显示
    menu.style.display = display;
    // 设置left 值
    menu.style.left = width + 'px';

    // 设置top
    if ((bodyHeight - clientRect - height) < childTotalHeight) {
      menu.style.top = clientRect + height - childTotalHeight + 'px'
    } else {
      menu.style.top = clientRect + 'px'
    }

  }


}
