import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-culture-block',
  templateUrl: './culture-block.component.html',
  styleUrls: ['./culture-block.component.less'],
})
export class CultureBlockComponent implements OnInit, OnChanges {
  @Input() titleText = '';
  @Input() option = null;

  isHover = false;
  isArray = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.option) {
      this.isArray = Array.isArray(this.option);
    }
  }
}
