
import { Component, OnInit } from '@angular/core'; 
import { domain } from '@core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzCollapseModule } from 'ng-zorro-antd';

import { baseUrl } from '@core';

declare var ehs: any;  // 安卓附件下载函数
declare var webkit: any;  // ios附件下载函数

@Component({
  selector: 'app-work-rule',
  templateUrl: './work-rule.component.html',
  styleUrls: ['./work-rule.component.scss']
})
export class WorkRuleComponent implements OnInit {
  params: any = {};
  basics: any = {
    operatingProceduresList:[]
  };
  items: any = [];
  orglist=[];


  checkList1= [{isactive: true},{isactive: false},{isactive: false}];
 // checkList1= [];
  collDoms:any = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: _HttpClient,
    private modal: ModalHelper) { }

  async ngOnInit() {    
    this.params = this.route.snapshot.params;
    await this.getData();

  }


 
  /**
   * 获取操作流程规范
   */
  getData() {

    return this.http.get(`${baseUrl}/hazard/v1/institution/account/operatingProcedures`, this.params).toPromise().then((res:any) => {
      if (res.code === 0) {

        this.basics = res.data || {};
        if(!res.data){
          this.basics.operatingProceduresList = [];
        }
      } else {

        this.msg.error(res.msg);
      }

    })
  }
  downLoad(item){
    const fileUrl = item.urlPath;
    const fileName = item.fileName;
    var ua = window.navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      var obj = {
        "fileName":fileName, 
        "fileUrl":fileUrl
      };
      webkit.messageHandlers.downLoadFile.postMessage(obj);
    } else if (/android/.test(ua)) {
      ehs.downLoadFile(fileName,fileUrl);
    }
}
  ngAfterViewInit() {
    // 监听路由变化
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/work-rule')) {
          this.getData(); 
        }
      }
    });
  }
}

