import { map } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService, domain } from '@core';

export type RiskType = 'LS' | 'LEC' | 'MES';

function autoApi(url: string) {
  return `${domain}/${url}`;
}

@Injectable()
export class CultureService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  get<T>(url: string, params?: GetParams) {
    return this.http
      .get<Res<T>>(url, { params })
      .pipe(map(res => res.data));
  }

  /**
   * 企业名称和logo
   */

  getCompanysScreen() {
    return this.get<CompanysScreen>(autoApi('basics/v1/companys/screen'));
  }

  /**
   * 通知公告
   */
  getSystemNotice() {
    return this.get<SystemNotice>(
      autoApi('hazard/v1/companyscreens/systemNotice'),
    );
  }

  /**
   * 企业大屏-交通行业----作业活动风险值对比图
   */

  getActivityRisk(type: RiskType) {
    return this.get<AcitvityRisk[]>(
      autoApi('hazard/v1/companyscreens/traffic/activityRisk'),
      {
        type,
      },
    );
  }

  /**
   * 企业大屏-交通行业----风险点统计
   */

  getRiskCountByType(type: string) {
    return this.get(
      autoApi('hazard/v1/companyscreens/traffic/riskCountByType'),
      { type },
    );
  }

  /**
   * 风险统计数量
   */

  getStatisticsRisknum() {
    return this.get<StatisticsRisknum>(
      autoApi('hazard/v1/risk/statistics/risknum'),
    );
  }

  /**
   * 重要节日隐患统计图
   */

  getTourismNumber() {
    return this.get<TourismNumber[]>(
      autoApi('hazard/v1/companyscreens/tourism/number'),
    );
  }

  /**
   * 风险对象统计
   * @param type
   */

  getobjectStatistics(type: ObjectStatisticsType) {
    return this.get<ObjectStatistics>(
      autoApi('hazard/v1/companyscreens/objectStatistics'),
      {
        type,
      },
    );
  }

  /**
   * 区域风险统计图
   */
  getCheckItemLocationStatistics() {
    return this.get<RiskLocationStatistic>(
      autoApi('hazard/v1/companyscreens/checkItemLocationStatistics'),
    );
  }
  /**
   * 9.隐患统计图
   */
  getChartStatistics(classifiedType: ChartStatisticsType) {
    const propMap = {
      area: 'areaStatistics',
      category: 'potentialCategoryStatistics',
    };
    return this.get<ChartStatistics[]>(
      autoApi('hazard/v1/companyscreens/chartStatistics'),
      { classifiedType },
    ).pipe(
      map(data => (data[propMap[classifiedType]] as any) as ChartStatistics[]),
    );
  }

  /**
   * 隐患数量
   */
  getCompanyscreens() {
    return this.get<DangerStatistics>(autoApi('hazard/v1/companyscreens'));
  }

  /**
   *
   * @param config 17.企业大屏-旅游行业----未整改隐患公示
   */
  getNotRectificationPublicity() {
    return this.get<any[]>(
      autoApi('hazard/v1/companyscreens/tourism/notRectificationPublicity'),
    );
  }

  /**
   * 重大隐患公示(包括旅游行业大屏，卫生行业大屏，及其他通用大屏)
   */
  getMajorHiddenDangerPublicity() {
    return this.get(
      autoApi('hazard/v1/companyscreens/majorHiddenDangerPublicity'),
    );
  }
  /**
   * 重大风险公示 (包括旅游行业大屏,卫生行业大屏，及其他通用大屏)
   */
  getMajorNotification() {
    return this.get(autoApi('hazard/v1/companyscreens/majorNotification'));
  }

  /**
   * 生成图表配置
   * @param config
   */
  createChartOption(config: CharOptionConfig) {
    if (!config.xAxisNames && config.xAxisNameKey) {
      config.xAxisNames = config.data.map(v => v[config.xAxisNameKey]);
    }
    const {
      data = [],
      xAxisNames,
      series = [],
      legend = false,
      title,
    } = config;
    const color =
      config.color || series.length > 2
        ? Object.values(this.commonService.color)
        : ['#F4001B', '#63AFFE'];

    return {
      grid: {
        left: 0,
        right: 8,
        containLabel: true,
        bottom: legend ? 50 : 30,
        top: 30,
      },
      title: {
        text: title,
        textStyle: {
          color: '#00e4ff',
          fontSize: 'normal',
          // lineHeight: 2,
        },
      },
      legend: {
        bottom: 0,
        left: 'center',
        textStyle: {
          color: '#c7fffe',
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'shadow',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisNames,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#c7fffe',
          margin: 2,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#c7fffe',
        },
        splitLine: {
          lineStyle: {
            color: '#044d67',
          },
        },
      },
      dataZoom: [
        {
          type: 'slider',
          xAxisIndex: 0,
          backgroundColor: '#063c58',
          dataBackground: {
            lineStyle: {
              width: 0,
            },
            areaStyle: {
              color: '#0c586c',
              opacity: 1,
            },
          },
          fillerColor: 'rgba(0, 54, 69, 0.58)',
          handleStyle: {
            color: '#00b4cf',
          },
          height: 19,
          borderColor: 'transparent',
          textStyle: {
            color: 'transparent',
          },
          bottom: legend ? 25 : 0,
        },
        {
          type: 'inside',
          xAxisIndex: 0,
        },
      ],
      color,
      series: series.map(s => {
        const result = {
          data: data.map(v => ({ value: v[s.valueKey], level: v['level'] })),
          name: s.name,
          level: data.map(v => v['level']),
          type: s.type || 'bar',
          barWidth: s.barWidth || (data && data.length > 50 ? 'auto' : 10),
          color: s.color,
        };
        if (s.itemStyle) {
          (<any>result).itemStyle = s.itemStyle;
        }
        return result;
      }),
    };
  }
}
interface SeriesConfig {
  name: string;
  type?: string;
  valueKey?: string;
  barWidth?: string | number;
  color?: string;
  itemStyle?: any;
  barCategoryGap?: string;
}
/**
 * 区域隐患统计类型
 */
export type ChartStatisticsType = 'area' | 'category' | 'duty';
/**
 * 区域隐患统计图数据
 */
export interface ChartStatistics {
  groupName: string; // 名称
  generalCount: string; // 一般数量
  greatCount: number; // 重大数量
}
export interface SystemNotice {
  notice: string[];
}
/**
 * 隐患数量
 */
export interface DangerStatistics {
  currentYearTotalNumber: number; // 年度隐患总数量
  currentMonthMajorNumber: number; // 当月重大隐患
  currentYearNotClosed: number; // 未整改隐患
  currentMonthGeneralNumber: number; // 当月一般隐患
  currentMonthTotalNumber: number; // 月度隐患数量
}
export interface CharOptionConfig {
  title?: string;
  data: any[];
  xAxisNameKey?: string;
  xAxisNames?: string[];
  legend?: boolean;
  series: SeriesConfig[];
  color?: string[];
}
export type GetParams =
  | HttpParams
  | {
      [param: string]: string | string[];
    };

/**
 *
 */

export interface RiskLocationStatistic {
  riskLocationStatistics: RiskLocationStatisticItem[];
}

export interface RiskLocationStatisticItem {
  groupId: string;
  groupName: string;
  lowRiskCount: number;
  generalRiskCount: number;
  relativeRiskCount: number;
  greatRiskCount: number;
}
/**
 * 作业风险值
 */
export interface AcitvityRisk {
  typeName: string;
  valueTotal: number;
  level: number;
  typeId: string;
}
/**
 * 风险点统计
 */
export interface ObjectStatistics {
  objectStatistics: Array<{
    typeName: string;
    percente: string;
    count: number;
  }>;
}
/**
 * 公司名称 logo
 */
export interface CompanysScreen {
  companyLogo: string;
  fullName: string;
}

export interface Res<T> {
  code: number;
  requestId: string;
  msg: string;
  data: T;
}
// type:"1"(设备设施)、"2"(作业活动)、"3"(工艺节点)
export type ObjectStatisticsType = '1' | '2' | '3';
// 重要节日隐患统计图
export interface TourismNumber {
  majorRectified: number; // 已整改重大隐患
  generalRectified: number; // 已整改隐患
}
/**
 * 风险统计
 */
export interface StatisticsRisknum {
  riskCount: number; // 风险总数
  oneRiskCount: number; // 一级风险(重大风险)
  twoRiskCount: number; // 二级风险
  threeRiskCount: number; // 三级风险
  fourRiskCount: number; // 四级风险
}
