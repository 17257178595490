import { Component, OnInit, Input } from '@angular/core'; import { MessageService } from '@core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';
import * as moment from 'moment';
import { baseUrl, mockUrl, riskUrl } from '@core';
import { DataService, CommonService } from '@core';

import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-checkitem-detail-dialog',
  templateUrl: './checkitem-detail-dialog.component.html',
  styleUrls: ['./checkitem-detail-dialog.component.less']
})
export class CheckitemDetailDialogComponent implements OnInit {
  loading = false;

  @Input() planId = null;
  @Input() objectId = null;
  @Input() checkItemId = null;
  @Input() evaluateType: null;

  plan: any = {};
  object: any = {};
  checkItem: any = {};

  constructor(private commonService: CommonService, private modalService: NzModalService, private fb: FormBuilder, private message: MessageService, private http: _HttpClient, private dataService: DataService) {
  }

  ngOnInit() {
    this.loading = true;
    this.http.get<any>(`${riskUrl}/risk/evaluateobject/checkitem/detail`, { id: this.checkItemId }).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.checkItem = res.data.checkItem;
        this.object = res.data.object;
        this.plan = res.data.plan;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

}
