import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { domain, MessageService } from '@core';
import { _HttpClient } from '@delon/theme';

import { IHttpResponse } from '../interface/api.interface';

export interface Token {
  access_token: string;
  token_type: string;
  refresh_token: string;
  scope: string;
  dept_code: string;
  tenant_id: number;
  code: number;
  area_code: string;
  mobile: string;
  dept_name: string;
  real_name: string;
  sys_jobs: SysJob[];
  avatar: string;
  message: string;
  is_function: number;
  job_name: string;
  user_id: number;
  job_id: number;
  company_name: string;
  dept_id: number;
  username: string;
  token: string;
}

export interface SysJob {
  id: number;
  company?: any;
  jobName: string;
  deptId: number;
  deptName: string;
  deptCode: string;
  postId?: any;
  postName?: any;
  isFunction: number;
  isMain: number;
}

export enum RootPath {
  companyscreens = 'companyscreens',
  statistics = 'statistics',
  companys = 'companys',
  risk = 'risk',
  jobticket = 'jobticket',
  waste = 'waste',
  chemicalInRecord = 'chemicalInRecord',
  chemicalOutRecord = 'chemicalOutRecord',
}

export enum SubPath {
  majorNotification = 'majorNotification',
  systemNotice = 'systemNotice',
  majorHiddenDangerPublicity = 'majorHiddenDangerPublicity',
  checkItemLocationStatistics = 'checkItemLocationStatistics',
  checkItemDepartmentStatistics = 'checkItemDepartmentStatistics',
  objectStatistics = 'objectStatistics',
  chartStatistics = 'chartStatistics',
  trendStatistics = 'trendStatistics',
  traffic = 'traffic',
  building = 'building',
  school = 'school',
  tourism = 'tourism',
  health = 'health',
  chart = 'chart',
  department = 'department',
  location = 'location',
  statistics = 'statistics',
  'hazardous-operation-notice' = 'hazardous-operation-notice',
  screen = 'screen',
  type = 'type',
  home = 'home',
  ledger = 'ledger',
  inParameter = 'inParameter',
  parameter = 'parameter',
}

export enum TertiaryPath {
  activityRisk = 'activityRisk',
  riskCountByType = 'riskCountByType',
  number = 'number',
  notRectificationPublicity = 'notRectificationPublicity',
  location = 'location',
  risknum = 'risknum',
  company = 'company',
  in = 'in',
  out = 'out',
  info = 'info',
}

export enum LastPath {
  overview = 'overview',
}

export type ApiAddress = 'hazard/v1' | 'basics/v1' | 'chemical/v1';

export class ApiService {
  private domain = domain;

  public hazard: ApiAddress = 'hazard/v1';

  public basics: ApiAddress = 'basics/v1';

  public chemical: ApiAddress = 'chemical/v1';

  protected rootPath = RootPath;

  protected subPath = SubPath;

  protected tertiaryPath = TertiaryPath;

  protected lastPath = LastPath;

  constructor(
    public http: _HttpClient,
    public messageService: MessageService,
  ) {
    
  }

  protected get token(): Token | null {
    // TODO: localStorage 应该被作为依赖注入进来
    const data = localStorage.getItem('_token');

    return JSON.parse(data || '{}');
  }

  protected get tokenParam(): { access_token: string } {
    return {
      access_token: this.token!.token,
    };
  }

  protected completeUrl(apiAddress: ApiAddress, paths: string[]): string {
    return `${this.domain}/${apiAddress}/${paths.join('/')}`;
  }

  protected get<U>(url: string, params: { [key: string]: any }): Observable<U> {
    return this.http.get<IHttpResponse<U>>(url, params).pipe(
      tap(res => {
        if (res.code !== 0) {
          this.messageService.error(res.msg || '加载失败');
        }

        return res;
      }),
      map(res => res.data),
    );
  }

  protected getWithToken<U>(url: string): Observable<U> {
    return this.get(url, this.tokenParam);
  }

  exportAddress(address: ApiAddress, url: string): string {
    return `${this.domain}/${address}/${url}/export`;
  }
}
