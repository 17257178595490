
import { Component, OnInit, ViewChild,Output ,EventEmitter,ChangeDetectorRef, Input } from '@angular/core'; import { MessageService } from '@core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { tap, map } from 'rxjs/operators';
import { STComponent, STColumn, STData, STChange ,STPage} from '@delon/abc';
import { mockUrl, baseUrl, access_token } from '@core';
import { resolveDefinition } from '@angular/core/src/view/util';
@Component({
  selector: 'app-selectposition',
  templateUrl: './selectposition.component.html',
  styleUrls: ['./selectposition.component.less']
})
export class SelectpositionComponent implements OnInit {
  //传值给父组件
  @Output() handleCancel = new EventEmitter<any>();
  //父组件传值
  @Input() processData: any;
  // 子组件是否可关闭
  isVisible = true;
  searchParams: any = {};
  isposition =false;
  isLoading = false;
  selectedposition:any ={};
  //右侧页面数据
  @ViewChild('st')
  st: STComponent;
  q: any = {
    pi: 1,
    ps: 10,
    sorter: '',
    q: '',
    total:0
  };
  data: any[] = [];
  loading = false;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'radio' },
    { title: '序号', type: 'no', width: '100px' },
    {
      title: '职位名称',
      index: 'postName',
    },
    { title: '岗位数', index: 'jobNum' },
    { title: '职位人数', index: 'postNum' },
    { title: '创建人', index: 'createUsername' },

  ];
  selectedRow: any = {};

  constructor(public msg: MessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef, ) { }

  ngOnInit() {
    // console.log(this.processData);
    if((this.processData.positionName != null && this.processData.positionName != "") && (this.processData.appointId != 0) ){
      this.isposition = true;
      this.selectedposition = {
        'id':this.processData.appointId,
        'postName':this.processData.positionName,
      }
      this.selectedRow =  this.selectedposition
    }
    
    
   this.getData();
  }

  getData(reset: boolean = false) {
    if (reset) {
      this.q.pi = 1;
    }

    let searchParams = {
      ...this.searchParams,
      current: this.q.pi,
      size: this.q.ps
    };
    this.loading = true;
    this.http.get<any>(`${baseUrl}/admin/v1/posts`, searchParams).subscribe(res => {
      // console.log(res);
      this.loading = false;
      if (res.code == 0) {
        res.data.records.forEach(e => {
          if(e.id === this.processData.appointId){
            e.checked = true;
          }
        });
        this.data = res.data.records || [];

        this.q.total = res.data.total || 0;
        //this.msg.success(res.msg);
      } else {
        this.msg.error(res.msg || '加载失败');
      }

      this.cdr.detectChanges();

    });
  }

  pages: STPage = {
    total: false,//分页显示多少条数据，字符串型
    show: true,//显示分页
    front: false, //关闭前端分页，true是前端分页，false后端控制分页,
    showSize:true,
  };
 
  change(e: STChange) {
    if (e.type === 'radio') {
      this.selectedRow = e.radio;
      // console.log(this.selectedRow);
    }
    if(e.type === 'pi' || e.type === 'ps'){
      this.q.pi = e.pi;
      this.q.ps = e.ps;
      this.getData();
    }
  }
  /**
   * 保存
   */
  save() {
    let member = {
      appointId: this.selectedRow.id,
      positionName: this.selectedRow.postName,
      approverName: null,
      departmentCode: null
    }
    let parmas = Object.assign(this.processData, member);
    this.isLoading = true;
    this.http.post(`${baseUrl}/cooperation/v1/process`, parmas)
      .subscribe((res: any) => {
        this.isLoading=false;
        // console.log(res);
        if (res.code == 0) {
          this.msg.success(res.msg);
          //获取选中的人员信息
          let postName:string;
          if(JSON.stringify(this.selectedRow) !='{}'){
            postName = this.selectedRow.postName;
          }else{
            postName = this.selectedposition.postName;
          }
          let p = {
            businessNode:this.processData.businessNode,
            username:postName
          }
          this.handleCancel.emit(p);
        } else {
          this.msg.error(res.msg);
        }
      });
  }
  /**
  * 清空
  */
 reset(){
  let parmas = 
  {
    businessNode:this.processData.businessNode,
    businessType:this.processData.businessType
  };
  this.http.put(`${baseUrl}/cooperation/v1/process/reset`, parmas)
    .subscribe((res: any) => {
      if (res.code == 0) {
        let p ={
          ... parmas,
          type:'reset'
        }
        this.handleCancel.emit(p);
        this.msg.success(res.msg);
      } else {
        this.msg.error(res.msg);
      }
      this.cdr.detectChanges();
    });
}
  /**
   * 关闭
   */
  close(){
    if(this.isposition){
      let postName:string;
          if(JSON.stringify(this.selectedRow) !='{}'){
            postName = this.selectedRow.postName;
          }else{
            postName = this.selectedposition.postName;
          }
      let p = {
        businessNode:this.processData.businessNode,
        username:postName
      }
      this.handleCancel.emit(p);
    }else{
      this.handleCancel.emit();
    }
    
  }
}

