import { Component, OnInit, ViewChild,Output ,EventEmitter,ChangeDetectorRef, Input } from '@angular/core'; import { MessageService } from '@core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions, NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { tap, map } from 'rxjs/operators';
import { STComponent, STColumn, STData, STChange ,STPage} from '@delon/abc';
import { mockUrl, baseUrl, access_token } from '@core';
import { resolveDefinition } from '@angular/core/src/view/util';

@Component({
  selector: 'app-selectpeople',
  templateUrl: './selectpeople.component.html',
  styleUrls: ['./selectpeople.component.less']
})
export class SelectpeopleComponent implements OnInit {
  //传值给父组件
  @Output() handleCancel = new EventEmitter<any>();
 // @Output() echodata = new EventEmitter<Element>();
  /**
     * tree
     */
  treeLoading = false;
  isLoading = false;
  //tree 数据源
  nodes = [];
  //当前选中的节点
  selectNode: any = {};
  searchParams: any = {};
  searchValue = '';
  //父组件传值
  @Input() processData: any;
  // 子组件是否可关闭
  isVisible = true;
  /**
   * 加载 tree 数据
   */
  loadTreeData() {
    this.treeLoading = true;
    this.http.get<any>(`${baseUrl}/admin/v1/depts/tree`).subscribe(res => {
      this.treeLoading = false;
      if (res.code == 0) {
        this.nodes = res.data || [];

        //标识叶子节点
        const recursion = (arr, lv) => {
          arr.forEach(node => {
            // 前端数据转换
            node.title = node.name;
            node.key = node.code;
            !this.selectNode.key && (this.selectNode = node);

            if (Array.isArray(node.children) && node.children.length) {
              //展开第一层节点
              lv == 1 && (node.expanded = true);
              recursion(node.children, ++lv);
            } else {
              node.isLeaf = true;

            }

          });
        };

        recursion(this.nodes,1);

        //加载表格数据
        if (this.selectNode.key) {
          this.getData(true);
        }

      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

  /**
     * 节点选中事件 => 加载对应的表格数据
     */
  onSelectNode(node) {
    if (node.key != this.selectNode.key) {
      this.selectNode = node;
      this.getData(true);
    }
    this.selectNode = {};
    setTimeout(() => {
      this.selectNode = node;
    }, 0);
  }




  //右侧页面数据
  @ViewChild('st')
  st: STComponent;
  q: any = {
    pi: 1,
    ps: 10,
    sorter: '',
    q: '',
    total:0
  };
  data: any[] = [];
  loading = false;
  selectedpeople:any = {};
  ispeople =false;
  columns: STColumn[] = [
    { title: '', index: 'userId', type: 'radio' },
    { title: '序号', type: 'no', width: '100px' },
    {
      title: '真实姓名',
      index: 'username',
      format: (item: any) => `${item.username}`,
      sorter: (a: any, b: any) => a.username != b.username,

    },
    { title: '手机号', index: 'mobile' },
    { title: '部门', index: 'deptName' },
    { title: '角色', index: 'roleName' },

  ];
  selectedRow: any = {};

  constructor(public msg: MessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef, ) { }

  ngOnInit() {
    if(this.processData.approverName != null && this.processData.approverName != ""){
      this.ispeople = true;
      this.selectedpeople = {
        'userId':this.processData.appointId,
        'username':this.processData.approverName,
        'dept':this.processData.departmentCode,
        'checked':true
      }
      this.selectedRow =  this.selectedpeople;
    }
    console.log(this.processData);
    this.loadTreeData();
  }

  getData(reset: boolean = false) {
    if (reset) {
      this.q.pi = 1;
    }

    let searchParams = {
      ...this.searchParams,
      deptCode: this.selectNode.key,
      current: this.q.pi,
      size: this.q.ps
    };
    this.loading = true;
    this.http.get<any>(`${baseUrl}/admin/v1/users`, searchParams).subscribe(res => {

      this.loading = false;
      if (res.code == 0) {
        res.data.records.forEach(e => {
          e.frz = false;
          let deptName = [];
          let jobName = [];
          let postName = [];
          let roleName = [];
          let jobId = [];
          let roleId = [];
          //循环遍历部门职位岗位
          e.jobList.forEach(f => {
            deptName.push(f.deptName);
            jobName.push(f.jobName);
            postName.push(f.postName);
            jobId.push(f.id);
            if (f.principal == e.userId) {
              e.frz = true;
            }
          })
          e.deptName = deptName.join(';');
          e.jobName = jobName.join(';');
          e.jobId = jobId;
          e.postName = postName.join(';');
          //循环遍历角色
          e.roleList.forEach(r => {
            roleName.push(r.roleName);
            roleId.push(r.roleId);
          })
          e.roleName = roleName.join(';');
          e.roleId = roleId;
          if(e.userId === this.processData.appointId){
            e.checked = true;
          }
        });
        this.data = res.data.records || [];
        this.q.total = res.data.total || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }


      this.cdr.detectChanges();

    });
  }
  pages: STPage = {
    total: '',//分页显示多少条数据，字符串型
    show: true,//显示分页
    front: false, //关闭前端分页，true是前端分页，false后端控制分页,
    showSize:true
  };
 
  change(e: STChange) {
    if (e.type === 'radio') {
      this.selectedRow = e.radio;
      console.log(this.selectedRow);
    }
    if(e.type === 'pi' || e.type === 'ps'){
      this.q.pi = e.pi;
      this.q.ps = e.ps;
      this.getData();
    }
  }
  /**
   * 保存
   */
  save() {
    let member = {
      appointId: this.selectedRow.userId,
      approverName: this.selectedRow.username,
      departmentCode: this.selectedRow.dept,
      positionName:null
    }
    let parmas = Object.assign(this.processData, member);
    this.isLoading = true;
    this.http.post(`${baseUrl}/cooperation/v1/process`, parmas)
      .subscribe((res: any) => {
        this.isLoading = false;
        if (res.code == 0) {
          this.msg.success(res.msg);
          //获取选中的人员信息
          let username:string;
          if(JSON.stringify(this.selectedRow) !='{}'){
            username = this.selectedRow.username;
          }else{
             username = this.selectedpeople.username;
          }
          let p = {
            businessNode:this.processData.businessNode,
            username:username
          }
          this.handleCancel.emit(p)
        } else {
          this.msg.error(res.msg);
        }
      });
  }
  /**
  * 清空
  */
  reset(){
    let parmas = 
    {
      businessNode:this.processData.businessNode,
      businessType:this.processData.businessType
    };
    this.http.put(`${baseUrl}/cooperation/v1/process/reset`, parmas)
      .subscribe((res: any) => {
        if (res.code == 0) {
          let p ={
            ... parmas,
            type:'reset'
          }
          this.handleCancel.emit(p);
          this.msg.success(res.msg);
        } else {
          this.msg.error(res.msg);
        }
        this.cdr.detectChanges();
      });
  }
  /**
   * 关闭
   */
  close(){
    if(this.ispeople){
      let username:string;
      if(JSON.stringify(this.selectedRow) !='{}'){
        username = this.selectedRow.username;
      }else{
         username = this.selectedpeople.username;
      }
      let p = {
        businessNode:this.processData.businessNode,
        username:username
      }
      this.handleCancel.emit(p);
    }else{
      this.handleCancel.emit();
    }
    
  }
}
