





































import { Component, OnInit } from '@angular/core';
import { CommonService, IQuickDeployData, IQuickDeployNode, QuickDeployService } from '@core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quick-deploy',
  templateUrl: './quick-deploy.component.html',
  styleUrls: ['./quick-deploy.component.scss'],
})
export class QuickDeployComponent implements OnInit {
  panels: IQuickDeployData[];
  constructor(private quickDeployService: QuickDeployService,
              private commonService: CommonService,
              private router: Router,
              private route:  ActivatedRoute,
              ) {}

  ngOnInit() {
    this.quickDeployService.getDeployConfig().subscribe(
       res => this.panels = res,
      error => console.log(`quick-deploy error: ${error}`)
    )
  }

  /**
   * 下载经典案例
   */
  download(url: string) {
     url = this.commonService.OssSignatureUrl(url);
    window.open(url, '_blank');
  }

  /**
   * 编辑修改设置
   * @param panel 分类
   * @param node 节点
   */
  edit(node: any) {
    //this.router.navigateByUrl(node.nodePath).then();
    this.router.navigate([node.nodePath],{
      relativeTo: this.route.parent,
      queryParams: {
        isHaveData: node.anyData
      }
    });  
  }

  /**
   * 导入
   * @param panel 分类
   * @param node 节点
   */
  importData(panel: any, node: any) {
    this.router.navigateByUrl(node.nodePath).then();

  }

  checkIsDisabled(panel: IQuickDeployData, node: IQuickDeployNode, index: number): boolean {

    for (let i = 0; i < index; i++) {
      let item = panel.nodes[i];
      if(item && !item.skip && !item.anyData){
        return true;
      }
    }
    return false;
  }
}