import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * 路由跳转服务，写在这里统一维护，复用。
 */
@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private router: Router) {}
  toHomePage() {
    this.router.navigateByUrl('/dashboard');
  }
}
