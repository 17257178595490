import { Component, OnInit, Input } from '@angular/core'; 
import { MessageService } from '@core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { baseUrl, mockUrl, riskUrl } from '@core';
import { DataService, CommonService } from '@core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.less']
})
export class RecordComponent implements OnInit {

 //数据
 @Input() recordId: any = {};
 
 loading=false;
 rows=[];
 rows_see=[];
  /**
   * 查看对话框
   */
  viewImg = {
    display: false,
    data: ''
  };
  dataType = false; 
  dataFile = false; 

 constructor(
   private commonService: CommonService,
    private modalService: NzModalService, 
    private fb: FormBuilder, 
    private message: MessageService, 
    private http: _HttpClient, 
    private dataService: DataService, 
    private modal: NzModalRef) {

 }

 ngOnInit() {
    this.search()
 }

   /**
   * 加载数据
   * @param reset
   */
  search() {
    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/rectifications/record/${this.recordId}`)
      .subscribe(res => {
        this.loading = false;
        if (res.code === 0) {
          this.rows = res.data || [];
          this.rows.reverse();
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
  }

    /**
   * 打开文件查看对话框
   * @param data 
   */
  onOpenFile(data = null) {
    this.viewImg.display = true;
    this.rows_see=[];

    data.forEach(item=>{
     let arr= item.fileName.split(".");
      item.type=arr.pop();
      item.urlPath = this.commonService.OssSignatureUrl(item.urlPath);
    })
  
    this.rows_see = data;
    this.dataType = false; 
    this.dataFile = false; 
    for(var i = 0;i<data.length;i++ ){
      if(data[i].type=='jpg' || data[i].type=='png' || data[i].type == 'jpeg'){
        this.dataType = true
      }else if (data[i].type=='doc' || data[i].type=='xls' || data[i].type=='pdf' || data[i].type == 'xlsx' || data[i].type=='docx'){
        this.dataFile = true
      }
      
    }
  }
  /**
   * 打开文件查看对话框
   * @param data 
   */
  closess() {
    this.viewImg.display = false;
  }

}
