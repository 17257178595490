/**
 * 全局请求 URL地址
 */

import { environment } from '../../environments/environment';
import { InjectionToken } from '@angular/core';
export let access_token = environment.access_token;
export let mockUrl = environment.mockUrl;

export let domain = environment.domain;
export let domainMap = environment.domainMap;
export let baseUrlMap = `${domainMap}`;
export let baseUrl = `${domain}`;
export let uploadUrl = `${domain}/basics/v1/file`;
export let uploadUrlAliyuncs = `https://oss-cn-beijing.aliyuncs.com?accessKey=LTAIzpfbqAanWh30&secretKey=w91asl9vXFUC0Xsf9cDI2MJ0DvXM41&bucketName=testxaloss`;
export let logUrl = `https://xaylog.cn-beijing.log.aliyuncs.com/logstores/web/track?APIVersion=0.6.0`;
export let riskUrl = `${domain}/hazard/v1`;
export let trainUrl = `${domain}/train/v1`;
export let serveUrl = environment.serveUrl;
export let wekeUrl = environment.wekeUrl;
export let ahjApiUrl = environment.ahjApiUrl;
export let ticketUrl = environment.ticketUrl;
export let ticketProcessUrl = environment.ticketProcessUrl;
export let ahjsnsApiUrl = environment.ahjsnsApiUrl;
export let BZbaseUrl = environment.BZbaseUrl;
export let YHbaseUrl = environment.YHbaseUrl;
export let ReliBaseUrl = environment.ReliBaseUrl;
export let AliyunOssUrl = environment.AliyunOssUrl;

export const LOCALE_STORAGE_TOKEN = new InjectionToken<string>('localStorage');

/**
 * 测试
 */
if(window.location.port == '4201') {
    // domain = 'http://123.14.75.50';
    // baseUrl = 'http://123.14.75.50:9001';
    // uploadUrl = 'http://123.14.75.50:9001/basics/file/upload';
    // riskUrl = 'http://123.14.75.50:9001/hazard/v1';
}
