import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterGuardService } from '@core';
import { environment } from '@env/environment';

// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
import { LayoutNoHeaderComponent } from '../layout/noheader/noheader.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { CultureComponent } from './culture/culture.component';
// 全屏地图展示
import { AmapFullComponent } from './dashboard/amap-full/amap-full.component';
// 首页-仪表盘
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataEduV2Component } from './data-edu-v2/data-edu-v2.component';
import { DataLsdBuildComponent } from './data-lsd-build/data-lsd-build.component';
import { DataLsdLoginComponent } from './data-lsd-login/data-lsd-login.component';
import { DataLsdV2Component } from './data-lsd-v2/data-lsd-v2.component';
import { DataLsdV3Component } from './data-lsd-v3/data-lsd-v3.component';
import { DataTrafficV2Component } from './data-traffic-v2/data-traffic-v2.component';
import { DataLsdV5Component } from './data-lsd-v5/data-lsd-v5.component';
// 员工健康上报H5页面
import { HealthReportComponent } from './h5-single/health-report/health-report.component';
import { NoticePageComponent } from './notice-page/notice-page.component';
import { UserForgetpwdComponent } from './passport/forgetpwd/forgetpwd.component';
import { UserLockComponent } from './passport/lock/lock.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { QuickDeployComponent } from './quick-deploy/quick-deploy.component';
import { QuickDeployAccountComponent } from './quick-deploy-account/quick-deploy-account.component';
import {
  RiskcardContentComponent
} from './h5-single/riskcard-content/riskcard-content.component';
import { SinglePageComponent } from './single-page/single-page.component';
import { DangerCheckComponent } from './h5-single/danger-check/danger-check.component'; // h5隐患排查情况
import { WorkRuleComponent } from './h5-single/work-rule/work-rule.component'; // h5操作规程
import {
  CertificateComponent
} from './system/personinfo/setting/certificate/certificate.component';
import { InfoComponent } from './system/personinfo/setting/info/info.component';
import { ResetComponent } from './system/personinfo/setting/reset/reset.component';
// 个人中心
import { SettingComponent } from './system/personinfo/setting/setting.component';
import { FilesInfoComponent } from './train/files-manage/files-info/files-info.component';
import { WaterComponent } from './water/water.component';
// 应急预案内容h5页面
import { LedgerContentComponent } from './ledger-content/ledger-content.component';
import { RiskPointComponent } from './h5-single/risk-point/risk-point.component';
import { RiskCardComponent } from './h5-single/risk-card/risk-card.component';
import { RiskcardContentV2Component } from './h5-single/riskcard-content-v2/riskcard-content-v2.component';
import { AccidentCaseComponent } from './h5-single/accident-case/accident-case.component';
import { DataLsdV4Component } from './data-lsd-v4/data-lsd-v4.component';
import { EnterpriseApplyComponent } from './h5-single/enterprise-apply/enterprise-apply.component';
//安全专家在线注册
import { SafeOnlineRegistrationComponent } from './h5-single/safe-online-registration/safe-online-registration.component';
import { RegistraSuccessComponent } from './h5-single/safe-online-registration/registra-success/registra-success.component';
const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    // canActivate: [SimpleGuard],
    // canActivateChild: [SimpleGuard],
    children: [
      // { path: '', redirectTo: 'risk-control', pathMatch: 'full' },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: '豫交安' },
      },
      { path: 'amapfull', component: AmapFullComponent },
      // 快速部署
      { path: 'rapid-deployment', component: QuickDeployComponent },

      // 疫情上报列表
      {
        path: 'report-list',
        loadChildren: './report-list/report-list.module#ReporListModule',
      }, // 风险管控
      {
        path: 'risk-control',
        loadChildren: './risk-control/risk-control.module#RiskControlModule',
      },
      // 隐患排查
      {
        path: 'danger-check',
        loadChildren: './danger-check/danger-check.module#DangerCheckModule',
      },
      // 系统设置
      { path: 'system', loadChildren: './system/system.module#SystemModule' },
      // Message 模块
      {
        path: 'message',
        loadChildren: './message/message.module#MessageModule',
      },
      // 服务支持 模块
      {
        path: 'service',
        loadChildren: './service/service.module#ServiceModule',
      },
      // 培训 模块
      { path: 'train', loadChildren: './train/train.module#TrainModule' },
      // 台账 模块
      { path: 'books', loadChildren: './books/books.module#BooksModule' },
      // 作业票
      { path: 'ticket', loadChildren: './ticket/ticket.module#TicketModule' },
      // 帮助中心
      { path: 'help', loadChildren: './help/help.module#HelpModule' },
      // 事故事件
      {
        path: 'accident',
        loadChildren: './accident/accident.module#AccidentModule',
      },
      // 预警预测
      // 设备设施
      { path: 'facility', loadChildren: './facility/facility.module#FacilityModule' },
      {
        path: 'warning',
        loadChildren: './warning/warning.module#WarningModule',
      },
      // 任务管理
      { path: 'task', loadChildren: './task/task.module#TaskModule' },
      {
        path: 'setting',
        component: SettingComponent,
        children: [
          { path: '', redirectTo: 'personinfo', pathMatch: 'full' },
          {
            path: 'personinfo',
            component: InfoComponent,
          },
          {
            path: 'reset',
            component: ResetComponent,
          },
          {
            path: 'certificate',
            component: CertificateComponent,
          },
        ],
      },
      // 台账系统/ 应急管理
      { path: 'ledger', loadChildren: './ledger/ledger.module#LedgerModule' },
      // 危险化学品管理
      { path: 'chemical', loadChildren: './chemical/chemical.module#ChemicalModule' },
      // 视频监控
      { path: 'monitor', loadChildren: './monitor/monitor.module#MonitorModule' },
    {
        path: 'safety-publicity',
        loadChildren: './safety-publicity/safety-publicity.module#SafetyPublicityModule',
      },
	  { path: 'major-hazard', loadChildren: './major-hazard/major-hazard.module#MajorHazardModule' },
    ],
  },
  // 系统初始化设置布局
  {
    path: '',
    component: LayoutNoHeaderComponent,
    children: [
      {
        path: 'rapid-deployment-account',
        component: QuickDeployAccountComponent,

      }
    ],
  },
  // 全屏布局
  {
    path: 'data-v',
    component: LayoutFullScreenComponent,
    children: [
      { path: '', loadChildren: './data-v/data-v.module#DataVModule' },
    ],
  },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      {
        path: 'login',
        component: UserLoginComponent,
        data: { title: '登录', titleI18n: 'app.login.login' },
      },

      {
        path: 'register-result',
        component: UserRegisterResultComponent,
        data: { title: '注册结果', titleI18n: 'app.register.register' },
      },
      {
        path: 'lock',
        component: UserLockComponent,
        data: { title: '锁屏', titleI18n: 'app.lock' },
      },
    ],
  },
  {
    path: 'account/forgetpwd',
    component: UserForgetpwdComponent,
    data: { title: '忘记密码', titleI18n: 'app.login.forgot-password' },
  },
  {
    path: 'account/activation',
    component: UserRegisterComponent,
    data: { title: '注册' },
  },
  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  // 风险告知卡内容
  {
    path: 'riskcard-content/:type/:planId/:objectId',
    component: RiskcardContentComponent,
  },
  // h5隐患排查情况
  {
    path: 'dangercheck-h5/:riskPointId/:access_token',
    component: DangerCheckComponent,
  },
  // h5操作规程
  {
    path: 'work-rule/:riskPointId/:access_token',
    component: WorkRuleComponent,
  },
  //员工档案详情
  { path: 'files-info/:userid/:companyId', component: FilesInfoComponent },

  //单页面测试
  { path: 'single-page', component: SinglePageComponent },
  //数据大屏
  { path: 'data-lsd-login', component: DataLsdLoginComponent }, //登录
  {
    path: 'data-lsd-v2',
    canActivate: [RouterGuardService],
    component: DataLsdV2Component,
  }, //企业大屏第二版
  {
    path: 'data-lsd-v3',
    canActivate: [RouterGuardService],
    component: DataLsdV3Component,
  }, // 企业大屏第二版 私有企业页面
  {
    path: 'data-lsd-v4',
    canActivate: [RouterGuardService],
    component: DataLsdV4Component,
  }, // 企业大屏第四版 私有企业页面
  // {
  //   path: 'enterprise-apply',
  //   component: EnterpriseApplyComponent,
  //   data: { title: '豫交安免费注册' }
  // }, // 企业申请
  {
    path: 'data-traffic-v2',
    canActivate: [RouterGuardService],
    component: DataTrafficV2Component,
  }, //交通行业大屏
  { path: 'demo', loadChildren: './test/test.module#TestModule' }, //临时演示
  {
    path: 'data-edu-v2',
    canActivate: [RouterGuardService],
    component: DataEduV2Component,
  }, //企业大屏第二版//公告详情h5页面
  {
    path: 'data-lsd-build',
    canActivate: [RouterGuardService],
    component: DataLsdBuildComponent,
  }, //建筑大屏
  {
    path: 'data-health',
    canActivate: [RouterGuardService],
    loadChildren: '../big-screen/health/health.module#HealthModule',
  },
  // 灵思定制卫生大屏
  {
    path: 'data-lsd-v5',
    component: DataLsdV5Component
  },
  {
    path: 'data-culture',
    canActivate: [RouterGuardService],
    component: CultureComponent,
  },
  { path: 'notice-page/:id/:token', component: NoticePageComponent },
  // { path: 'notice-page', component: NoticePageComponent },
  // 疫情上报
  { path: 'health-report/:token', component: HealthReportComponent },
  //自来水自动登录
  { path: 'water/:mobile/:token', component: WaterComponent },
  // 应急预案内容
  { path: 'ledger-content/:id/:token', component: LedgerContentComponent },

  { path: '**', redirectTo: 'exception/404' },
  // h5风险点列表
  {
    path: 'risk-point/:companyId/:geoPositionCode',
    component: RiskPointComponent,
    data: { title: ' ' }
  },
  // h5风险点风险告知卡
  {
    path: 'risk-card/:companyId/:riskId/:riskType',
    component: RiskCardComponent,
    data: { title: ' ' }
  },
  // 风险告知卡内容v2
  {
    path: 'riskcard-content-v2/:riskPointType/:objectId',
    component: RiskcardContentV2Component,
    data: { title: ' ' }
  },
  //事故案例详情
  {
    path: 'accident-case/:id/:token',
    component: AccidentCaseComponent,
    data: { title: ' ' }
  },
  //安全专家在线注册
  {
    path: 'safe-online-registration',
    component: SafeOnlineRegistrationComponent,
    data: { title: '安全专家在线' }
  },
  //注册成功
  {
    path: 'registra-success',
    component: RegistraSuccessComponent,
    data: { title: '安全专家在线' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
      // Pls refer to https://ng-alain.com/components/reuse-tab
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule { }
