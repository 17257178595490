import { Component, OnInit } from '@angular/core'; import { baseUrl, MessageService } from '@core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzCollapseModule } from 'ng-zorro-antd';

import { riskUrl } from '@core';
import { fromEvent } from 'rxjs';

declare var ehs: any;  // 安卓附件下载函数
declare var webkit: any;  // ios附件下载函数
@Component({
  selector: 'app-accident-case',
  templateUrl: './accident-case.component.html',
  styleUrls: ['./accident-case.component.less']
})
export class AccidentCaseComponent implements OnInit {
  params: any = {};
  accidentName: any = '';
  accidentDetail: any = '';
  //图片弹框属性
  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: _HttpClient,
    private modal: ModalHelper) { }

  async ngOnInit() {
    this.params = this.route.snapshot.params;
    await this.getData();

  }
  getData() {
    this.http.get<any>(`${baseUrl}/hazard/v1/riskPushMessage/findMessageById?id=${this.params.id}&access_token=${this.params.token}`).subscribe(res => {
      if (res.code === 0) {
          let data = res.data;
          if (data.pushType == 2) {
            this.accidentDetail = data.accidentDetail;
            this.accidentName = data.accidentName;
          }
        }
    });
  }

  /**
   * 图片弹框预览
   * @param event
   */
  open(event:any){
    if(event.target.localName === 'img'){
      this.previewImage = event.target.currentSrc;
      this.previewVisible = true;

    }
    if(event.target.localName === 'a'){
      const fileUrl = event.target.href;
      const fileName = event.target.innerHTML;

      var ua = window.navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        var obj = {
          "fileName":fileName,
          "fileUrl":fileUrl
        };
        webkit.messageHandlers.downLoadFile.postMessage(obj);
      } else if (/android/.test(ua)) {
        ehs.downLoadFile(fileName,fileUrl);
      }
    }
  }

}
