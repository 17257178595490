import { BigScreenService } from 'app/big-screen/services/big-screen.service';
import { EChartOption } from 'echarts';
import { combineLatest, forkJoin, from, Observable, concat } from 'rxjs';
import { concatMap, map, reduce, startWith } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MessageService } from '@core';
import { _HttpClient } from '@delon/theme';

import {
  HealthRiskLocationData,
  HiddenAreaRequest,
  HiddenAreaResponse,
  HiddenResponsibleDepartmentRequest,
  HiddenResponsibleDepartmentResponse,
  HiddenStatistics,
  HiddenTrendStatistics,
  RiskActivityType,
  RiskAmountResponse,
  HealthHiddenStatisticsResponse,
  HealthHiddenTypeStatisticsResponse,
  HiddenStatisticsRequest
} from '../../../routes/interface/api.interface';
import { ChartService } from './chart.service';

export type HiddenPolylineCategory =
  | Exclude<keyof HiddenTrendStatistics, 'yearAndMonth'>
  | 'total';

export type RiskByLocationCategory = Exclude<
  keyof HealthRiskLocationData,
  'groupId' | 'groupName'
>;

export type HiddenRiskStatistics = Partial<
  HealthHiddenStatisticsResponse | RiskAmountResponse
>;

export type HiddenRiskStatisticType =
  | keyof HealthHiddenStatisticsResponse
  | keyof RiskAmountResponse;

export interface HiddenAndRiskNotification {
  name: string;
  department: string;
  registerTime: string;
  briefName:string;
  checkName:string;
  geographyPositionName:string;
  type:boolean
}

export enum compareRiskTypes {
  LS = 1,
  LEC,
  MES,
}


@Injectable({
  providedIn: 'root',
})
export class DataService extends BigScreenService {
  constructor(
    public http: _HttpClient,
    public msgService: MessageService,
    private chartService: ChartService,
  ) {
    super(http, msgService);
  }

  /* ---------------------------------------Api Request-------------------------------------------------*/

  getHiddenStatisticsByDepartment(
    req: HiddenResponsibleDepartmentRequest,
  ): Observable<HiddenStatistics[]> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.statistics,
      this.subPath.department,
    ]);

    return this.get<HiddenResponsibleDepartmentResponse>(url, {
      ...this.tokenParam,
      ...req,
    }).pipe(map(res => res.data));
  }

  getHiddenStatisticsByLocation(
    req: HiddenAreaRequest,
  ): Observable<HiddenStatistics[]> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.statistics,
      this.subPath.location,
    ]);

    return this.get<HiddenAreaResponse>(url, {
      ...this.tokenParam,
      ...req,
    }).pipe(map(res => res.data));
  }

  getHiddenStatistics(): Observable<HealthHiddenStatisticsResponse> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.companyscreens,
      this.subPath.health,
      this.tertiaryPath.number,
    ]);

    return this.getWithToken<HealthHiddenStatisticsResponse>(url);
  }

  getHiddenTypeStatistics(): Observable<HealthHiddenTypeStatisticsResponse> {
    const url = this.completeUrl(this.hazard, [
      this.rootPath.statistics,
      this.subPath.chart,
    ]);
    const req: HiddenStatisticsRequest = { classifiedType: 'category' };

    return this.get<HealthHiddenTypeStatisticsResponse>(url, {
      ...this.tokenParam,
      ...req,
    });
  }

  /* ---------------------------------------Data transform-------------------------------------------------*/

  getHiddenPolylineChartOptions(
    lineCategories: HiddenPolylineCategory[],
  ): Observable<EChartOption> {
    const nameMap: { [p in HiddenPolylineCategory]: string } = {
      greatCount: '重大隐患',
      generalCount: '一般隐患',
      total: '总数',
    };
    const extra: EChartOption = {
      color: ['#ff001b', '#0086f9', '#9d77de'],
    };

    return this.getHiddenTrendStatistics().pipe(
      map(res => {
        const xData = res.map(item => item.yearAndMonth);
        const series = lineCategories.map(key => ({
          data: res.map(v =>
            key === 'total' ? v.greatCount + v.generalCount : v[key],
          ),
          name: nameMap[key],
          type: 'line',
          showSymbol: false,
          hoverAnimation: false,
          lineStyle: {
            width: 1,
          },
        }));

        return this.chartService.getOptions(xData, series, extra);
      }),
    );
  }

  getActivityRiskColumnChartOptions(): Observable<EChartOption[]> {
    const types = [
      compareRiskTypes.LS.toString(),
      compareRiskTypes.LEC.toString(),
      compareRiskTypes.MES.toString(),
    ] as RiskActivityType[];
    return from(types).pipe(
      concatMap(type =>
        this.getActivityRisk({ type }).pipe(
          map(res => {
            if(res){
              res.forEach((item) => {
                switch (item.level){
                  case 1:
                    item.setcolor= '#FF0000';
                    break;
                  case 2:
                    item.setcolor = '#FF8800';
                    break;
                  case 3:
                    item.setcolor = '#FFFF00';
                    break;
                  case 4:
                    item.setcolor = '#5abaff';
                    break;    
                }
              });
              const xData = res.map(item => item.typeName);
              const series = [
                {
                  data: res.map(item => item.valueTotal || 0),
                  type: 'bar',
                  barWidth: '5',
                  itemStyle: {
                    normal: { //每根柱子颜色设置
                        color: function(params) {
                          var colorList = (res || []).map(v => v.setcolor)
                          return colorList[params.dataIndex];
                        }
                    }
                },

                },
              ];

              return this.chartService.getOptions(xData, series, null, [
                'legend',
              ],);
            }
    
          }),
        ),
      ),
      reduce(
        (acc: echarts.EChartOption[], cur: echarts.EChartOption) => [
          ...acc,
          cur,
        ],
        [],
      ),
    );
  }

  getHealthRiskColumnChartOptions(
    categories: RiskByLocationCategory[],
  ): Observable<EChartOption> {
    const nameMap: { [p in RiskByLocationCategory]: string } = {
      greatRiskCount: '重大风险',
      generalRiskCount: '一般风险',
      relativeRiskCount: '较大风险',
      lowRiskCount: '低风险',
    };
    const extra: EChartOption = {
      color: ['#ff001b', '#ff7b26', '#faf73a'],
    };

    return this.getRiskByLocation().pipe(
      map(({ data = [] }) => {
        const xData = data.map(item => item.groupName);
        const series = categories.map(key => ({
          name: nameMap[key],
          data: data.map(v => v[key]),
          type: 'bar',
          barMaxWidth: '50',
        }));

        return this.chartService.getOptions(xData, series, extra);
      }),
    );
  }

  getHiddenAndRiskStatistics(
    types: HiddenRiskStatisticType[],
  ): Observable<HiddenRiskStatistics[]> {
    return combineLatest(
      this.getHiddenStatistics().pipe(startWith(null)),
      this.getRiskAmountStatistics().pipe(startWith(null)),
      (hidden, risk) => ({ ...hidden, ...risk }),
    ).pipe(map(data => types.map(key => ({ [key]: data[key] || '0' }))));
  }

  getHiddenAndRiskNotifications(): Observable<HiddenAndRiskNotification[]> {
    return forkJoin(
      this.getMajorRiskNotification(),
      this.getMajorHiddenNotification(),
    ).pipe(
      map(([risk, hidden]) => {
        const riskResult: HiddenAndRiskNotification[] = risk.map(
          ({ objectName, adminBranchName,checkName,geographyPositionName }) => ({
            name: objectName,
            briefName:objectName.length > 15 ? objectName.substring(0,15) + '...' : objectName,
            checkName:checkName,
            geographyPositionName:geographyPositionName,
            department: adminBranchName,
            registerTime: '',
            type:true
          }),
        );
        const hiddenResult: HiddenAndRiskNotification[] = hidden.map(
          ({ potentialName, potentialDepartmentName, addTime }) => ({
            name: potentialName,
            briefName:potentialName.length > 15 ? potentialName.substring(0,15) + '...' : potentialName,
            department: potentialDepartmentName,
            registerTime: addTime,
            checkName:'',
            geographyPositionName:'',
            type:false
          }),
        );
        riskResult.push.apply(riskResult,hiddenResult);
        return [...riskResult];
      }),
    );
  }

  getHiddenTypeRadarOptions(): Observable<EChartOption> {
    return this.getHiddenTypeStatistics().pipe(
      map(({ chart }) => {
        const series: EChartOption.SeriesRadar.DataObject[] = [
   
          {
            
            value: chart.map(({ greatCount }) => greatCount),
            name: '重大隐患',
            lineStyle: {
                color: '#F5222D',
            },
            itemStyle: {
              color: '#F5222D',
            },
          },
          {
            value: chart.map(({ generalCount }) => generalCount),
            name: '一般隐患',
            lineStyle: {
              color: '#1890FF',
            },
            itemStyle: {
              color: '#1890FF',
            },
          },
        ];
        const indicator = chart.map(({ groupName }) => ({ name: groupName }));

        return this.chartService.getRadarOptions(indicator, series);
      }),
    );
  }
}
