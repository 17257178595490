import { Component, OnInit } from '@angular/core'; import { MessageService } from '@core';
import { LazyService } from '@delon/util';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http'
import { ElementRef} from '@angular/core';
import { mockUrl, baseUrl, access_token } from '@core';


declare var THREE: any;  //three.js
@Component({
  selector: 'layout-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.less'],
})


export class LayoutPassportComponent implements OnInit {

  constructor(
    private lazy: LazyService,
    public http: HttpClient,
    public msg: MessageService,
    private el:ElementRef
  ) { }
  links = [
    {
      title: '帮助',
      href: '',
    },
    {
      title: '隐私',
      href: '',
    },
    {
      title: '条款',
      href: '',
    },
  ];
  comname='欢迎登录豫交安';

  ngOnInit() {
      this.getImg()
    // this.lazy
    //   .loadScript('https://cdn.bootcss.com/three.js/r56/three.min.js').then(() => {
    //     var SEPARATION = 100, AMOUNTX = 50, AMOUNTY = 50;

    //     var container;
    //     var camera, scene, renderer;

    //     var particles, particle, count = 0;

    //     var mouseX = 0, mouseY = 0;

    //     var windowHalfX = window.innerWidth / 2;
    //     var windowHalfY = window.innerHeight / 2;

    //     init();
    //     animate();

    //     function init() {

    //       container = document.createElement('div');
    //       container.style.position = "absolute";
    //       container.style.top = '0';
    //       container.style.left= '0';
    //       container.style.width= '100%';
    //       container.style.height= '100%';
    //       container.style.overflow= 'hidden';
    //       document.getElementById('container-canvas').appendChild(container);

    //       camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
    //       camera.position.z = 1000;

    //       scene = new THREE.Scene();

    //       particles = new Array();

    //       var PI2 = Math.PI * 2;
    //       var material = new THREE.ParticleCanvasMaterial({

    //         color: 0xffffff,
    //         program: function (context) {

    //           context.beginPath();
    //           context.arc(0, 0, 1, 0, PI2, true);
    //           context.fill();

    //         }

    //       });

    //       var i = 0;

    //       for (var ix = 0; ix < AMOUNTX; ix++) {

    //         for (var iy = 0; iy < AMOUNTY; iy++) {

    //           particle = particles[i++] = new THREE.Particle(material);
    //           particle.position.x = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2);
    //           particle.position.z = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2);
    //           scene.add(particle);

    //         }

    //       }

    //       renderer = new THREE.CanvasRenderer();
    //       renderer.setSize(window.innerWidth, window.innerHeight);
    //       container.appendChild(renderer.domElement);

    //       document.addEventListener('mousemove', onDocumentMouseMove, false);
    //       document.addEventListener('touchstart', onDocumentTouchStart, false);
    //       document.addEventListener('touchmove', onDocumentTouchMove, false);

    //       //

    //       window.addEventListener('resize', onWindowResize, false);

    //     }

    //     function onWindowResize() {

    //       windowHalfX = window.innerWidth / 2;
    //       windowHalfY = window.innerHeight / 2;

    //       camera.aspect = window.innerWidth / window.innerHeight;
    //       camera.updateProjectionMatrix();

    //       renderer.setSize(window.innerWidth, window.innerHeight);

    //     }

    //     //

    //     function onDocumentMouseMove(event) {

    //       mouseX = event.clientX - windowHalfX;
    //       // mouseY = event.clientY - windowHalfY;
    //       mouseY = -438;

    //     }

    //     function onDocumentTouchStart(event) {

    //       if (event.touches.length === 1) {

    //         event.preventDefault();

    //         mouseX = event.touches[0].pageX - windowHalfX;
    //         mouseY = event.touches[0].pageY - windowHalfY;

    //       }

    //     }

    //     function onDocumentTouchMove(event) {

    //       if (event.touches.length === 1) {

    //         event.preventDefault();

    //         mouseX = event.touches[0].pageX - windowHalfX;
    //         mouseY = event.touches[0].pageY - windowHalfY;

    //       }

    //     }

    //     //

    //     function animate() {

    //       requestAnimationFrame(animate);

    //       render();


    //     }

    //     function render() {

    //       camera.position.x += (mouseX - camera.position.x) * .05;
    //       camera.position.y += (- mouseY - camera.position.y) * .05;
    //       camera.lookAt(scene.position);

    //       var i = 0;

    //       for (var ix = 0; ix < AMOUNTX; ix++) {

    //         for (var iy = 0; iy < AMOUNTY; iy++) {

    //           particle = particles[i++];
    //           particle.position.y = (Math.sin((ix + count) * 0.3) * 50) + (Math.sin((iy + count) * 0.5) * 50);
    //           particle.scale.x = particle.scale.y = (Math.sin((ix + count) * 0.3) + 1) * 2 + (Math.sin((iy + count) * 0.5) + 1) * 2;

    //         }

    //       }

    //       renderer.render(scene, camera);

    //       count += 0.1;

    //     }

    //   })

  }

  getImg(){
    let domain =window.location.host;// 获取域名
    this.http.get(`${baseUrl}/basics/v1/basicsinfo/`+domain,{ headers: { token: '' }}).subscribe((res: any) => {
      if (res.code == '0') {
        if(res.data && res.data.loginBackground){
          this.el.nativeElement.querySelector('.container').style.backgroundImage="url("+res.data.loginBackground+")";
        }
        if(res.data && res.data.companyLogo){
          this.el.nativeElement.querySelector('.logo').src=res.data.companyLogo;
        }

        if(res.data && res.data.systemName){
          this.comname=res.data.systemName;
        }
          
        }
      });
  }

}
