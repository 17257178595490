import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ticketUrl ,CommonService} from '@core';
import { NzMessageService } from 'ng-zorro-antd';
@Component({
  selector: 'app-tnlg-process',
  templateUrl: './tnlg-process.component.html',
  styleUrls: ['./tnlg-process.component.less']
})
export class TnlgProcessComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() templateId: string = '';
  @Input() processKey: string = '';
  @Input() status: number = 0;

  approval: any = {
    approved: '1',
    advice: '同意'
  };
  instanceOfData = [];

  imgUrl: string;
  access_token: any = JSON.parse(window.localStorage.getItem('_token')).access_token;
  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (typeof (changes['id'].currentValue) !== 'undefined') {
      this.id = changes['id'].currentValue;
      this.templateId = changes['templateId'].currentValue
      this.status = changes['status'].currentValue
      
      let key;
      let insKey;

      let onProcess:boolean;
      let proId:string;
      if (this.processKey==('jobticket_job')) {
        insKey = 'job-approvals';
        onProcess = this.status>1;
        proId="businessProcessId";
      } else {
        insKey = 'postpone-approvals';
        onProcess = this.status!==2
        proId="postProcessId"
      }

      if(!onProcess){
        this.http.get<any>(`${ticketUrl}/jobticket/job-ticket-templates/base/id?id=${this.templateId}`).subscribe(res => {
          if (res.code == 0) {
            this.imgUrl = `${ticketUrl}/bpm/process-instance/diagram0?businessProcessId=${res.data[proId]}&access_token=${this.access_token}&time=` + new Date().getTime();
          } else {
            this.msg.error(res.msg || '加载失败');
          }
        });
      }else{
        this.imgUrl = `${ticketUrl}/bpm/process-instance/diagram?formId=${this.id}&access_token=${this.access_token}&time=` + new Date().getTime();
      }

      // if (this.status == '1' && !this.rejectTime) {
      //   return;
      // }
      this.getInstance(insKey);
    }

  }

  getInstance(insKey: string) {
    let formKey = {};
    if (insKey == 'job-approvals') {
      formKey = {
        jobTaskId: this.id
      }
    } else {
      formKey = {
        postponeId: this.id
      }
    }
    this.http.get<any>(`${ticketUrl}/jobticket/${insKey}/record`, formKey).subscribe(res => {
      if (res.code == 0) {
        this.instanceOfData = res.data;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }


}
