import { CascaderOption, NzMessageService } from 'ng-zorro-antd';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { baseUrl } from '@core';
import { _HttpClient } from '@delon/theme';
import { LazyService } from '@delon/util';
import * as moment from 'moment';

declare var ehs: any;  // 安卓标识
declare var webkit: any;  // ios标识

export interface HealthInterface {
  code: string | number;
  msg: string;
  data: HealthDataInterface[];
}
export interface HealthDataInterface {
  name: string;
  phone: string;
  idCard: string;
  address?: string;
  province: string;
  city: string;
  district: string;
  onSiteStatus: string;
  workStatus: string;
  isolation: string;
  infection: string;
  contract: string;
  heat: string;
  gis: string;
}
export interface AddressDataInterface {
  value: string;
  label: string;
  isLeaf?: boolean;
  children?: [];
}
@Component({
  selector: 'app-health-report',
  templateUrl: './health-report.component.html',
  styleUrls: ['./health-report.component.less'],
})
export class HealthReportComponent implements OnInit {
  date:string = moment().format('YYYY-MM-DD HH:mm:ss');
  token: string;
  params: any = {};

  loading: Boolean = false;
  adressVisible: Boolean = false;
  isVisible: Boolean = false;
  map: any = {};
  address = '';
  gis: string ;
  values: string[] | null = null;
  // provinces = provinces;
  // 省市区数据
  provinceData: Array<AddressDataInterface> = [];
  cityData: Array<AddressDataInterface> = [];
  districtData: Array<AddressDataInterface> = [];
  healData: any = {};
  // 是否可提交
  isReport = true;
  isDisabled = false;
  constructor(
    private route: ActivatedRoute,
    public msg: NzMessageService,
    private sanitizer: DomSanitizer,
    private http: _HttpClient,
    private lazy: LazyService,
  ) {}

  ngOnInit() {
    
     this.route.queryParams.subscribe(data => {
        this.params = {
          ...this.route.snapshot.params,
          ...data 
        }
     })
    this.loadProvince();
    if(this.params.id){
      this.isReport = false;
      this.isDisabled = true;
      this.getDetail();
      
    }else{
      this.getData();
    }
    
    // 安卓调用方法传参
     window['setLocation'] = (address) => {
      this.setLocation(address)
     }
     window['alertWithMessage'] = (address) => {
      this.alertWithMessage(address)
     }
     
   
  }
  /**
   * 获取上报信息 
   */
  getData() {
    this.http
      .get<HealthInterface>(`${baseUrl}/hazard/app/v1/health-sign-in/last-record?access_token=${this.params['token']}`)
      .subscribe(res => {
        if (res.code === 0) {
           this.healData = res.data || {};
        } else {
          this.msg.error(res.msg);
        }
      });
  }
   /**
   * 获取h5列表上报记录 
   */
  getDetail() {
    this.http
      .get<HealthInterface>(`${baseUrl}/hazard/app/v1/health-sign-in/${this.params.id}?access_token=${this.params['token']}`)
      .subscribe(res => {
        if (res.code === 0) {
           this.healData = res.data || {};
           this.date = this.healData.createTime;
        } else {
          this.msg.error(res.msg);
        }
      });
  }
  /**
   * 加载省数据
   *
   */
  loadProvince() {
    const params = {
      name: 'province',
      parentCode: '0',
    };
    return this.http
      .get<any>(`${baseUrl}/basics/v1/districts/data?access_token=${this.params['token']}`, params)
      .pipe(
        map(res => {
          if (res.code === 0) {
            // this.provinceData = res.data || [];
            return res.data.map(item => ({
              value: item.code,
              label: item.name,
            }));
          }
          return [];
        }),
      );
  }
  /**
   * 选择省时加载市
   *
   */
  loadCities(pid: string, cleanCity = true) {
    const params = {
      name: 'city',
      parentCode: pid,
    };
    return this.http
      .get<any>(`${baseUrl}/basics/v1/districts/data?access_token=${this.params['token']}`, params)
      .pipe(
        map(res =>
          res.data.map(item => ({ value: item.code, label: item.name })),
        ),
      );
  }
  /**
   * 选择市时加载区
   *
   */
  loadDistrict(pid: string, cleanDistrict = true) {
    const params = {
      name: 'district',
      parentCode: pid,
    };

    return this.http
      .get<any>(`${baseUrl}/basics/v1/districts/data?access_token=${this.params['token']}`, params)
      .pipe(
        map(res =>
          res.data.map(item => ({ value: item.code, label: item.name ,isLeaf: true})),
        ),
      );
  }

  loadData = (node: CascaderOption, index: number): PromiseLike<void> => {
    return new Promise(resolve => {
        if (index < 0) {
          this.loadProvince().subscribe(provinceData => {
            node.children = provinceData;
            resolve();
          });
        } else if (index === 0) {
          this.loadCities(node.value).subscribe(cityData => {
            node.children = cityData;
            resolve();
          });
        } else {
          this.loadDistrict(node.value).subscribe(data => {
            node.children = data;
            resolve();
          });
        }
    });
  };

  onSelectionChange(selectedOptions: CascaderOption[]): void {
    this.healData.province = selectedOptions[0].value;
    this.healData.city = selectedOptions[1].value;
    this.healData.district = selectedOptions[2].value;
    this.healData.address = selectedOptions.map(o => o.label).join(', ');
  }
  getCurrentAddress(){
    const ua = window.navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      webkit.messageHandlers.getLocation.postMessage(null);
    } else if (/android/.test(ua)) {
      ehs.getLocation();
    }
  }
  setLocation(address:string){
    this.healData.gis = address;
  }
  alertWithMessage(content) {
    alert(content);
  }
  // 表单保存
  save(){
    console.log(this.healData);
    const params ={
      ...this.healData
    };
    this.http
    .post<HealthInterface>(`${baseUrl}/hazard/app/v1/health-sign-in?access_token=${this.params['token']}`,params)
    .subscribe(res => {
      if (res.code === 0) {
        this.msg.success(res.msg || '提交成功');
        const ua = window.navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(ua)) {
          webkit.messageHandlers.finish.postMessage(null);
        } else if (/android/.test(ua)) {
          ehs.finish();
        }
        
      } else {
        this.msg.error(res.msg);
      }
    });
  }
}
