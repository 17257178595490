import * as moment from 'moment';
import { NzCarouselComponent } from 'ng-zorro-antd';

import {
    AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren
} from '@angular/core';
import { AuthService, DocumentService, NavigationService } from '@core';

import {
    ChartStatisticsType, CompanysScreen, CultureService, ObjectStatisticsType, RiskType
} from './culture.service';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.less'],
  providers: [CultureService],
})
export class CultureComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(NzCarouselComponent) carouselRefs: QueryList<
    NzCarouselComponent
  >;

  companysScreen: CompanysScreen = {} as any;
  radioValue: any = '1';
  notifyType = '1';
  datetime = '';

  notices = [];
  /**
   * 重要节日隐患统计图
   */
  tourismNumber;
  /**
   * 区域隐患统计图 (区域)
   */
  areaChartStatistics;
  /**
   * 区域隐患统计图（类别）
   */
  categoryChartStatistics;
  /**
   * 作业活动风险对比图
   */
  activityRiskOption;

  /**
   * 风险点统计图
   */
  objectStatistics1;
  objectStatistics2;

  /**
   * 风险统计数量
   */
  statisticsRisknum = [];
  /**
   * 隐患统计数量
   */
  dangerList = [];
  /**
   * 区域风险统计图
   */
  checkItemLocationStatistics;
  /**
   * 未整改隐患统计
   */
  notRectifiedDangerList = [];
  /**
   * 重大隐患统计
   */
  hiddenDangerNotification = [];
  /**
   * 重大风险统计
   */
  riskNotification = [];
  options = [
    { label: 'LS', value: '1' },
    { label: 'LEC', value: '2' },
    { label: 'MES', value: '3' },
  ];

  notifyOptions = [
    { label: '重大风险', value: '1' },
    { label: '重大隐患', value: '2' },
    { label: '未整改隐患', value: '3' },
  ];

  riskList = [
    { name: '重大风险', key: 'oneRiskCount' },
    { name: '较大风险', key: 'twoRiskCount' },
    { name: '一般风险', key: 'threeRiskCount' },
    { name: '低风险', key: 'fourRiskCount' },
  ];
  dangerListMap = [
    { name: '年度隐患数量', key: 'currentYearTotalNumber' },
    { name: '月度隐患数量', key: 'currentMonthTotalNumber' },
    { name: '未整改隐患', key: 'cumulativeNotCorrected' },
    { name: '隐患整改率', key: 'cumulativeRectificationRate' },
  ];

  /**
   * 未整改隐患 map
   */
  notRectifiedDangerListMap = [
    { name: '隐患名称', key: 'potentialName' },
    { name: '隐患描述', key: 'potentialDetail' },
    { name: '隐患地点', key: 'potentialLocationIdName' },
    { name: '隐患类别', key: 'potentialCategoryName' },
    { name: '隐患等级', key: 'potentialLevel' },
    { name: '整改责任人', key: 'reformUserName' },
    { name: '整改期限', key: 'reformEndDate' },
  ];

  /**
   * 重大隐患公示
   */
  hiddenDangerNotificationMap = [
    { name: '隐患名称', key: 'potentialName' },
    { name: '隐患描述', key: 'potentialDetail' },
    { name: '隐患地点', key: 'potentialLocationIdName' },
    { name: '隐患类别', key: 'potentialCategoryName' },
    { name: '整改责任人', key: 'reformUserName' },
    { name: '隐患登记时间', key: 'addTime' },
  ];
  /**
   * 重大风险公示
   */
  riskNotificationMap = [
    { name: '风险点名称', key: 'objectName' },
    { name: '主要后果', key: 'consequences' },
    { name: '区域/位置', key: 'geographyPositionName' },
    { name: '责任单位', key: 'adminBranchName' },
    { name: '责任人', key: 'adminUserName' },
  ];

  get isFullscreen() {
    return this.documentService.isFullscreen;
  }
  constructor(
    private documentService: DocumentService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private pageService: CultureService,
  ) {}

  ngOnInit() {
    this.getCompanysScreen();
    this.getSystemNotice();
    this.getActivityRisk(this.radioValue);
    this.getRiskCountByType();
    this.getObjectStatistics('1');
    this.getObjectStatistics('2');
    this.getCheckItemLocationStatistics();
    this.getTourismNumber();
    this.getChartStatistics('area');
    this.getChartStatistics('category');
    this.getStatisticsRisknum();
    this.getCompanyscreens();
    this.getNotRectificationPublicity();
    this.getMajorHiddenDangerPublicity();
    this.getMajorNotification();
    this.setDateTime();
  }

  ngAfterViewInit() {
    this.documentService.setBodyOverflowHidden();
  }

  ngOnDestroy() {
    this.documentService.setBodyOverflowScroll();
  }
  // 设置日期时间
  setDateTime() {
    const dayMap = {
      0: '星期日',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
    };
    this.datetime =
      moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    setInterval(() => {
      this.datetime =
        moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    }, 1000);
  }
  getSystemNotice() {
    this.pageService
      .getSystemNotice()
      .subscribe(data => (this.notices = data.notice));
  }

  getMajorNotification() {
    this.pageService.getMajorNotification().subscribe(data => {
      this.riskNotification = (data['riskNotification'] || []).map(d =>
        this.riskNotificationMap.map(item => ({
          name: item.name,
          value: d[item.key],
        })),
      );
    });
  }

  getNotRectificationPublicity() {
    this.pageService.getNotRectificationPublicity().subscribe(data => {
      this.notRectifiedDangerList = (
        data['getNotRectificationPublicity'] || []
      ).map(d =>
        this.notRectifiedDangerListMap.map(item => ({
          name: item.name,
          value: d[item.key],
        })),
      );
    });
  }

  getMajorHiddenDangerPublicity() {
    return this.pageService.getMajorHiddenDangerPublicity().subscribe(data => {
      this.hiddenDangerNotification = (
        data['hiddenDangerNotification'] || []
      ).map(d =>
        this.hiddenDangerNotificationMap.map(item => ({
          name: item.name,
          value: d[item.key],
        })),
      );
    });
  }

  getCompanyscreens() {
    return this.pageService.getCompanyscreens().subscribe(
      data =>
        (this.dangerList = this.dangerListMap.map(item => ({
          name: item.name,
          count: data[item.key],
        }))),
    );
  }

  getStatisticsRisknum() {
    this.pageService.getStatisticsRisknum().subscribe(
      data =>
        (this.statisticsRisknum = this.riskList.map(risk => ({
          name: risk.name,
          count: data[risk.key],
        }))),
    );
  }
  getTitle() {
    return (
      this.notifyOptions.find(opt => opt.value === this.notifyType)['label'] +
      '公示'
    );
  }

  getTourismNumber() {
    this.pageService.getTourismNumber().subscribe(data => {
      data = data.map((v, i) => ({ ...v, month: `${i + 1}月` }));
      this.tourismNumber = this.pageService.createChartOption({
        data: data,
        legend: true,
        xAxisNames: [
          '元旦',
          '春节',
          '清明节',
          '劳动节',
          '端午节',
          '中秋节',
          '国庆节',
        ],
        series: [
          {
            name: '重大隐患',
            type: 'line',
            valueKey: 'majorRectified',
          },
          {
            name: '一般隐患',
            type: 'line',
            valueKey: 'generalRectified',
          },
        ],
      });
    });
  }
  /**
   * 公司名称 logo
   */
  getCompanysScreen() {
    this.pageService
      .getCompanysScreen()
      .subscribe(data => (this.companysScreen = data));
  }
  /**
   * 作业活动风险值对比图
   */
  getActivityRisk(type: RiskType) {
    const colors = ['#FF0000', '#FF8800', '#FFFF00', '#5abaff'];      
    this.pageService.getActivityRisk(type).subscribe(activityRisks => {
      this.activityRiskOption = this.pageService.createChartOption({
        data: activityRisks,
        xAxisNameKey: 'typeName',

        series: [
          {
            name: '',
            valueKey: 'valueTotal',
            itemStyle: {
              normal: {
                color: function(params) {
                  const level = params.data.level;
                  return colors[level - 1];
                },
              },
            },
          },
        ],
      });
    });
  }
  /**
   * 风险点统计图
   */
  getObjectStatistics(type: ObjectStatisticsType) {
    const title = `风险点统计图（${type === '1' ? '设备设施' : '作业活动'}）`;
    const color = type === '1' ? '#a224e3' : '#14cd68';
    this.pageService.getobjectStatistics(type).subscribe(d => {
      this[`objectStatistics${type}`] = this.pageService.createChartOption({
        data: d.objectStatistics,
        title,
        xAxisNameKey: 'typeName',
        series: [
          {
            name: '',
            valueKey: 'count',
            barWidth: 20,
            color,
          },
        ],
      });
    });
  }

  /**
   * 区域风险统计图
   */
  getCheckItemLocationStatistics() {
    return this.pageService.getCheckItemLocationStatistics().subscribe(
      data =>
        (this.checkItemLocationStatistics = this.pageService.createChartOption({
          data: data.riskLocationStatistics,
          xAxisNameKey: 'groupName',
          legend: true,
          series: [
            {
              name: '重大风险',
              valueKey: 'greatRiskCount',
            },
            {
              name: '较大风险',
              valueKey: 'relativeRiskCount',
            },
            {
              name: '一般风险',
              valueKey: 'generalRiskCount',
            },
            {
              name: '低风险',
              valueKey: 'lowRiskCount',
            },
          ],
        })),
    );
  }
  /**
   * 风险点统计
   */
  getRiskCountByType() {
    this.pageService
      .getRiskCountByType('1')
      .subscribe(data => console.log(data));
  }

  getChartStatistics(type: ChartStatisticsType) {
    this.pageService.getChartStatistics(type).subscribe(data => {
      this[`${type}ChartStatistics`] = this.pageService.createChartOption({
        data: data,
        legend: true,
        xAxisNameKey: 'groupName',
        series: [
          {
            name: '重大隐患',
            valueKey: 'greatCount',
          },
          {
            name: '一般隐患',
            valueKey: 'generalCount',
          },
        ],
      });
    });
  }

  onExpand() {
    this.documentService.toggleFullscreen();
  }

  onHome() {
    this.navigationService.toHomePage();
  }

  onShut() {
    this.authService.logout().subscribe();
  }
}
