import { Component, OnInit, Output, EventEmitter, Input ,TemplateRef,ViewChild} from '@angular/core';
import { MessageService ,baseUrl} from '@core';
import { _HttpClient } from '@delon/theme';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
@Component({
  selector: 'xls-upload',
  templateUrl: './xls-upload.component.html',
  styleUrls: ['./xls-upload.component.less']
})
export class XlsUploadComponent implements OnInit {
  @ViewChild(TemplateRef) template: TemplateRef<{}>;
  @ViewChild('tperror') tperror: TemplateRef<any>;
  @ViewChild('tptsuccess') tptsuccess: TemplateRef<any>;
  //导入地址
  @Input() url = '';
  //上传参数
  @Input() uploadParams: any = {};
  //模板下载地址
  @Input() templateUrl = '';
  //导入成功的事件
  @Output() uploadSuccess = new EventEmitter<any>();
  // 是否动态下载导入模板
  @Input() switchtrends = false;
  loading = false;
  title ='';
  errorlist = '';
  downloading = false;
  constructor(private message: MessageService, private notification: NzNotificationService,private http: _HttpClient,) { }

  ngOnInit() {
  }

  beforeUpload = (file: File) => {
    if (file.name.slice(-4) == '.xls' || file.name.slice(-5) == '.xlsx') {
      this.loading = true;
      return true;
    }
    this.message.error('请选择Excel文件!');
    return false;
  };

  /**
   * 文件上传状态改变
   * @param UploadChangeParam 
   */
  fileChange(event) {
    if (event.file.status == 'done') {
      if (event.file.response.code == 0) {
       // this.message.success(event.file.response.msg || '导入成功');
       const msgs =  { title: event.file.response.msg || '导入成功'};
       this.notification.template(this.tptsuccess, { nzData: msgs,nzDuration: 0 });
        this.uploadSuccess.emit();
        this.loading = false;
      } else {
        // event.file.response.data = ['错误消息1', '错误消息2'];
        let title = event.file.response.msg || '导入错误';
        console.log(title);
        let errorList = event.file.response.data || []; 
        //this.notification.error(title, errorList.join('<br />'), { nzDuration: 5000 });
        const errors =  { title: title, errorList: errorList.join('<br />') };
        this.notification.template(this.tperror, { nzData: errors,nzDuration: 0 });
        this.loading = false;
      }
      return;
    }
    if (event.file.status == 'error') {
      this.message.error('文件上传失败!');
      this.loading = false;
      return;
    }
  }
  downloadTemplate(){
    this.downloading = true;
    this.http.get<any>(`${baseUrl}/admin/v1/users/model`,{},{ responseType: 'blob', observe: 'response' }).subscribe(res => {
      if (res.body.code == 1) {
        this.downloading = false;
        this.message.error('导出不成功!');
        return;
      }

      try {
        const link = document.createElement('a');
        const blob = new Blob([<any>res.body]);
        let fileNmae = `用户导入模板.xlsx`;
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileNmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.message.error(error.toString());
      }
    });
  }
}
