import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

export interface BigScreenLayoutConfig {
  gdColumns?: string;
  gdGap?: string;
  gdRowsMiddle?: string;
}

@Component({
  selector: 'app-big-screen',
  templateUrl: './big-screen.component.html',
  styleUrls: ['./big-screen.component.scss'],
})
export class BigScreenComponent implements OnInit {
  @Input() bcgImage: string = '';

  @Input() bcgColor: string = '';

  @Input() set layoutConfig(input: BigScreenLayoutConfig) {
    if (!!input) {
      this._layoutConfig = { ...this._layoutConfig, ...input };
    }
  }

  get layoutConfig(): BigScreenLayoutConfig {
    return this._layoutConfig;
  }

  private _layoutConfig: BigScreenLayoutConfig = {
    gdColumns: '20% 50% 30%',
    gdGap: '20px',
  };

  @ViewChild('header') header: ElementRef;

  bodyHeight: string = '0px';

  constructor() {}

  ngOnInit() {
    const height = this.header.nativeElement.offsetHeight + 'px';

    this.bodyHeight = `calc(100vh - ${height})`;
  }

  get bcgImageAddress(): string {
    return this.bcgImage
      ? `url(../../../../../assets/images/big-screen/${this.bcgImage})`
      : '';
  }
}
