import { Injectable } from '@angular/core';
import { EChartOption } from 'echarts';

const grid: EChartOption.Grid = {
  left: 0,
  right: 8,
  containLabel: true,
  bottom: 25,
  top: 30,
};

const legend: EChartOption.Legend = {
  right: 0,
  textStyle: {
    color: '#c7fffe',
  },
};

const tooltip: EChartOption.Tooltip = {
  trigger: 'axis',
  confine: true,
  axisPointer: {
    type: 'shadow',
    label: {
      backgroundColor: '#6a7985',
    },
  },
};

const xAxis: EChartOption.XAxis = {
  type: 'category',
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    color: '#c7fffe',
    margin: 2,
  },
};

const yAxis: EChartOption.YAxis = {
  type: 'value',
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  axisLabel: {
    color: '#c7fffe',
  },
  splitLine: {
    lineStyle: {
      color: '#044d67',
    },
  },
};

const dataZoom: EChartOption.DataZoom[] = [
  {
    type: 'slider',
    xAxisIndex: 0,
    backgroundColor: '#063c58',
    dataBackground: {
      lineStyle: {
        width: 0,
      },
      areaStyle: {
        color: '#0c586c',
        opacity: 1,
      },
    },
    fillerColor: 'rgba(0, 54, 69, 0.58)',
    handleStyle: {
      color: '#00b4cf',
    },
    borderColor: 'transparent',
    textStyle: {
      color: 'transparent',
    },
    // @ts-ignore
    height: 19,
    bottom: 0,
  },
  {
    type: 'inside',
    xAxisIndex: 0,
  },
];

export type XData =
  | string
  | number
  | EChartOption.BasicComponents.CartesianAxis.DataObject;

type optionKey = keyof EChartOption;

type OptionsFn = (
  xData: XData[],
  series: (EChartOption.SeriesLine | EChartOption.SeriesRadar)[],
  extra?: EChartOption,
  omit?: optionKey[],
) => EChartOption;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor() { }

  getOptions: OptionsFn = (xData, series, extra = {}, omit = []) => {
    const xConfig = { ...xAxis, data: xData };
    const options: EChartOption = {
      grid,
      legend,
      tooltip,
      xAxis: xConfig,
      yAxis,
      dataZoom,
      series,
    };
    const filteredOptions = Object.entries(options).reduce(
      (acc, [key, value]) =>
        omit.includes(key as optionKey) ? acc : { ...acc, [key]: value },
      {},
    );
    return { ...filteredOptions, ...extra };
  };

  getRadarOptions(
    indicator: object[],
    seriesData: EChartOption.SeriesRadar.DataObject[],
  ) {
    const series: EChartOption.SeriesRadar[] = [
      { type: 'radar', data: seriesData },
    ];
    const radar = {
      name: {
        textStyle: { color: '#00e4ff' },
      },
      indicator,
      radius: 65
    };

    return this.getOptions(
      null,
      series,
      {
        radar,
        tooltip: {
          position: [20, 20]
        },
        legend: { ...legend, orient: 'vertical', right: 0, bottom: -4 },
      },
      ['grid', 'xAxis', 'yAxis', 'dataZoom'],
    );
  }
}
