/**
 * 工具服务
 */

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { _HttpClient } from '@delon/theme';

import { environment } from '../../environments/environment';
import { domain } from './constant';
import { format,differenceInCalendarDays } from 'date-fns';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  companyData = {};
  // 是否隐藏模块菜单
  isHideModuleSidebar = true;
  // 是否在首页
  isDashboard = false;

  // 当前登录用户的id
  get currentUserId() {
    return JSON.parse(window.localStorage._token || '{}').user_id;
  }
  // 当前登录用户的Jobid
  get currentJobId() {
    return JSON.parse(window.localStorage._token || '{}').job_id;
  }
  // 当前登录企业信息
  // get currentCompany() {
  //   return this.getCompany;
  // }
  // 更新OSS参数
  get getCompany() {
    this.OSS_Params.loading = true;
    let companyId = JSON.parse(window.localStorage._token || '{}').tenant_id;
    if (!companyId) return Promise.resolve(true);
    return this.http.get<any>(`https://testxaloss.oss-cn-beijing.aliyuncs.com/public/${companyId}/config.json`).toPromise().then(
      // return this.http.get<any>(`/sts`).toPromise().then(
      (res) => {
        this.OSS_Params.loading = false;
        if (res.code == 0) {
          this.companyData = res.data || {};
        }
      }, () => {
        this.OSS_Params.loading = false
      }
    );
  }
  // 是否使用服务器菜单
  useServerMenu = true;
  // 模块列表菜单
  moduleListMenu = [];

  // 登录后需要打开的页面
  loginBeforeUrl: string = null;

  // 禁止当前天之后时间
  today = new Date();
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  constructor(private http: _HttpClient) { }

  /**
   * 通过key获取node
   * @param treeData tree 数据源
   * @param key 
   */
  getNodeByKey(treeData: any[], key: string) {
    let node: any = {};
    const recursion = (arr) => {
      for (let item of arr) {
        if (item.key == key) {
          node = item;
          break;
        }
        if (Array.isArray(item.children)) {
          recursion(item.children);
        }
      }
    };
    recursion(treeData);
    return node;
  }

  /**
   * 风险颜色
   */
  color = {
    //重大
    zd: '#FF0000',
    //较大
    jd: '#FF8800',
    //一般
    yb: '#FFFF00',
    //低风险
    di: '#5abaff',
  };

  /**
   * 风险点类型
   */
  riskType =[
    {title:'设备设施类',value:'1'},
    {title:'作业活动类',value:'2'},
    {title:'工艺节点类',value:'3'},
    {title:'基础管理类',value:'0'},
  ];


  /**
   * 隐患颜色
   */
  hiddencolor = {
    //重大
    zd: '#FF0000',

    //一般
    yb: '#1890ff',
    
  };

  /**
   * OSS
   */
  OSS_Params = {
    loading: false,
    accessKeyId: '',
    accessKeySecret: '',
    securityToken: '',
    expiration: '2018-11-04T09:58:12Z',
  };
  get OSS() {
    // if (environment.production) {
    //   //生产环境
    //   return new window['OSS']({
    //     region: 'oss-cn-beijing',
    //     accessKeyId: 'LTAIVBm1yiqtxFnl',
    //     accessKeySecret: 'Uw4dMdZ1LvZZircPbeaHRQc8TDqiHL',
    //     bucket: 'prdxaloss',
    //     secure: true
    //   });
    // } else {
    //   //测试环境
    //   return new window['OSS']({
    //     region: 'oss-cn-beijing',
    //     accessKeyId: 'LTAIzpfbqAanWh30',
    //     accessKeySecret: 'w91asl9vXFUC0Xsf9cDI2MJ0DvXM41',
    //     bucket: 'testxaloss',
    //     secure: true
    //   });
    // }
    if (environment.production) {
      // 生产环境
      return new window['OSS']({
        region: 'oss-cn-beijing',
        accessKeyId: this.OSS_Params.accessKeyId,
        accessKeySecret: this.OSS_Params.accessKeySecret,
        stsToken: this.OSS_Params.securityToken,
        bucket: 'prdxaloss',
        secure: true
      });
    } else {
      // 测试环境
      return new window['OSS']({
        region: 'oss-cn-beijing',
        accessKeyId: this.OSS_Params.accessKeyId,
        accessKeySecret: this.OSS_Params.accessKeySecret,
        stsToken: this.OSS_Params.securityToken,
        bucket: 'testxaloss',
        secure: true
      });
    }
  }
  // 更新OSS参数
  getOSSParams() {
    this.OSS_Params.loading = true;
    let token = JSON.parse(window.localStorage.getItem('_token') || '{}').token || '';
    if (!token) return Promise.resolve(true);
    return this.http.get<any>(`${domain}/basics/v1/file/sts?access_token=${token}`).toPromise().then(
      // return this.http.get<any>(`/sts`).toPromise().then(
      (res) => {
        this.OSS_Params.loading = false;
        if (res.code == 0) {
          this.OSS_Params = res.data || {};
        }
      }, () => {
        this.OSS_Params.loading = false
      }
    );
  }
   // 更新OSS参数
   getOneOSSParams() {
    this.OSS_Params.loading = true;
    let token = JSON.parse(window.localStorage.getItem('_token') || '{}').token || '';
    // if (!token) return Promise.resolve(true);
    return this.http.get<any>(`${domain}/basics/v1/file/sts`).toPromise().then(
      // return this.http.get<any>(`/sts`).toPromise().then(
      (res) => {
        this.OSS_Params.loading = false;
        if (res.code == 0) {
          this.OSS_Params = res.data || {};
        }
      }, () => {
        this.OSS_Params.loading = false
      }
    );
  }
  //定时更新OSS参数, 仅在 \src\app\layout\default\default.component.ts 中调用
  setOSSInterval() {
    window['__zone_symbol__setInterval'](() => {
      //提前20分钟更新(1分钟检查一次)
      if (!this.OSS_Params.loading && (((+new Date(this.OSS_Params.expiration) - (+new Date))) < (20 * 60 * 1000))) {
        this.getOSSParams();
      }
    }, 1 * 60 * 1000);

  }
  //文件存储路径
  get path() {
    //公司id
    let tenant_id = JSON.parse(window.localStorage.getItem('_token') || '{}').tenant_id;
    return `${tenant_id}/${+new Date}_${this.selectRandom()}`;
  }
  //过期时间
  get expirationTime() {
    //20年(秒)
    // return 630720000;
    return 30 * 60;
  }
  //获取临时的 OSS URL 签名
  OssSignatureUrl(path = '') {
    if (this.isUrl(path)) return path;

    // if(path.includes('.')) {
    //   path = path.slice(0, path.indexOf('.'));
    // }
    return this.OSS.signatureUrl(path, { expires: this.expirationTime })
  }
  //获取带文件后缀名的OSS路径
  getFilePath(path, file) {
    // if(path.includes('.')) return path;
    // let name = file.name || '';
    // return path + name.slice(name.lastIndexOf('.'));
    return path;
  }
  getFileType(file) {
    let name = file.name || '';
    return name.slice(name.lastIndexOf('.'));
  }


  /**
   * 获取随机整数
   * @param lowValue 
   * @param highValue 
   */
  selectRandom(lowValue = 0, highValue = 1000) {
    let choice = highValue - lowValue + 1;
    return Math.floor(Math.random() * choice + lowValue);
  }

  /**
   * 是否是一个网址
   * @param url 
   */
  isUrl(url: string = '') {
    return !!(url.startsWith('http://') || url.startsWith('https://'));
  }
	// 月份
  monthList: any = [
    { label: '1月', value: '1' },
    { label: '2月', value: '2' },
    { label: '3月', value: '3' },
    { label: '4月', value: '4' },
    { label: '5月', value: '5' },
    { label: '6月', value: '6' },
    { label: '7月', value: '7' },
    { label: '8月', value: '8' },
    { label: '9月', value: '9' },
    { label: '10月', value: '10' },
    { label: '11月', value: '11' },
    { label: '12月', value: '12' },
  ];  
	// 应急预案类型
  planType: any = [
    // { label: '全部', value: '全部' },
    { label: '综合应急预案', value: '综合应急预案' },
    { label: '专项应急预案', value: '专项应急预案' },
    { label: '现场处置方案', value: '现场处置方案' },
    { label: '其他', value: '其他' }
  ];
   // 应急预案类型
   drillType: any = [
    { label: '检验性演练', value: '检验性演练' ,field:'inspectCount'},
    { label: '示范性演练', value: '示范性演练' ,field:'demoCount'},
    { label: '研究性演练', value: '研究性演练' ,field:'researchCount'},
    { label: '桌面演练', value: '桌面演练' ,field:'desktopCount'},
    { label: '实战演练', value: '实战演练' ,field:'realCount'},
    { label: '单项演练', value: '单项演练' ,field:'singleCount'},
    { label: '综合演练', value: '综合演练' ,field:'compositeCount'},
    { label: '单项桌面演练', value: '单项桌面演练' ,field:'singleDesktopCount'},
    { label: '综合桌面演练', value: '综合桌面演练' ,field:'compositeDesktopCount'},
    { label: '单项实战演练', value: '单项实战演练' ,field:'singleRealCount'},
    { label: '综合实战演练', value: '综合实战演练' ,field:'compositeRealCount'},
    { label: '单项示范演练', value: '单项示范演练' ,field:'singleDemoCount'},
    { label: '综合示范演练', value: '综合示范演练' ,field:'compositeDemoCount'}
  ];
  // 应急预案类型下拉不要全部
  planTypes: any = [
    { label: '综合应急预案', value: '综合应急预案' },
    { label: '专项应急预案', value: '专项应急预案' },
    { label: '现场处置方案', value: '现场处置方案' },
    { label: '其他', value: '其他' }
  ];  
  // 单位
  unit: any = [
    { label: '个', value: '个' },
    { label: '具', value: '具' },
    { label: '套', value: '套' },
    { label: 'kg', value: 'kg' },
    { label: 'g', value: 'g' },
    { label: '吨', value: '吨' },
    { label: '把', value: '把' },
    { label: '袋', value: '袋' },
    { label: '台', value: '台' },
    { label: '辆', value: '辆' }
  ];
  // 应急任务分工小组
  taskGroup: any = [
    { label: '指挥组', value: '指挥组' },
    { label: '应急办公室', value: '应急办公室' },
    { label: '抢险组', value: '抢险组' },
    { label: '灭火组', value: '灭火组' },
    { label: '通信组', value: '通信组' },
    { label: '保障组', value: '保障组' },
    { label: '疏导组', value: '疏导组' },
    { label: '警戒组', value: '警戒组' },
    { label: '后勤保障组', value: '后勤保障组' },
    { label: '自定义', value: '自定义' }
  ];  
  // 危化类别
  typeData = [
    { label: '含爆炸品废物', value: '含爆炸品废物' },
    { label: '含易燃液体废物', value: '含易燃液体废物' },
    { label: '含易燃固体废物', value: '含易燃固体废物' },
    { label: '含氧化剂和有机过氧化物废物', value: '含氧化剂和有机过氧化物废物' },
    { label: '含放射性废物', value: '含放射性废物' },
    { label: '含剧毒化学品废物', value: '含剧毒化学品废物' },
    { label: '含重金属废物', value: '含重金属废物' },
    { label: '含酸碱类腐蚀性废物', value: '含酸碱类腐蚀性废物' },
    { label: '生化制剂类废物', value: '生化制剂类废物' },
    { label: '废弃用品', value: '废弃用品' },
    { label: '废弃器皿', value: '废弃器皿' },
  ];
  // 危化单位
  unitData = [
    { label: '毫克', value: '毫克' },
    { label: '克', value: '克' },
    { label: '千克', value: '千克' },
    { label: '吨', value: '吨' }
  ];
  //一年第一天
  getYearFirstDay() {
    const firstDay = new Date();
    firstDay.setDate(1);
    firstDay.setMonth(0);
    return format(firstDay,"YYYY-MM-DD");  
   }
   //一年最后一天
   getYearLastDay() {
    const lastDay = new Date();
    lastDay.setFullYear(lastDay.getFullYear()+1);
    lastDay.setDate(0);
    lastDay.setMonth(-1);
    return format(lastDay,"YYYY-MM-DD");
   }   // 应急队伍职务
  teamJobs: any = [
    { label: '总指挥', value: '总指挥' },
    { label: '副总指挥', value: '副总指挥' },
    { label: '成员', value: '成员' },
    { label: '抢险抢修组组长', value: '抢险抢修组组长' },
    { label: '安全警戒组组长', value: '安全警戒组组长' },
    { label: '抢救疏散组组长', value: '抢救疏散组组长' },
    { label: '医疗救护组组长', value: '医疗救护组组长' },
    { label: '物资供应组组长', value: '物资供应组组长' },
    { label: '其他', value: '其他' }
  ];
// 事故上报状态
   reportStatus: any = [
    { label: '全部', value: '' },
    { label: '待提交', value: '1' },
    { label: '审核中', value: '2' },
    { label: '审核驳回', value: '3' },
    { label: '审核通过', value: '4' }
  ];  
   // 事故审核状态
   reviewStatus: any = [
    { label: '待审核', value: '1' },
    { label: '审核通过', value: '2' },
    { label: '审核驳回', value: '3' }
  ];  
  // 事故调查处理状态
  investStatus: any = [
    { label: '全部', value: '' },
    { label: '待调查处理', value: '1' },
    { label: '已调查处理', value: '2' }
  ];  
  // 事故等级
  accidentLevel: any = [
    { label: '特别重大事故', value: 1 },
    { label: '重大事故', value: 2 },
    { label: '较大事故', value: 3 },
    { label: '一般事故', value: 4 }
  ];  
  // 事故类型
  accidentType: any = [
    { label: '物体打击', value: '物体打击' },
    { label: '车辆伤害', value: '车辆伤害' },
    { label: '机械伤害', value: '机械伤害' },
    { label: '起重伤害', value: '起重伤害' },
    { label: '触电', value: '触电' },
    { label: '淹溺', value: '淹溺' },
    { label: '火灾', value: '火灾' },
    { label: '高处坠落', value: '高处坠落' },
    { label: '冒顶片帮', value: '冒顶片帮' },
    { label: '放炮', value: '放炮' },
    { label: '火药爆炸', value: '火药爆炸' },
    { label: '瓦斯爆炸', value: '瓦斯爆炸' },
    { label: '锅炉爆炸', value: '锅炉爆炸' },
    { label: '容器爆炸', value: '容器爆炸' },
    { label: '其他爆炸', value: '其他爆炸' },
    { label: '中毒和窒息', value: '中毒和窒息' },
    { label: '其他伤害', value: '其他伤害' }
  ];  
   // 事故主要原因类型
   accidentReasonType: any = [
    { label: '人为因素', value: '人为因素' ,field:'accidentReason1'},
    { label: '设备和物料因素', value: '设备和物料因素',field:'accidentReason2' },
    { label: '环境因素', value: '环境因素',field:'accidentReason3' },
    { label: '管理因素', value: '管理因素',field:'accidentReason4' }
  ];  
  // 事故主要原因
  accidentReason1: any = [
    { label: '缺乏工作程序', value: '缺乏工作程序' ,checked: false},
    { label: '不知道正确的程序', value: '不知道正确的程序' },
    { label: '知道程序但未遵守', value: '知道程序但未遵守' },
    { label: '因心理或生理原因不能完成工作', value: '因心理或生理原因不能完成工作' },
    { label: '缺少个人防护装备', value: '缺少个人防护装备' },
    { label: '工作中任务难度大而不能实施', value: '工作中任务难度大而不能实施' },
    { label: '不知道需要个人防护装备', value: '不知道需要个人防护装备' },
    { label: '知道需要个人防护装备，但不会使用', value: '知道需要个人防护装备，但不会使用' },
    { label: '缺乏应急设施', value: '缺乏应急设施' },
    { label: '应急设施没有正确地发挥作用', value: '应急设施没有正确地发挥作用' },
    { label: '知道程不知道如何使用应急设施序但未遵守', value: '不知道如何使用应急设施' },
    { label: '其他', value: '其他' }
  ];  
  accidentReason2: any = [
    { label: '质量或条件有缺陷', value: '质量或条件有缺陷',checked: false },
    { label: '设计缺陷', value: '设计缺陷' },
    { label: '未识别出危害', value: '未识别出危害' },
    { label: '危害性条件未报告', value: '危害性条件未报告' },
    { label: '缺乏设备检查', value: '缺乏设备检查' },
    { label: '危害性条件识别了、报告了，但未及时查明原因', value: '危害性条件识别了、报告了，但未及时查明原因' },
    { label: '检查未能检测出危害', value: '检查未能检测出危害' },
    { label: '使用不适当的工具或物料', value: '使用不适当的工具或物料' },
    { label: '没有适当的设备、工具或物', value: '没有适当的设备、工具或物' },
    { label: '设计时未考虑操作因素', value: '设计时未考虑操作因素' },
    { label: '其他', value: '其他' }
  ];  
  accidentReason3: any = [
    { label: '设备或员工的位置缺陷', value: '设备或员工的位置缺陷' ,checked: false},
    { label: '危险区域', value: '危险区域' },
    { label: '工作空间不够', value: '工作空间不够' },
    { label: '照明/噪音/空气污染/极端温度/通风/振动/辐射/地面和工作面', value: '照明/噪音/空气污染/极端温度/通风/振动/辐射/地面和工作面' },
    { label: '超负荷人手搬运或搬运程序错误', value: '超负荷人手搬运或搬运程序错误' },
    { label: '工作中任务难度大而不能实施', value: '工作中任务难度大而不能实施' },
    { label: '其他', value: '其他' }
  ];  
  accidentReason4: any = [
    { label: '监督人未能发现、阻止或报告危险条件', value: '监督人未能发现、阻止或报告危险条件' ,checked: false},
    { label: '监督人未能发现、阻止或报告违章的现象', value: '监督人未能发现、阻止或报告违章的现象' },
    { label: '管理人员没有将存在的或可能的危险通知员工', value: '管理人员没有将存在的或可能的危险通知员工' },
    { label: '管理人员没有使员工和监督人对安全负起责任', value: '管理人员没有使员工和监督人对安全负起责任' },
    { label: '管理人员没有对已知的危险状态采取', value: '管理人员没有对已知的危险状态采取' },
    { label: '缺乏对监督人的培训', value: '缺乏对监督人的培训' },
    { label: '其他', value: '其他' }
  ];  
  checkJobs:any=[
    { label: '组长', value: '组长' },
    { label: '副组长', value: '副组长' },
    { label: '成员', value: '成员' }
  ];
 /**
   * 是否是一个文件类型
   * @param word 
   */
   isVIPType(type: string){
     if(['3','4','5','6'].includes(type)){
       return true;
     }else{
       return false;
     }
   }
    // 是否是视频 
  isVidio(data: string){
      const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
      if (videolist.includes(data)) {
        return true;
      }else{
        return false;
      }
  }

  // 播放视频
  openVideo(src:string) {
    const page = window.open();
    const html="<body style='background:black'><div style='width: 82%;height: 82%;position: relative;margin:100px auto;'><video style='height: 100%;width:100%;margin: 0 5% 0 5%;' muted controls autoplay src='"+src+ "'></video> </div></body>"
    page.document.write(html); 
  }
  // 行业
  hyly = ["农业", "林业", "畜牧业", "渔业", "农、林、牧、渔服务业", "煤炭开采和洗选业",
  "石油和天然气开采业", "黑色金属矿采选业", "有色金属矿采选业", "非金属矿采选业", "开采辅助活动", "其他采矿业", "农副食品加工业",
  "食品制造业", "酒、饮料和精制茶制造业", "烟草制品业", "纺织业", "纺织服装、服饰业", "皮革、毛皮、羽毛及其制品和制鞋业",
  "木材加工和木、竹、藤、棕、草制品业", "家具制造业", "造纸和纸制品业", "印刷和记录媒介复制业", "文教、工美、体育和娱乐用品制造业",
  "石油加工、炼焦和核燃料加工业", "化学原料和化学制品制造业", "医药制造业", "化学纤维制造业", "橡胶和塑料制品业", "非金属矿物制品业",
  "黑色金属冶炼和压延加工业", "有色金属冶炼和压延加工业", "金属制品业", "通用设备制造业", "专用设备制造业", "汽车制造业",
  "铁路、船舶、航空航天和其他运输设备制造业", "电气机械和器材制造业", "计算机、通信和其他电子设备制造业", "仪器仪表制造业",
  "其他制造业", "废弃资源综合利用业", "金属制品、机械和设备修理业", "电力、热力生产和供应业", "燃气生产和供应业", "水的生产和供应业",
  "房屋建筑业", "土木工程建筑业", "建筑安装业", "建筑装饰和其他建筑业", "批发业", "零售业", "铁路运输业", "道路运输业", "水上运输业", "航空运输业",
  "管道运输业", "装卸搬运和运输代理业", "仓储业", "邮政业", "住宿业", "餐饮业", "电信、广播电视和卫星传输服务", "互联网和相关服务", "软件和信息技术服务业",
  "货币金融服务", "资本市场服务", "保险业", "其他金融业", "房地产业", "租赁业", "商务服务业", "研究和试验发展", "专业技术服务业", "科技推广和应用服务业",
  "水利管理业", "生态保护和环境治理业", "公共设施管理业", "居民服务业", "机动车、电子产品和日用产品修理业", "其他服务业", "教育", "卫生", "社会工作",
  "新闻和出版业", "广播、电视、电影和影视录音制作业", "文化艺术业", "体育",
  "娱乐业", "中国共产党机关", "国家机构", "人民政协、民主党派", "社会保障", "群众团体、社会团体和其他成员组织",
  "基层群众自治组织", "国际组织"]

  /**
   * 触发表单验证
   * @param form
   */
  formValid(form: FormGroup) {
    Object.values(form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });
  }
  /**
   * 获取地址列表
   * @param type
   * @param parentCode
   */
  getAddress(type = 'province', parentCode = '0') {
    if (parentCode == null) {
      return of([]);
    }

    const params = {
      name: type,
      parentCode,
    };
    return this.http.get<any>(`${domain}/basics/v1/districts/data`, { params, headers: { token: '' } }).pipe(
      map(res => {
        if (res.code == 0) {
          return res.data || [];
        } else {
          return [];
        }
      }),
    );
  }

}
