import { takeWhile } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { CacheService } from '@delon/cache';

 @Component({
  selector: 'module-name',
  templateUrl: './module-name.component.html',
  styleUrls: ['./module-name.component.less']
})
export class ModuleNameComponent implements OnInit {


  module: any = {};

  private isLive = true;

  constructor(private router: Router, public srv: CacheService) {
    this.router.events
    .pipe(takeWhile(() => this.isLive))
    .subscribe(e => {
      if (e instanceof ActivationEnd) {
        const url = this.router.url;
        const routePath = url.slice(1, url.indexOf('/', 1));
        const moduleListMenu = this.srv.getNone<any>('moduleListMenu') || [];

        this.module = moduleListMenu.find(item => item.routePath === routePath) || {};
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() { 
    this.isLive = false;
  }
}
