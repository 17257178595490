import { Component, OnInit } from '@angular/core'; import { MessageService } from '@core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzCollapseModule } from 'ng-zorro-antd';

import { riskUrl } from '@core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-riskcard-content',
  templateUrl: './risk-point.component.html',
  styleUrls: ['./risk-point.component.less']
})
export class RiskPointComponent implements OnInit {
  params: any = {};
  basics: any = {};
  riskPointList: any = [];
  datausccess=true;

  collDoms:any = [];
  checkList1= [];

  totalPage = null;
  page = 1;
  pageSize = 30;
  showEnd = false;

  subscribeScoll:any;
  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: _HttpClient,
    private modal: ModalHelper) { }

  async ngOnInit() {
    this.params = this.route.snapshot.params;
    await this.getData();

    this.collDoms = document.getElementsByClassName("collapseset");
    this.subscribeScoll = fromEvent(window, 'scroll')
      .subscribe((event) => {
        this.scollPostion();
      });
  }
  checkIt(index,disable) {
    this.checkCol(index,disable);
  }
  checkCol(index,disable) {
    if(disable) {
      return;
    }
    let checkFlags;
    checkFlags = this.checkList1;
    if(checkFlags[index].isactive) {
      this.collDoms[index].getElementsByClassName("collapse")[0].setAttribute("class","collapse");
    } else {
      this.collDoms[index].getElementsByClassName("collapse")[0].setAttribute("class","collapse active");
    }
    checkFlags[index].isactive = !checkFlags[index].isactive;
  }
  /**
   * 获取风险点
   */
  getData() {
    return this.http.get(`${riskUrl}/risk/equipments/regionalRiskPointList?limit=${this.pageSize}&page=${this.page}&geographyPositionCode=${this.params.geoPositionCode}&companyId=${this.params.companyId}`).toPromise().then((res:any) => {
      if (res.code === 0) {
        this.datausccess=true;
        let newList = res.data.content;
        newList.forEach(riskPoint => {
          const checkItems = riskPoint.checkItems;
          riskPoint.typeName = '';
          riskPoint.objId = '';
          riskPoint.planId = '';
          riskPoint.type = '';
          if (checkItems.length > 0) {
            let evaluateType = checkItems[0].evaluateType;
            riskPoint.evaluateType = evaluateType;
            riskPoint.type = evaluateType;
            if (evaluateType == 1) {
              riskPoint.typeName = '设备设施';
            } else if(evaluateType == 2) {
              riskPoint.typeName = '作业活动';
            } else if(evaluateType == 3) {
              riskPoint.typeName = '工艺节点';
            } else if(evaluateType == 4) {
              riskPoint.typeName = '作业场所';
            }
            riskPoint.objId =  checkItems[0].evaluateObjectId;
            riskPoint.planId =  checkItems[0].evaluatePlanId;
          }
          this.riskPointList.push(riskPoint);
          this.checkList1.push({isactive: false});
        });
        this.totalPage = res.data.totalPages;
      } else {
        this.datausccess=false;
        this.msg.error(res.msg);
      }
    })
  }
  ngAfterViewInit() {
    // 监听路由变化
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/risk-point')) {
          this.getData();
        }
      }
    });
  }

  toCard(riskPoint) {
    this.router.navigateByUrl(`risk-card/${this.params.companyId}/${riskPoint.id}/${riskPoint.evaluateType}`);
  }

  scollPostion() {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    if(scrollTop + clientHeight + 80 > scrollHeight) {
      if (this.page < this.totalPage) {
        this.page++;
        this.getData();
      } else {
        this.showEnd = true;
      }
    }
  }
}
