import { NzIconService } from 'ng-zorro-antd';
import { zip } from 'rxjs';

import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { Router } from '@angular/router';
import { ALAIN_I18N_TOKEN, MenuService, SettingsService, TitleService } from '@delon/theme';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

import { ICONS } from '../../../style-icons';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { CommonService } from '../common.service';
import { baseUrl , AliyunOssUrl} from '../constant';
import { I18NService } from '../i18n/i18n.service';
import { ReuseTabService } from '@delon/abc/reuse-tab';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    public srv: CacheService,
    private injector: Injector,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    // private router: Router,
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }
  getUrlParameter(name) {
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? undefined : decodeURIComponent(results[1]);
  }
  private getUrlparam(param) {
    const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
    const r = window.location.search.substr(1).match(reg) || window.location.hash.substring((window.location.hash.search(/\?/)) + 1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
  };
  load() {
    if(environment.production && window.location.protocol==='http:'){
      let url = window.location.href;
      url=url.replace('http','https');
      window.location.replace(url)
    }
    
    const appRedirectToken = this.getUrlParameter(
      (environment.appRedirectConfig || ({} as any)).appRedirectTokenParamName,
    );

    if (appRedirectToken) {
      this.tokenService.set({ token: appRedirectToken });
    }
    // 优先从cookies读取token 
    const superviseToken = document.cookie.replace(/ /g, '').split(';').find(item => item.includes('token')) ? document.cookie.replace(/ /g, '').split(';').find(item => item.includes('token')).split('=')[1] : '';

    if (superviseToken) {
    
      const tenant_id = document.cookie.replace(/ /g, '').split(';').find(item => item.includes('tenant_id')) ? document.cookie.replace(/ /g, '').split(';').find(item => item.includes('tenant_id')).split('=')[1] : '';
 
      if(tenant_id){
        const res = {
          token:superviseToken,
          tenant_id:tenant_id
        };
        this.tokenService.set(res);
      }else{
        this.tokenService.set({ token: superviseToken });
      }
    }
 
    const authToken = {
      access_token: this.tokenService.get().token,
      tenant_id:this.tokenService.get().tenant_id,
      status: 1 // 正常流程登录
    };
    
    const __sid = this.getUrlparam('__sid');
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic eXVrb246eXVrb24')
      .set('token', '');
    if (__sid) {
     return  this.httpClient.post(`${baseUrl}/auth/mobile/token/sms?_allow_anonymous=true&code=${__sid}&type=sid,pc`, {}, { headers }).toPromise().then((res: any) => {
        // 设置用户Token信息
        res.token = res.access_token;
        this.tokenService.set(res);
        return this.checkToken(res).then(() => {
          this.injector.get(Router).navigate(['/']);
          return Promise.resolve(null)
        });
      })
    }else{
     return this.checkToken(authToken).then(() => {
      // this.injector.get(Router).navigate(['/']);
       return Promise.resolve(null)
      });
    }
   
    
  }
  checkToken(resdata): Promise<any> {
    var $taht = this;
    return new Promise(resolve => {
      zip(
        this.httpClient.get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`),
        this.httpClient.get('assets/tmp/app-data.json'),
        this.httpClient.get<any>(
          `${baseUrl}/auth/oauth/check_token?token=${resdata.access_token || ''}`,
        ),
        this.httpClient.get<any>(
          `${baseUrl}/admin/v1/menus/mymenu?productType=2&token=${resdata.access_token || ''}`,
        ),
        // this.injector.get(CommonService).getOSSParams(),
        // this.httpClient.get<any>(`${AliyunOssUrl}/public/${resdata.tenant_id}/config.json`),
        // resdata.tenant_id && this.httpClient.get<any>(
        //   `${baseUrl}/platform/v1/sysAudit/status/${resdata.tenant_id}`,
        // ),
        // this.injector.get(CommonService).getOSSParams(),
      ).subscribe(
        ([langData, appData, authData, menuData]) => {
          if (resdata.status !== 1) {
            // 清空路由复用信息
            this.reuseTabService.clear();
            // 设置用户Token信息
            resdata.token = resdata.access_token;
            this.tokenService.set(resdata);
          }
          // setting language data
          this.translate.setTranslation(this.i18n.defaultLang, langData);
          this.translate.setDefaultLang(this.i18n.defaultLang);
          // 保存用户模块
          this.srv.set('applications', Object.keys((menuData || {}).data || {}));
          // application data
          const res: any = appData;
          // 应用信息：包括站点名、描述、年份
          this.settingService.setApp(res.app);
          // 用户信息：包括姓名、头像、邮箱地址
          this.settingService.setUser({
            name: authData.real_name,
            avatar: authData.avatar,
            mobile: authData.mobile,
            company_name: authData.company_name,
            company_id: authData.tenant_id,
          });
          // 存储登录信息
          this.srv.set('loginData', authData);
          // 存储公司信息
          // if(companyData.code != 100){
          //   this.srv.set('companyData', companyData);
          // }else{
          this.srv.set('companyData', {companyAlias:'企业' });
          // }
          
          //存储企业的审核状态
         this.getAduitStatus(resdata.tenant_id);
          // if(auditData.code == 0){
          //   auditData.data && this.srv.set('auditStatus', auditData.data.auditStatus);
          // }
          
          /**
           * 权限
           */
          (() => {
            // this.aclService.setFull(true); //设置为全量用户
            if (!authData.code) {
              this.aclService.setRole(authData.authorities || []);
            } else {
              this.aclService.setRole([]);
            }
          })();

          /**
           * 菜单
           */
          (() => {
            //风险管控
            let riskControlMenu = [];
            //隐患排查
            let dangerCheckMenu = [];
            //应急预测
            let ledgerMenu = [];
            //系统设置
            let systemMenu = [];
            //消息
            let messageMenu = [];
            //教育培训
            let trainMenu = [];
            //台账
            let booksMenu = [];
            //服务中心
            let serviceMenu = [];
            //作业票
            let jobTicketMenu = [];
            let helpMenu = [];
            //预警预测
            let warningMenu = [];
            //事故事件
            let accidentMenu = [];
            //奖惩模块
            let rewardMenu = [];
            // 疫情上报菜单
            let reportMenu = [];
            // 设备设施
            let facilityMenu = [];
            // 危化品管理菜单
            let chemicalMenu = [];
            // 视频监控
            let monitorMenu = [];
            // 视频监控
            let majorHazardMenu = [];

            // 任务管理
            let taskMenu = [];

            //安全咨询服务
            //  let serviceMenu = [];
            // //测试模块
            // let testMenu = [];
            // //ng-alain 示例模块
            // let ngAlainExampleMenu = [];

            //是否使用服务器提供的菜单
            let useServerMenu = true;
            //xjz 开发模式
            this.srv.set('useServerMenu', useServerMenu);
            this.srv.set('moduleListMenu', []);
            if (useServerMenu) {
              /**
               * 使用服务器提供的菜单
               */
              if (menuData.code != 0) return;

              //格式化
              const recursion = (arr = []) => {
                for (let item of arr || []) {
                  item.text = item.name || item.label;
                  item.link = item.path;
                  item.icon = item.icon || 'anticon anticon-file';
                  if (Array.isArray(item.children) && item.children.length) {
                    // //如果父菜单没有配置link, 则使用第一个子菜单的link
                    !item.link && (item.link = item.children[0].path);
                    recursion(item.children);
                  }
                }
                return arr || [];
              };

              let rootMenu = {
                text: '',
                group: false,
                hideInBreadcrumb: true,
                children: [],
              };

              riskControlMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.risk || {}).menus || []),
                },
              ];
              dangerCheckMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.hazard || {}).menus || []),
                },
              ];
              systemMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.sys || {}).menus || []),
                },
              ];
              messageMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.info || {}).menus || []),
                },
              ];
              ledgerMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.ledger || {}).menus || [])
                }
              ];
              trainMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.train || {}).menus || []),
                },
              ];
              booksMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.books || {}).menus || []),
                },
              ];
              serviceMenu = [
                {
                  ...rootMenu,
                  children: recursion(
                    (menuData.data.service || {}).menus || [],
                  ),
                },
              ];
              jobTicketMenu = [
                {
                  ...rootMenu,
                  children: recursion(
                    (menuData.data.jobTicket || {}).menus || [],
                  ),
                },
              ];
              accidentMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.accident || {}).menus || [])
                }
              ];
              helpMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.help || {}).menus || []),
                },
              ];
              warningMenu = [
                {
                  ...rootMenu,
                  children: recursion(
                    (menuData.data.warning || {}).menus || [],
                  ),
                },
              ];
              rewardMenu = [
                {
                  ...rootMenu,
                  children: recursion(
                    (menuData.data.reward || {}).menus || []
                  ),
                },
              ];
              reportMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.antiepidemic || {}).menus || []),
                },
              ];
              facilityMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.facility || {}).menus || []),
                },
              ];
              chemicalMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.chemical || {}).menus || []),
                },
              ];
              monitorMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.jksb || {}).menus || []),
                },
              ];
              majorHazardMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.major || {}).menus || []),
                },
              ];
              taskMenu = [
                {
                  ...rootMenu,
                  children: recursion((menuData.data.task || {}).menus || []),
                },
              ];
              
              //设置模块选择菜单

              this.srv.set('moduleListMenu', [
                {
                  name: (menuData.data.sys || {}).applicationName || '系统设置',
                  menus: systemMenu[0].children,
                  icon: 'icon-shezhimoren1',
                  routePath: 'system',
                },
                {
                  name:
                    (menuData.data.books || {}).applicationName || '台账管理',
                  menus: booksMenu[0].children,
                  icon: 'icon-taizhangguanli',
                  routePath: 'books',
                },
                {
                  name:
                    (menuData.data.risk || {}).applicationName || '风险管控',
                  menus: riskControlMenu[0].children,
                  icon: 'icon-fenjiguankongmoren1',
                  routePath: 'risk-control',
                },
                {
                  name:
                    (menuData.data.hazard || {}).applicationName || '隐患排查',
                  menus: dangerCheckMenu[0].children,
                  icon: 'icon-yinhuanpaichamoren1',
                  routePath: 'danger-check',
                },
                {
                  name:
                    (menuData.data.ledger || {}).applicationName || '应急预案',
                  menus: ledgerMenu[0].children,
                  icon: 'icon-yingjiziyuan',
                  routePath: 'ledger'
                },
                {
                  name:
                    (menuData.data.train || {}).applicationName || '教育培训',
                  menus: trainMenu[0].children,
                  icon: 'icon-jiaoyupeixun',
                  routePath: 'train',
                },
                {
                  name:
                    (menuData.data.jobTicket || {}).applicationName ||
                    '危险作业票',
                  menus: jobTicketMenu[0].children,
                  icon: 'icon-zuoyepiao',
                  routePath: 'ticket',
                },
                {
                  name: (menuData.data.accident || {}).applicationName || '事故管理',
                  menus: accidentMenu[0].children, icon: 'icon-fuwuzhongxin', routePath: 'accident'
                },
                {
                  name:
                    (menuData.data.info || {}).applicationName || '消息中心',
                  menus: messageMenu[0].children,
                  icon: 'icon-xiaoxizhongxinmoren1',
                  routePath: 'message',
                },
                {
                  name:
                    (menuData.data.help || {}).applicationName || '帮助中心',
                  menus: helpMenu[0].children,
                  icon: 'icon-bangzhuzhongxin',
                  routePath: 'help',
                },
                {
                  name:
                    (menuData.data.service || {}).applicationName || '服务中心',
                  menus: serviceMenu[0].children,
                  icon: 'icon-fuwuzhongxin',
                  routePath: 'service',
                },
                {
                  name:
                    (menuData.data.reward || {}).applicationName || '奖惩记录',
                  menus: rewardMenu[0].children,
                  icon: 'icon-jiangcheng-',
                  routePath: 'reward',
                },
                {
                  name:
                    (menuData.data.warning || {}).applicationName || '预警预测',
                  menus: warningMenu[0].children,
                  icon: 'icon-yujing-',
                  routePath: 'warning',
                },
                {
                  name:
                    (menuData.data.antiepidemic || {}).applicationName || '疫情管理',
                  menus: reportMenu[0].children,
                  icon: 'icon-yiqing-',
                  routePath: 'report-list',
                },
                {
                  name:
                    (menuData.data.facility || {}).applicationName || '设备设施',
                  menus: facilityMenu[0].children,
                  icon: 'icon-shebeisheshi',
                  routePath: 'facility',
                },
                {
                  name:
                    (menuData.data.chemical || {}).applicationName || '危化品管理',
                  menus: chemicalMenu[0].children,
                  icon: 'icon-chemical-weapon',
                  routePath: 'chemical',
                },
                {
                  name:
                    (menuData.data.jksb || {}).applicationName || '视频监控管理',
                  menus: monitorMenu[0].children,
                  icon: 'icon-webcam',
                  routePath: 'monitor',
                },
                {
                  name:
                    (menuData.data.major || {}).applicationName || '重大危险源辨识',
                  menus: majorHazardMenu[0].children,
                  icon: 'icon--zhongdaweixian',
                  routePath: 'major-hazard',
                },
                {
                  name:
                    (menuData.data.task || {}).applicationName || '任务管理',
                  menus: taskMenu[0].children,
                  icon: 'icon-jiangcheng-',
                  routePath: 'task',
                },
              ])
              this.srv.set('useServerMenu', useServerMenu);
            } else {
              /**
               * 使用本地菜单
               */
              riskControlMenu = res['risk-control.menu'];
              dangerCheckMenu = res['danger-check.menu'];
              ledgerMenu = res['ledger.menu'];
              systemMenu = res['system.menu'];
              messageMenu = res['message.menu'];
              trainMenu = res['train.menu'];
              booksMenu = res['books.menu'] || [];
              jobTicketMenu = res['jobTicket.menu'] || [];
              accidentMenu = res['accident.menu'] || [];
              helpMenu = res['help.menu'] || [];
              warningMenu = res['warning.menu'] || [];
              rewardMenu = res['reward.menu'] || [];
              reportMenu = res['report.menu'] || [];
              facilityMenu = res['facility.menu'] || [];
              chemicalMenu = res['chemical.menu'] || [];
              monitorMenu = res['monitor.menu'] || [];
              majorHazardMenu = res['major-hazard.menu'];
            }
            this.srv.set('risk-control.menu', riskControlMenu);
            this.srv.set('danger-check.menu', dangerCheckMenu);
            this.srv.set('ledger.menu', ledgerMenu);
            this.srv.set('system.menu', systemMenu);
            this.srv.set('message.menu', messageMenu);
            this.srv.set('train.menu', trainMenu);
            this.srv.set('books.menu', booksMenu);
            this.srv.set('jobTicket.menu', jobTicketMenu);
            this.srv.set('accident.menu', accidentMenu);
            this.srv.set('help.menu', helpMenu);
            this.srv.set('warning.menu', warningMenu);
            this.srv.set('reward.menu', rewardMenu);
            this.srv.set('report.menu', reportMenu);
            this.srv.set('facility.menu', facilityMenu);
            this.srv.set('chemical.menu', chemicalMenu);
            this.srv.set('monitor.menu', monitorMenu);
            this.srv.set('major-hazard.menu', majorHazardMenu); 
            this.srv.set('task.menu', taskMenu); 
          })();

          // 设置页面标题的后缀
          this.titleService.default = '';
          this.titleService.suffix = res.app.name;
          this.settingService.setLayout('collapsed', false);
        },
        () => { },
        () => {
          resolve(null);
        },
      );
    });
  }
  /**
   * 获取企业初始化状态
   */
  getAduitStatus(companyId){ 
    if(companyId){
      this.httpClient.get<any>(`${baseUrl}/platform/v1/sysAudit/status/${companyId}`).subscribe((res:any) => {
        if (res.code == 0) {
          // 存储审核状态信息
          res.data && this.srv.set('auditStatus', res.data.auditStatus);         
        }
      });
    }
   
  }
}
