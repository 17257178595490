import { Component, OnInit } from '@angular/core'; import { MessageService } from '@core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { forkJoin } from 'rxjs'
import { DomSanitizer } from '@angular/platform-browser';

import { _HttpClient } from '@delon/theme';
import { baseUrl, mockUrl, riskUrl, uploadUrl } from '@core';
import { DataService, CommonService, } from '@core';

import * as G2 from '@antv/g2';
import * as moment from 'moment';
import { CacheService } from '@delon/cache';

export interface getItemInterface{
  code:number,
  data:getItemDataInterface[],
  msg:string,
}
export interface getItemDataInterface{
  checkItem: string,
  checkStandard: string,
  nonStandardCase: string,
  nonStandardResult: string,
  activityWorkStep:string,
  activityPotentialEvent:string,
  activityHarmResult:string,
  craftName:string,
  craftParam:string,
  craftParamDesc:string,
  craftDeviation:string,
  craftPossibleReason:string,
  craftResult:string,
}

export interface SetResponse {
  code:number;
  data:{spiMonth:number,spiYear:number} | null;
  msg:string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  window = window;
  position_nodes = [];
  dep_nodes = [];
  height = '220px';
  listSpan = '12';
  listLine = '12';
  div1 = {display:'block'};
  div2 = {display:'block'};
  spiMonth = 0;
  spiYear = 0;
  smallDiv = {height:'315px'};
  /**
   * 查看对话框
   */
  viewImg = {
    display: false,
    data: '',
    loading:false
  };
  rows_see = [];  /**
   * 代办
   */
  awaitData: any = {
    loading: false,
  };

  /**
   * 公示
   */
  bbs = {
    loading: false,
    rows: []
  }

  /**
   * 隐患Tab
   */
  dangerTab = {
    //岗位
    // tab1: {
    //   searchParams: <any>{},
    //   chartOption: null,
    //   rows: [],
    //   loading: false,
    // },
    //责任单位
    tab2: {
      searchParams: <any>{},
      chartOption: null,
      rows: [],
      loading: false,
    },
    //隐患区域
    tab3: {
      searchParams: <any>{},
      chartOption: null,
      rows: [],
      loading: false,
    },
    //隐患类别
    tab4: {
      searchParams: <any>{},
      chartOption: null,
      rows: [],
      loading: false,
    },
    //月度隐患
    tab5: {
      searchParams: <any>{},
      chart: null,
      rows: [],
      loading: false,
      riskType: [
        { title: '重大隐患', field: 'greatCount' },
        { title: '一般隐患', field: 'generalCount' },
      ],
      months: [],
      resData: []
    },
    //年度隐患
    tab6: {
      searchParams: <any>{},
      chart: null,
      rows: [],
      loading: false,
      riskType: [
        { title: '重大隐患', field: 'greatCount' },
        { title: '一般隐患', field: 'generalCount' },
      ],
      months: [],
      resData: []
    }
  };

  /**
   * 风险tab
   */
  riskTab = {
    //按区域统计
    area: {
      searchParams: <any>{},
      chartOption: null,
      loading: false,
    },
    //按管辖部门统计
    dep: {
      searchParams: <any>{},
      chartOption: null,
      loading: false,
    },
    //对象类型统计
    riskType: {
      searchParams: <any>{
        type: 1
      },
      chart: null,
      loading: false,
    },
    //风险变化趋势
    riskTrend: {
      searchParams: <any>{},
      chartOption: null,
      loading: false,
    }
  };
  /**
   * 预警Tab
   */
  warningTab = {
     //企业综合风险
     tab1: {
      searchParams: <any>{},
      chartOption: null,
      data: {
        degree:''
      },
      loading: false,
    },
    //企业综合风险变化趋势
    tab2: {
      searchParams: <any>{},
      chartOption: null,
      rows: [],
      loading: false,
    }
  }
  // 隐患查看对话框
  dangerViewDialog = {
    loading: false,
    display: false,
    data: <any>{},
  }

  //风险查看对话框
  riskViewDialog = {
    loading: false,
    display: false,
    data: <any>{
      checkItem: <any>{}
    },
    evaluateType: 1
  };
  companyData: any = this.srv.getNone('companyData') || {};
  constructor(public sanitizer: DomSanitizer, 
    public srv: CacheService,
    private message: MessageService, 
    private http: _HttpClient,
     private dataService: DataService, 
     private commonService: CommonService, 
     public router: Router, 
     public route: ActivatedRoute) {
    //加载 区域/位置 tree 数据
    this.dataService.loadPositionTreeData().subscribe(res => {
      if (res.code == 0) {
        this.position_nodes = res.data || [];
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });

    //加载 管辖部门/单位 tree 数据
    this.dataService.loadDepTreeData().subscribe(res => {
      if (res.code == 0) {
        this.dep_nodes = res.data || [];
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }


  ngOnInit() {
    if(this.srv.getNone('auditStatus') != '1'){
      this.router.navigateByUrl('/rapid-deployment-account');
      return;
    }
    this.loadView();
    this.loadAwaitRows();
    this.loadbbs();

    this.loadTab2();
  }

  /**
   * 是否加载页面
   * @param reset 
   */
  loadView() {
    this.http.get<SetResponse>(`${baseUrl}/hazard/v1/display/setup/home`).subscribe(res => {
      if (res.code === 0) {
          if(res.data.spiMonth===0 && res.data.spiYear===0){
            this.loadWarningTab1();
            this.loadWarningTab2();
            this.div2 = {display:'block'};
            this.div1 = {display:'block'};
            this.listSpan = '12';
          } else if(res.data.spiMonth===0 && res.data.spiYear === 1){
            this.loadWarningTab1();
            this.div2 = {display:'none'};
            this.div1 = {display:'block'};
            this.listSpan = '24';
          } else if(res.data.spiMonth===1 && res.data.spiYear === 0){
            this.loadWarningTab2();
            this.div2 = {display:'block'};
            this.div1 = {display:'none'};
            this.listSpan = '24';
          } else {
            this.div2 = {display:'none'};
            this.div1 = {display:'none'};
            this.listSpan = '12';
            this.listLine = '24';
          }


      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载代办
   */
  loadAwaitRows() {
    this.awaitData.loading = true;
    this.http.get<any>(`${baseUrl}/cooperation/v1/schedules/myschedule`, {type: ''}).subscribe(res => {
      this.awaitData.loading = false;
      if (res.code == 0) {
        this.awaitData = res.data || {};
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载 重大风险公示
   */
  loadbbs() {
    //风险
    let _risk = this.http.get(`${riskUrl}/risk/home/major/notification`);
    //隐患
    let _danger = this.http.get(`${riskUrl}/statistics/publicity`);
    this.bbs.loading = true;
    forkJoin<any>(_risk, _danger).subscribe(([riskRes, dangerRes]) => {
      this.bbs.loading = false;
      if (riskRes.code == 0 && dangerRes.code == 0) {
        dangerRes.data.forEach(item=>{
          if(item.potentialPhotographs && item.potentialPhotographs.length>0){
            item.potentialPhotographs[0].urlPath= this.commonService.OssSignatureUrl(item.potentialPhotographs[0].urlPath);
          }       
        })
        this.bbs.rows = [...(riskRes.data || []).map(v => ({ ...v, _type: 'risk' })), ...(dangerRes.data || []).map(v => ({ ...v, _type: 'danger' }))]
      } else {
        this.message.error('重大风险公示加载失败');
      }
    });
  }

  nzSelectedIndexChange(index) {
    if (index == 1) {
      if (this.riskTab.area.chartOption == null) {
        this.loadArea();
        this.loadDep();
        this.loadRiskType();
        this.loadriskTrend();
      }
    }
  };

  nzDangerTabSelectedIndexChange(index) {
    this['loadTab' + (index + 2)]();
  }

  /**
   * 加载区域图表
   */
  loadArea() {
    let params = {
      ...this.riskTab.area.searchParams
    };

    params.startDay && (params.startDay = moment(params.startDay).format('YYYY-MM-DD'));
    params.endDay && (params.endDay = moment(params.endDay).format('YYYY-MM-DD'));

    Object.entries(params).forEach(([key, value]) => { value === null && delete params[key]; });

    (params.locationId != null) && (params.locationCode = this.commonService.getNodeByKey(this.position_nodes, params.locationId).code);

    this.riskTab.area.loading = true;
    this.http.get<any>(`${riskUrl}/risk/statistics/location`, params).subscribe(res => {
      this.riskTab.area.loading = false;
      if (res.code == 0) {
        /**
         * chart
         */
        (() => {
          this.riskTab.area.chartOption = {
            grid: {
              left: 30,
              right: 30,
              containLabel: true,
              bottom: 45,
              top: 45,
            },
            legend: {},
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            xAxis: {
              type: 'category',
              data: []
            },
            yAxis: {
              type: 'value',
              name: '风险量',
              minInterval: 1
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: 0,
              },
              {
                type: 'inside',
                xAxisIndex: 0,
              },
            ],
            series: [],
            color: Object.values(this.commonService.color)
          };

          (res.data.data || []).forEach(item => {
            this.riskTab.area.chartOption.xAxis.data.push(item.groupName);
          });

          let typeList = [
            { title: '重大风险', field: 'greatRiskCount' },
            { title: '较大风险', field: 'relativeRiskCount' },
            { title: '一般风险', field: 'generalRiskCount' },
            { title: '低风险', field: 'lowRiskCount' },
          ];

          this.riskTab.area.chartOption.series = typeList.map(type => ({
            name: type.title,
            data: (res.data.data || []).map(v => v[type.field]),
            type: 'bar',
            barMaxWidth: '50',
            label: {
              normal: {
                show: true,
                position: 'top'
              }
            }
          }));
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载部门图表
   */
  loadDep() {
    this.riskTab.dep.loading = true;
    let params = {
      ...this.riskTab.dep.searchParams
    };

    params.startDay && (params.startDay = moment(params.startDay).format('YYYY-MM-DD'));
    params.endDay && (params.endDay = moment(params.endDay).format('YYYY-MM-DD'));

    Object.entries(params).forEach(([key, value]) => { value === null && delete params[key]; });

    (params.departmentId != null) && (params.departmentCode = this.commonService.getNodeByKey(this.dep_nodes, params.departmentId).code);

    this.http.get<any>(`${riskUrl}/risk/statistics/department`, params).subscribe(res => {
      this.riskTab.dep.loading = false;
      if (res.code == 0) {
        /**
         * chart
         */
        (() => {
          this.riskTab.dep.chartOption = {
            grid: {
              left: 30,
              right: 30,
              containLabel: true,
              bottom: 45,
              top: 45,
            },
            legend: {},
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            xAxis: {
              type: 'category',
              data: []
            },
            yAxis: {
              type: 'value',
              name: '风险量',
              minInterval: 1
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: 0,
              },
              {
                type: 'inside',
                xAxisIndex: 0,
              },
            ],
            series: [],
            color: Object.values(this.commonService.color)
          };

          (res.data.data || []).forEach(item => {
            this.riskTab.dep.chartOption.xAxis.data.push(item.groupName);
          });

          let typeList = [
            { title: '重大风险', field: 'greatRiskCount' },
            { title: '较大风险', field: 'relativeRiskCount' },
            { title: '一般风险', field: 'generalRiskCount' },
            { title: '低风险', field: 'lowRiskCount' },
          ];

          this.riskTab.dep.chartOption.series = typeList.map(type => ({
            name: type.title,
            data: (res.data.data || []).map(v => v[type.field]),
            type: 'bar',
            barMaxWidth: '50',
            label: {
              normal: {
                show: true,
                position: 'top'
              }
            }
          }));
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载对象类型图表
   */
  loadRiskType() {
    this.riskTab.riskType.loading = true;
    let params = {
      ...this.riskTab.riskType.searchParams,
    };

    params.startDay && (params.startDay = moment(params.startDay).format('YYYY-MM-DD'));
    params.endDay && (params.endDay = moment(params.endDay).format('YYYY-MM-DD'));

    Object.entries(params).forEach(([key, value]) => { value === null && delete params[key]; });

    (params.departmentId != null) && (params.departmentCode = this.commonService.getNodeByKey(this.dep_nodes, params.departmentId).code);
    (params.locationId != null) && (params.locationCode = this.commonService.getNodeByKey(this.position_nodes, params.locationId).code);

    this.http.get<any>(`${riskUrl}/risk/statistics/object`, params).subscribe(res => {
      this.riskTab.riskType.loading = false;
      if (res.code == 0) {
        // var data1 = [{
        //   item: '事例一',
        //   count: 40,
        //   percent: 0.4
        // }, {
        //   item: '事例二',
        //   count: 21,
        //   percent: 0.21
        // }, {
        //   item: '事例三',
        //   count: 17,
        //   percent: 0.17
        // }, {
        //   item: '事例四',
        //   count: 13,
        //   percent: 0.13
        // }, {
        //   item: '事例五',
        //   count: 9,
        //   percent: 0.09
        // }];
        var data1 = res.data.data.map(v => ({ ...v, item: v.typeName, percent: parseInt(v.percent) / 100 }));
        this.riskTab.riskType.chart && this.riskTab.riskType.chart.destroy();
        var chart = this.riskTab.riskType.chart = new G2.Chart({
          container: 'risk_statisics_riskType_chart',
          forceFit: true,
          height: 400
        });
        chart.source(data1, {
          percent: {
            formatter: function formatter(val) {
              val = (val * 100).toFixed(0) + '%';
              return val;
            }
          }
        });
        chart.coord('theta');
        chart.tooltip({
          showTitle: false
        });
        chart.intervalStack().position('percent').color('item').label('percent', {
          offset: -40,
          autoRotate: false,
          textStyle: {
            textAlign: 'center',
            shadowBlur: 2,
            shadowColor: 'rgba(0, 0, 0, .45)'
          }
        }).tooltip('item*percent', function (item, percent) {
          percent = (percent * 100).toFixed(0) + '%';
          return {
            name: item,
            value: percent
          };
        }).style({
          lineWidth: 1,
          stroke: '#fff'
        });
        chart.render();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载变化趋势图表
   */
  loadriskTrend() {
    this.riskTab.riskTrend.loading = true;
    let params = {
      ...this.riskTab.riskTrend.searchParams
    };

    params.startDay && (params.startDay = moment(params.startDay).format('YYYY-MM-DD'));
    params.endDay && (params.endDay = moment(params.endDay).format('YYYY-MM-DD'));

    Object.entries(params).forEach(([key, value]) => { value === null && delete params[key]; });

    (params.departmentId != null) && (params.departmentCode = this.commonService.getNodeByKey(this.dep_nodes, params.departmentId).code);
    (params.locationId != null) && (params.locationCode = this.commonService.getNodeByKey(this.position_nodes, params.locationId).code);

    this.http.get<any>(`${riskUrl}/risk/statistics/trend`, params).subscribe(res => {
      this.riskTab.riskTrend.loading = false;
      if (res.code == 0) {

        /**
         * chart
         */
        (() => {
          this.riskTab.riskTrend.chartOption = {
            grid: {
              left: 30,
              right: 50,
              containLabel: true,
              bottom: 45,
              top: 30,
            },
            legend: {},
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            xAxis: {
              type: 'category',
              data: [],
              name: '年份'
            },
            yAxis: {
              type: 'value',
              name: '风险量',
              minInterval: 1
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: 0,
              },
              {
                type: 'inside',
                xAxisIndex: 0,
              },
            ],
            series: [],
            color: Object.values(this.commonService.color)
          };


          (res.data || []).forEach(item => {
            this.riskTab.riskTrend.chartOption.xAxis.data.push(item.yearAndMonth);
          });

          let typeList = [
            { title: '重大风险', field: 'greatRiskCount' },
            { title: '较大风险', field: 'relativeRiskCount' },
            { title: '一般风险', field: 'generalRiskCount' },
            { title: '低风险', field: 'lowRiskCount' },
          ];

          this.riskTab.riskTrend.chartOption.series = typeList.map(type => ({
            name: type.title,
            data: (res.data || []).map(v => v[type.field]),
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            lineStyle: {
              width: 1
            },
          }));


        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // loadTab1() {
  //   this.dangerTab.tab1.loading = true;

  //   this.http.get<any>(`${baseUrl}/hazard/v1/statistics/chart?classifiedType=job`).subscribe(res => {
  //     this.dangerTab.tab1.loading = false;
  //     if (res.code == 0) {
  //       /**
  //        * table
  //        */
  //       this.dangerTab.tab1.rows = [...res.data];

  //       /**
  //        * chart
  //        */
  //       (() => {
  //         this.dangerTab.tab1.chartOption = {
  //           grid: {
  //             left: 30,
  //             right: 30,
  //             containLabel: true,
  //             bottom: 45,
  //             top: 30,
  //           },
  //           legend: {},
  //           tooltip: {
  //             trigger: 'axis',
  //             axisPointer: {
  //               type: 'cross',
  //               label: {
  //                 backgroundColor: '#6a7985'
  //               }
  //             }
  //           },
  //           xAxis: {
  //             type: 'category',
  //             data: []
  //           },
  //           yAxis: {
  //             type: 'value',
  //             name: '隐患数量'
  //           },
  //           dataZoom: [
  //             {
  //               type: 'slider',
  //               xAxisIndex: 0,
  //             },
  //             {
  //               type: 'inside',
  //               xAxisIndex: 0,
  //             },
  //           ],
  //           series: [],
  //           color: [this.commonService.color.zd, this.commonService.color.yb]
  //         };

  //         (res.data || []).forEach(item => {
  //           this.dangerTab.tab1.chartOption.xAxis.data.push(item.groupName);
  //         });

  //         let typeList = [
  //           { title: '重大隐患', field: 'greatCount' },
  //           { title: '一般隐患', field: 'generalCount' },
  //         ];

  //         this.dangerTab.tab1.chartOption.series = typeList.map(type => ({
  //           name: type.title,
  //           data: (res.data || []).map(v => v[type.field]),
  //           type: 'bar',
  //           barMaxWidth: '50',
  //           label: {
  //             normal: {
  //               show: true,
  //               position: 'top'
  //             }
  //           }
  //         }));
  //       })();
  //     } else {
  //       this.message.error(res.msg || '加载失败');
  //     }
  //   });
  // }
  loadTab2() {
    this.dangerTab.tab2.loading = true;

    this.http.get<any>(`${baseUrl}/hazard/v1/statistics/chart?classifiedType=duty`).subscribe(res => {
      this.dangerTab.tab2.loading = false;
      if (res.code == 0) {
        /**
         * table
         */
        this.dangerTab.tab2.rows = [...res.data];

        /**
         * chart
         */
        (() => {
          this.dangerTab.tab2.chartOption = {
            grid: {
              left: 30,
              right: 30,
              containLabel: true,
              bottom: 45,
              top: 30,
            },
            legend: {},
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            xAxis: {
              type: 'category',
              data: []
            },
            yAxis: {
              type: 'value',
              name: '隐患数量',
              minInterval: 1
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: 0,
              },
              {
                type: 'inside',
                xAxisIndex: 0,
              },
            ],
            series: [],
            color: [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]
          };

          (res.data || []).forEach(item => {
            this.dangerTab.tab2.chartOption.xAxis.data.push(item.groupName);
          });

          let typeList = [
            { title: '重大隐患', field: 'greatCount' },
            { title: '一般隐患', field: 'generalCount' },
          ];

          this.dangerTab.tab2.chartOption.series = typeList.map(type => ({
            name: type.title,
            data: (res.data || []).map(v => v[type.field]),
            type: 'bar',
            barMaxWidth: '50',
            label: {
              normal: {
                show: true,
                position: 'top'
              }
            }
          }));
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  loadTab3() {
    this.dangerTab.tab3.loading = true;

    this.http.get<any>(`${baseUrl}/hazard/v1/statistics/chart?classifiedType=area`).subscribe(res => {
      this.dangerTab.tab3.loading = false;
      if (res.code == 0) {
        /**
         * table
         */
        this.dangerTab.tab3.rows = [...res.data];

        /**
         * chart
         */
        (() => {
          this.dangerTab.tab3.chartOption = {
            grid: {
              left: 30,
              right: 30,
              containLabel: true,
              bottom: 45,
              top: 30,
            },
            legend: {},
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            xAxis: {
              type: 'category',
              data: []
            },
            yAxis: {
              type: 'value',
              name: '隐患数量',
              minInterval: 1
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: 0,
              },
              {
                type: 'inside',
                xAxisIndex: 0,
              },
            ],
            series: [],
            color: [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]
          };

          (res.data || []).forEach(item => {
            this.dangerTab.tab3.chartOption.xAxis.data.push(item.groupName);
          });

          let typeList = [
            { title: '重大隐患', field: 'greatCount' },
            { title: '一般隐患', field: 'generalCount' },
          ];

          this.dangerTab.tab3.chartOption.series = typeList.map(type => ({
            name: type.title,
            data: (res.data || []).map(v => v[type.field]),
            type: 'bar',
            barMaxWidth: '50',
            label: {
              normal: {
                show: true,
                position: 'top'
              }
            }
          }));
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  loadTab4() {
    this.dangerTab.tab4.loading = true;

    this.http.get<any>(`${baseUrl}/hazard/v1/statistics/chart?classifiedType=category`).subscribe(res => {
      this.dangerTab.tab4.loading = false;
      if (res.code == 0) {
        /**
         * table
         */
        this.dangerTab.tab4.rows = [...res.data.chart];

        /**
         * chart
         */
        (() => {
          this.dangerTab.tab4.chartOption = {
            grid: {
              left: 30,
              right: 30,
              containLabel: true,
              bottom: 45,
              top: 30,
            },
            legend: {},
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            xAxis: {
              type: 'category',
              data: []
            },
            yAxis: {
              type: 'value',
              name: '隐患数量',
              minInterval: 1
            },
            dataZoom: [
              {
                type: 'slider',
                xAxisIndex: 0,
              },
              {
                type: 'inside',
                xAxisIndex: 0,
              },
            ],
            series: [],
            color: [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]
          };

          (res.data.chart || []).forEach(item => {
            this.dangerTab.tab4.chartOption.xAxis.data.push(item.groupName);
          });

          let typeList = [
            { title: '重大隐患', field: 'greatCount' },
            { title: '一般隐患', field: 'generalCount' },
          ];

          this.dangerTab.tab4.chartOption.series = typeList.map(type => ({
            name: type.title,
            data: (res.data.chart || []).map(v => v[type.field]),
            type: 'bar',
            barMaxWidth: '50',
            label: {
              normal: {
                show: true,
                position: 'top'
              }
            }
          }));
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  loadTab5() {
    this.dangerTab.tab5.loading = true;

    this.http.get<any>(`${baseUrl}/hazard/v1/statistics/chart?classifiedType=monthly`).subscribe(res => {
      this.dangerTab.tab5.loading = false;
      if (res.code == 0) {
        // console.log(res);
        /**
         * table
         */

        (() => {
          this.dangerTab.tab5.resData = res.data;
          this.dangerTab.tab5.months = res.data.map(v => v.groupId);
        })();

        /**
         * chart
         */
        (() => {
          var data5 = [

          ];

          (res.data || []).forEach(item => {
            data5.push({ month: item.groupId + '月', type: '重大隐患', count: item.greatCount || 0 });
            data5.push({ month: item.groupId + '月', type: '一般隐患', count: item.generalCount || 0 });
          });
          this.dangerTab.tab5.chart && this.dangerTab.tab5.chart.destroy();
          var chart = this.dangerTab.tab5.chart = new G2.Chart({
            container: 'risk_statisics_tab5_chart',
            forceFit: true,
            height: 400
          });
          chart.source(data5, {
            count: {
              alias: '隐患数量'
            }
          });
          chart.axis('count', {
            title: {}
          });
          chart.line().position('month*count').color('type', [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]).shape('smooth');
          chart.point().position('month*count').color('type', [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]).size(4);
          chart.render();
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  loadTab6() {
    this.dangerTab.tab6.loading = true;

    this.http.get<any>(`${baseUrl}/hazard/v1/statistics/chart?classifiedType=year`).subscribe(res => {
      this.dangerTab.tab6.loading = false;
      if (res.code == 0) {
        console.log(res);
        /**
         * table
         */

        (() => {
          this.dangerTab.tab6.resData = res.data;
          this.dangerTab.tab6.months = res.data.map(v => v.groupId);
        })();

        /**
         * chart
         */
        (() => {
          var data6 = [

          ];

          (res.data || []).forEach(item => {
            data6.push({ month: item.groupId + '年', type: '重大隐患', count: item.greatCount || 0 });
            data6.push({ month: item.groupId + '年', type: '一般隐患', count: item.generalCount || 0 });
          });
          this.dangerTab.tab6.chart && this.dangerTab.tab6.chart.destroy();
          var chart = this.dangerTab.tab6.chart = new G2.Chart({
            container: 'risk_statisics_tab6_chart',
            forceFit: true,
            height: 400
          });
          chart.source(data6, {
            count: {
              alias: '隐患数量'
            }
          });
          chart.axis('count', {
            title: {}
          });
          chart.line().position('month*count').color('type', [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]).shape('smooth');
          chart.point().position('month*count').color('type', [this.commonService.hiddencolor.zd, this.commonService.hiddencolor.yb]).size(4);
          chart.render();
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 打开查看对话框
   * @param item 
   */
  openViewDialog(item) {
    if (item._type == 'danger') {
      this.dangerViewDialog.display = true;
      this.dangerViewDialog.loading = true;
      this.http.get<any>(`${baseUrl}/hazard/v1/statistics/publicitydetail?id=` + item.id).subscribe(res => {
        this.dangerViewDialog.loading = false;
        if (res.code == 0) {
          this.dangerViewDialog.data = res.data || [];
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
    } else {
      this.riskViewDialog.display = true;
      this.riskViewDialog.loading = true;
      this.riskViewDialog.evaluateType = item.evaluateType;

      // let action = '';
      // switch(item.evaluateType) {
      //   case 1: { action = '/risk/equipment/init'; break; }
      //   case 2: { action = '/risk/activity/init'; break; }
      //   case 3: { action = '/risk/processnode/init'; break; }
      // }

      this.http.get<getItemInterface>(`${riskUrl}/risk/checkitems/${item.checkItemId}?evaluateType=${item.evaluateType}`).subscribe(res => {
        this.riskViewDialog.loading = false;
        if (res.code == 0) {
          this.riskViewDialog.data = res.data || [];
        } else {
          this.message.error(res.msg || '加载失败');
        }
      });
    }
  }
  loadWarningTab1(){
    this.warningTab.tab1.loading = true;

 this.http.get<any>(`${baseUrl}/hazard/v1/spi/current-month`).subscribe((res:any) => {
      this.warningTab.tab1.loading = false;
      if (res.code == 0) {
        this.warningTab.tab1.data = res.data || {};
        /**
         * chart
         */
        (() => {
          var colorTemplate1 = [[0.15, "rgb(90,186,255)"],[0.34, "rgb(255,255,0)"], [0.62, "rgb(255,136,0)"], [1, "rgb(255,0,0)"]];
          this.warningTab.tab1.chartOption = {
            tooltip : {
                //formatter: "{a} <br/>{b} : {c}%"
                formatter: "{a} <br/>{b}{c}"
            },
            toolbox: {
                feature: {
                   // restore: {},
                  //  saveAsImage: {}
                }
            },
            series: [
                {
                    name: '业务指标',
                    type: 'gauge',
                    radius: '100%',
                    min: 0,
                    max: 100,
                    splitNumber: 10,
                    center: ['50%', '55%'],    // 默认全局居中
                    //startAngle: 180,
                    //endAngle: 0,
                    axisLabel: {
                         color: '#000',
                    },
                    // axisTick: {show: false},
                    // splitLine: {show:false},
                    axisLine: {				// 仪表盘轴线(轮廓线)相关配置。
                      show: true,				// 是否显示仪表盘轴线(轮廓线),默认 true。
                      lineStyle: {			// 仪表盘轴线样式。
                        color: colorTemplate1, 	//仪表盘的轴线可以被分成不同颜色的多段。每段的  结束位置(范围是[0,1]) 和  颜色  可以通过一个数组来表示。默认取值：[[0.2, '#91c7ae'], [0.8, '#63869e'], [1, '#c23531']]
                        opacity: 1,					//图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                        width: 20,					//轴线宽度,默认 30。
                        shadowBlur: 20,				//(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                        shadowColor: "#fff",		//阴影颜色。支持的格式同color。
                      }
                    },
                    detail: {
                      fontSize: 18
                    },
                    data: [{value: res.data.spi, name: ''}]
                }
            ]
        };
        })();
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  loadWarningTab2() {
    this.warningTab.tab2.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/spi/year`).subscribe(res => {
      this.warningTab.tab2.loading = false;
      if (res.code == 0) {
        /**
         * table
         */
        //this.warningTab.tab2.rows = [...res.data];
        /**
         * chart
         */
        (() => {
         
          this.warningTab.tab2.chartOption = {
              // title: {
              //     text: '企业综合风险变化趋势'
              // },
              tooltip: {
                  trigger: 'axis'
              },
              legend: {
                top:'15px',
                  data:[this.companyData.companyAlias+'综合安全风险值','预测值','视频广告','直接访问'],
                  selected: { 
                      '视频广告' :false, 
                      '直接访问' :false,
                      //不想显示的都设置成false 
                      } 
              },
              grid: {
                  left: '3%',
                  right: '6%',
                  //top:'-3%',
                  bottom: '40%',
                  containLabel: true
              },
              color:['#5abaff','#FFFF00','#FF8800','#FF0000'],
              toolbox: {
                  feature: {
                    // saveAsImage: {}
                  }
              },
              xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: (res.data.months || []).map(v => v)
                  //data: ['1月','2月','3月','4月','5月','6月','7月','8月']
              },
              yAxis: {
                  // nameGap:20,
                  // name:'SPI值',
                  type: 'value',
                  axisLine: {show: false},
                  axisLabel: {show: false},
                  axisTick: {show: false},
                  splitLine: {
                      show: false
                  },
              },
              series: [
                  {
                      name:`${this.companyData.companyAlias}综合安全风险值`,
                      type:'line',
                      // stack: '总量',
                      //data:[24, 68, 39, 79, 88, 92,68,72,45,68],
                      data:(res.data.values || []).map(v => v),
                      lineStyle: {
                          normal: {
                              color: 'red',
                              width: 2,
                          }
                      },
                      markArea: {
                          data: [[{ yAxis: '0'}, {yAxis: '39'}]]
                      },
                      // markLine: {
                      //     data: [
                      //         [
                      //             { name: '39', xAxis: 0, yAxis: 39},
                      //             { name: '', xAxis: '8月', yAxis: 39, symbol: 'circle' },
                      //         ],
                      //     ]
                      // }
                  },
                  {
                      name:'预测值',
                      type:'line',
                      // stack: '总量',
                     // data:[0, 0, 0, 0, 0, 0, 0,0,0,0,34],
                      data:(res.data.preValues || []).map(v => v),
                      lineStyle: {
                          normal: {
                              color: 'graph',
                              width: 1,
                              type: 'dashed'
                          }
                      },
                       markArea: {
                          data: [[{ yAxis: '39'}, {yAxis: '69'}]]
                      },
                      // markLine: {
                      //     data: [
                      //         [
                      //             { name: '69', xAxis: 0, yAxis: 69},
                      //             { name: '', xAxis: '8月', yAxis: 69, symbol: 'circle' },
                      //         ],
                      //     ]
                      // }
                  },
                  {
                      name:'',
                      type:'line',
                       markArea: {
                          data: [[{ yAxis: '69'}, {yAxis: '88'}]]
                      },
                      // markLine: {
                      //     data: [
                      //         [
                      //             { name: '88', xAxis: 0, yAxis: 88},
                      //             { name: '', xAxis: '8月', yAxis: 88, symbol: 'circle' },
                      //         ],
                      //     ]
                      // }
                    
                  },
                  {
                      name:'',
                      type:'line',
                       markArea: {
                          data: [[{ yAxis: '88'}, {yAxis: '100'}]]
                      }
                    
                  }
              ]
          };  
        })();

      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
             /**
   * 打开文件查看对话框
   * @param data 
   */
  onOpenFile(data = null) {
    this.viewImg.display = true;
    this.rows_see=[];
    data.potentialPhotographs.forEach(item=>{
     let arr= item.fileName.split(".");
      item.type=arr.pop();
      item.urlPath = this.commonService.OssSignatureUrl(item.urlPath);
    })
    this.rows_see = data.potentialPhotographs;
  }
  /**
   * 打开文件查看对话框
   * @param data 
   */
  closess() {
    this.viewImg.display = false;
  }}