import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit, Component, ComponentFactoryResolver, ElementRef, Inject, OnDestroy, OnInit,
    Renderer2, ViewChild, ViewContainerRef
} from '@angular/core';
import {
    NavigationCancel, NavigationEnd, NavigationError, RouteConfigLoadStart, Router
} from '@angular/router';
import { CommonService, MessageService } from '@core';
import { CacheService } from '@delon/cache';
import { _HttpClient, SettingsService } from '@delon/theme';
import { updateHostClass } from '@delon/util';
import { environment } from '@env/environment';

@Component({
  selector: 'layout-noheader',
  templateUrl: './noheader.component.html',
})
export class LayoutNoHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('settingHost', { read: ViewContainerRef })
  private settingHost: ViewContainerRef;
  isFetching = false;

  updateCheckDialog = {
    display: false
  };

  project = 'xaylog';
  host = 'cn-beijing.log.aliyuncs.com';
  logstore = 'web';

  constructor(
    router: Router,
    _message: MessageService,
    private resolver: ComponentFactoryResolver,
    private settings: SettingsService,
    private el: ElementRef,
    private http: _HttpClient,
    private renderer: Renderer2,
    public srv: CacheService,
    @Inject(DOCUMENT) private doc: any,
    public commonService: CommonService,
    private modalService: NzModalService
  ) {
    // scroll to top in change page
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(evt => {
      //隐藏模块菜单
      this.commonService.isHideModuleSidebar = true;

      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true;
      }
      if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
        this.isFetching = false;
        if (evt instanceof NavigationError) {
          console.log(`无法加载${evt.url}路由`, evt);
          // _message.error(`无法加载${evt.url}路由, 请刷新页面重试.`, { nzDuration: 1000 * 5 });
          this.openUpdateCheckDialog();

          /**
           * 上报异常
           */
          let errorObj = {
            url: evt.url,
            errorMessage: evt.error.message
          };
          let loginData: any = this.srv.getNone('loginData') || {};
          let logUrl = `https://${this.project}.${this.host}/logstores/${this.logstore}/track?APIVersion=0.6.0`;
          let params = {
            userid: loginData.user_id || '', //用户id
            company: loginData.company_name || '',  //公司id
            url: errorObj.url,  //请求url
            method: 'NavigationError', //请求方法 GET POST ...
            request_params: '', //请求的参数 json
            request_time: moment().format('YYYY-MM-DD HH:mm:ss'), //请求时间
            ua: window.navigator.userAgent, //ua信息
            request_id: '', //返回的request_id
            response_status: 404,  //请求状态 200 500 ... 超时为-1
            response_data: JSON.stringify(errorObj) || '',  //返回数据有则填
            profile: environment.profile,  //环境 dev prod test
            platform:'web'
          };

          this.http.get(logUrl, params).subscribe();
        }
        return;
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      setTimeout(() => {
        this.isFetching = false;
      }, 100);
    });

    this.commonService.setOSSInterval();
  }

  private setClass() {
    const { el, doc, renderer, settings } = this;
    const layout = settings.layout;
    updateHostClass(
      el.nativeElement,
      renderer,
      {
        ['alain-default']: true,
        [`alain-default__fixed`]: layout.fixed,
        [`alain-default__collapsed`]: layout.collapsed,
      },
    );

    doc.body.classList[layout.colorWeak ? 'add' : 'remove']('color-weak');
  }

  ngAfterViewInit(): void {
    // Setting componet for only developer
    // if (!environment.production) {
    //   setTimeout(() => {
    //     const settingFactory = this.resolver.resolveComponentFactory(SettingDrawerComponent);
    //     this.settingHost.createComponent(settingFactory);
    //   }, 22);
    // }
  }

  ngOnInit() {
    const { settings, unsubscribe$ } = this;
    settings.notify.pipe(takeUntil(unsubscribe$)).subscribe(() => this.setClass());
    this.setClass();

    this.updateCheck();
  }

  ngOnDestroy() {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
  }

  openUpdateCheckDialog () {
    this.modalService.confirm({
      nzTitle: '发现新版本, 现在是否刷新页面？',
      // nzContent: '<b>Some descriptions</b>',
      // nzZIndex: 999999,
      nzOnOk: () => {
        window.location.reload();
      }
    });
  }

  /**
   * 更新检查
   */
  updateCheck() {
    let intervalTime = 5 * 1000;
    let runtimeUrl = document.querySelector('script[src*=runtime]').getAttribute('src');

    if(runtimeUrl.endsWith('runtime.js')) return;

    setTimeout(() => {
      let getRuntime = () => {
        this.http.get(`${runtimeUrl}?_=${+new Date}`, {}, { responseType: 'text' }).subscribe((runtimeScript) => {
          setTimeout(getRuntime, intervalTime);
        }, (error) => {
          if (error.status == 404) {
            // if (window.confirm('发现新版本, 现在是否刷新页面？')) {
            //   window.location.reload()
            // }
            this.openUpdateCheckDialog();
          } else {
            setTimeout(getRuntime, intervalTime);
          }
        });
      };
      getRuntime();
    }, intervalTime);

  }
}
