
import { Component, OnInit } from '@angular/core'; import { MessageService } from '@core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzCollapseModule } from 'ng-zorro-antd';

import { baseUrl } from '@core';

@Component({
  selector: 'app-danger-check',
  templateUrl: './danger-check.component.html',
  styleUrls: ['./danger-check.component.scss']
})
export class DangerCheckComponent implements OnInit { 
  params: any = {};
  basics: any = {};
  items: any = [];
  orglist=[];

  datausccess=true;

  checkList1= [{isactive: true},{isactive: false},{isactive: false}];
 // checkList1= [];
  collDoms:any = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: _HttpClient,
    private modal: ModalHelper) { }

  async ngOnInit() {    
    this.params = this.route.snapshot.params;
    await this.getData();
   
    this.collDoms = document.getElementsByClassName("collapseset");

  }
 
  closeOpenCol(checkFlags,num,index) {
    for(var i=0;i < checkFlags.length;i++) {
      if((i != index) && checkFlags[i].isactive) {
        checkFlags[i].isactive = false;
        this.collDoms[num].getElementsByClassName("collapse")[i].setAttribute("class","collapse");
        break;
      }
    }
  }
  checkIt(index,disable,num) {
    this.checkCol(index,disable,num);
  }
  checkCol(index,disable,num) {
    if(disable) {
      return;
    }
    let checkFlags;
    checkFlags = this.checkList1;
    if(checkFlags[index].isactive) {
      this.collDoms[num].getElementsByClassName("collapse")[index].setAttribute("class","collapse");
    } else {
      this.collDoms[num].getElementsByClassName("collapse")[index].setAttribute("class","collapse active");
    }
    checkFlags[index].isactive = !checkFlags[index].isactive;
  }
  /**
   * 获取风险卡告知内容
   */
  getData() {

    return this.http.get(`${baseUrl}/hazard/v1/records/cards/scan/screen`, this.params).toPromise().then((res:any) => {
      if (res.code === 0) {
        this.basics = res.data || {};
      } else {
        this.msg.error(res.msg);
      }

    })
  }
  ngAfterViewInit() {
    // 监听路由变化
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/danger-check')) {
          this.getData(); 
        }
      }
    });
  }
}

