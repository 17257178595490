import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injector,
  TemplateRef,
  Type,
} from '@angular/core';

import {
  NzMessageService,
  NzMessageDataOptions,
  NzMessageDataFilled,
} from 'ng-zorro-antd';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends NzMessageService {
  constructor(
    overlay: Overlay,
    injector: Injector,
    cfr: ComponentFactoryResolver,
    appRef: ApplicationRef,
    commonService: CommonService,
  ) {
    super(overlay, injector, cfr, appRef);
  }

  error(
    content: string | TemplateRef<void>,
    options?: NzMessageDataOptions,
  ): any {
    localStorage.getItem('_token') && super.error(content, options);
  }
}
