// #region third libs

// #region third libs
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CountdownModule } from 'ngx-countdown';
import { NgxTinymceModule } from 'ngx-tinymce';
import { UEditorModule } from 'ngx-ueditor';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DelonABCModule } from '@delon/abc';
import { DelonACLModule } from '@delon/acl';
import { DelonChartModule } from '@delon/chart';
import { DelonFormModule } from '@delon/form';
// delon
import { AlainThemeModule } from '@delon/theme';
import { DelonUtilModule } from '@delon/util';
// i18n
import { TranslateModule } from '@ngx-translate/core';

import { SelectpeopleComponent } from '../routes/system/process/selectpeople/selectpeople.component';
import { SelectpositionComponent } from '../routes/system/process/selectposition/selectposition.component';
import { WidgetModule } from '../widget/widget.module';
// #endregion
// #region your componets & directives
import { AddressComponent } from './components/address/address.component';
import { AmapShowComponent } from './components/amap-show/amap-show.component';
import { BdUeditorComponent } from './components/bd-ueditor/bd-ueditor.component';
import { CaptchaBtnComponent } from './components/captcha-btn/captcha-btn.component';
import { CardUploadComponent } from './components/card-upload/card-upload.component';
import { CheckitemDetailDialogComponent } from './components/checkitem-detail-dialog/checkitem-detail-dialog.component';
import { CustomColumnModalComponent } from './components/custom-column/custom-column-modal.component';
import { CustomColumnComponent } from './components/custom-column/custom-column.component';
import { DelayDirective } from './components/delay/delay.directive';
import { DownloadExcelComponent } from './components/download-excel/download-excel.component';
import { EchartsComponent } from './components/echarts/echarts.component';
import { EditorComponent } from './components/editor/editor.component';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { FooterCopyrightComponent } from './components/footer-copyright/footer-copyright.component';
import { FooterGlobalComponent } from './components/footer-global/footer-global.component';
import { ImgComponent } from './components/img/img.component';
import { ImgDirective } from './components/img/img.directive';
import { MasonryDirective } from './components/masonry/masonry.directive';
import { ModuleNameComponent } from './components/module-name/module-name.component';
import { MouseFocusDirective } from './components/mouse-focus/mouse-focus.directive';
import { ScrollbarDirective } from './components/scrollbar/scrollbar.directive';
import { SelectDownloadExcelComponent } from './components/select-download-excel/select-download-excel.component';
import { StatusLabelComponent } from './components/status-label/status-label.component';
import { TnlgProcessComponent } from './components/tnlg-process/tnlg-process.component';
import { XlsUploadComponent } from './components/xls-upload/xls-upload.component';
import { XalChartDirective } from './directives/xal-chart.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';

// #endregion
// #region your componets & directives
import { MS_SHARED_COMPONENTS } from './ms';
import { SafeUrlPipe } from './safe-url.pipe';

const THIRDMODULES = [
  NgZorroAntdModule,
  CountdownModule,
  UEditorModule,
  NgxTinymceModule,
];
const COMPONENTS_ENTRY = [
  ImgComponent,
  FileManagerComponent,
  StatusLabelComponent,
  CaptchaBtnComponent,
  FooterCopyrightComponent,
  FooterGlobalComponent,
  AddressComponent,
  CustomColumnComponent,
  CustomColumnModalComponent,
];
const COMPONENTS = [
  SafeUrlPipe,
  XalChartDirective,
  EditorComponent,
  ...COMPONENTS_ENTRY,
  ...MS_SHARED_COMPONENTS,
  ModuleNameComponent,
  BdUeditorComponent,
  EchartsComponent,
  AmapShowComponent,
  CheckitemDetailDialogComponent,
  XlsUploadComponent, //Excel 导入
  DownloadExcelComponent, //Excel 导出
  SelectDownloadExcelComponent, //Excel 选择导出
  CardUploadComponent,
  TnlgProcessComponent,
  SelectpeopleComponent,
  SelectpositionComponent,
  FileUploadComponent,
];
const DIRECTIVES = [
  ImgDirective,
  DelayDirective,
  MasonryDirective,
  ScrollbarDirective,
  MouseFocusDirective,
];
// #endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonABCModule,
    DelonChartModule,
    DelonACLModule,
    DelonFormModule,
    DelonUtilModule,
    // third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    XlsUploadComponent,
    DownloadExcelComponent,
    FileUploadComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonABCModule,
    DelonChartModule,
    DelonACLModule,
    DelonFormModule,
    DelonUtilModule,
    FlexLayoutModule,
    // i18n
    TranslateModule,
    WidgetModule,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  entryComponents: [CheckitemDetailDialogComponent],
})
export class SharedModule {}
