export const environment = {
  profile: 'prod',
  SERVER_URL: `./api`,
  production: false,
  useHash: true,
  hmr: false,
  controlvar:'prod',
  version:'2.0v',

  domain: './api',
  domainMap: '//jtghmin.xinanyun.cn',
  mockUrl: '//106.12.201.179:7300/mock',
  access_token: '',
  serveUrl:'//www.xinanyun.cn',
  wekeUrl:'//weke.anhuanjia.com',
  ahjApiUrl:'//api.anhuanjia.com',
  ahjsnsApiUrl:'//social.anhuanjia.com',
  ticketUrl: '//api.xinanyun.cn',
  ticketProcessUrl:'//bpm.xinanyun.cn',
  YHbaseUrl: '//esc.anhuanjia.com',
  BZbaseUrl: '//aq.anhuanjia.com',
  ReliBaseUrl: '//reli.xinanyun.cn',
  AliyunOssUrl:'https://jtghmin.xinanyun.cn/jtgh',
  // 应用跳转配置，需要在部署时候配置到对应的环境
  appRedirectConfig: {
    // productList 中的应用对应的链接
    appMap: {
      培训宝产品: 'peixun.xinanyun.cn',
      安全宝产品: 'anquan.xinanyun.cn',
      鑫安云产品: 'www.xinanyun.cn',
    },
    // 当前应用名称
    client: '鑫安云产品',
    // 跳转链接中 token 参数对应的名称
    appRedirectTokenParamName: 'appRedirectToken'
  }
};
