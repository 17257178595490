import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';

import { CallbackComponent } from './callback/callback.component';
import { CultureBlockComponent } from './culture/culture-block/culture-block.component';
import { CultureComponent } from './culture/culture.component';
// 首页-仪表盘
import { DashboardComponent } from './dashboard/dashboard.component';
// single pages
import { UserLockComponent } from './passport/lock/lock.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
import { UserRegisterComponent } from './passport/register/register.component';

import { RouteRoutingModule } from './routes-routing.module';
import {
    CertificateComponent
} from './system/personinfo/setting/certificate/certificate.component';
import { InfoComponent } from './system/personinfo/setting/info/info.component';
import { ResetComponent } from './system/personinfo/setting/reset/reset.component';
//个人中心
import { SettingComponent } from './system/personinfo/setting/setting.component';
import { FilesInfoComponent } from './train/files-manage/files-info/files-info.component';
import { SinglePageComponent } from './single-page/single-page.component';
import { NoticePageComponent } from './notice-page/notice-page.component';
import { AmapFullComponent } from './dashboard/amap-full/amap-full.component';
import { DataLsdV2Component } from './data-lsd-v2/data-lsd-v2.component';
import { DataLsdV3Component } from './data-lsd-v3/data-lsd-v3.component';
import { UserForgetpwdComponent } from './passport/forgetpwd/forgetpwd.component';
import { DataLsdLoginComponent } from './data-lsd-login/data-lsd-login.component';
import { PoschangeComponent } from './system/member/memberlist/edit/poschange/poschange.component';
import { CheckBookDetailComponent } from './danger-check/book/check-book/detail/detail.component';
import { DataTrafficV2Component } from './data-traffic-v2/data-traffic-v2.component';
import { DataLsdBuildComponent } from './data-lsd-build/data-lsd-build.component';
import { DataEduV2Component } from './data-edu-v2/data-edu-v2.component';
import { QuickDeployComponent } from './quick-deploy/quick-deploy.component';
import { QuickDeployAccountComponent } from './quick-deploy-account/quick-deploy-account.component';
import { ChangePersonComponent } from './shared/change-person/change-person.component';
// import { BaseComponent } from './base/base.component';
import { OpenFileComponent } from './common/open-file/open-file.component';

// 员工健康上报H5页面
// import { HealthReportComponent } from './health-report/health-report.component';
import { WaterComponent } from './water/water.component';

import { HealthReportComponent } from './h5-single/health-report/health-report.component'; // 员工健康上报H5页面
import { RiskcardContentComponent } from './h5-single/riskcard-content/riskcard-content.component'; // h5风险卡告知内容
import { DangerCheckComponent } from './h5-single/danger-check/danger-check.component'; // h5隐患排查情况
import { WorkRuleComponent } from './h5-single/work-rule/work-rule.component'; // h5操作规程

// 应急预案内容h5页面
import { LedgerContentComponent } from './ledger-content/ledger-content.component';
import { RiskPointComponent } from './h5-single/risk-point/risk-point.component';
import { RiskCardComponent } from './h5-single/risk-card/risk-card.component';
import { DataLsdV4Component } from './data-lsd-v4/data-lsd-v4.component';
import { RiskcardContentV2Component } from './h5-single/riskcard-content-v2/riskcard-content-v2.component';
import { AccidentCaseComponent } from './h5-single/accident-case/accident-case.component';
import { DataLsdV5Component } from './data-lsd-v5/data-lsd-v5.component';
import { EnterpriseApplyComponent } from './h5-single/enterprise-apply/enterprise-apply.component';
import { AgreeComponent } from './h5-single/enterprise-apply/agree/agree.component';

//安全专家在线注册
import { SafeOnlineRegistrationComponent } from './h5-single/safe-online-registration/safe-online-registration.component';
import { RegistraSuccessComponent } from './h5-single/safe-online-registration/registra-success/registra-success.component';
// import { MapshowComponent } from './dashboard/mapshow/mapshow.component';
const COMPONENTS = [
  // passport pages
  UserLoginComponent,
  UserRegisterComponent,
  UserRegisterResultComponent,
  // single pages
  UserLockComponent,
  CallbackComponent,
  DashboardComponent,
  SettingComponent,
  InfoComponent,
  ResetComponent,
  RiskcardContentComponent,
  FilesInfoComponent,
  CertificateComponent,
  UserForgetpwdComponent,
  HealthReportComponent,
  DangerCheckComponent,
  WorkRuleComponent,
  // culture
  CultureBlockComponent,
 
  OpenFileComponent,
  WaterComponent,

  // OpenFileComponent,
  LedgerContentComponent,
  RiskPointComponent,
  RiskCardComponent,
  RiskcardContentV2Component,
  AccidentCaseComponent,
];
const COMPONENTS_NOROUNT = [
  PoschangeComponent,
  CheckBookDetailComponent,
  ChangePersonComponent,
  AgreeComponent,
];

@NgModule({
  imports: [SharedModule, RouteRoutingModule],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
    SinglePageComponent,
    NoticePageComponent,
    AmapFullComponent,
    DataLsdV2Component,
    DataLsdV3Component,
    DataLsdLoginComponent,
    DataTrafficV2Component,
    DataLsdBuildComponent,
    DataEduV2Component,
    CultureComponent,
    QuickDeployComponent,
    QuickDeployAccountComponent,
    DataLsdV4Component,
    DataLsdV5Component,
    EnterpriseApplyComponent,
    SafeOnlineRegistrationComponent,
    RegistraSuccessComponent
    ],
  entryComponents: COMPONENTS_NOROUNT,
})
export class RoutesModule {}
