import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';

import { Base } from './base';
import { IRes } from './base.model';

/**
 * API SERVICE 基类。
 */
export abstract class BaseApi extends Base {
  protected http: HttpClient;
  constructor(protected injector: Injector) {
    super(injector);
    this.http = injector.get(HttpClient);
  }

  getApiUrl(url: string) {
    return this.appConfig.domain + ('/' + url).replace(/\/{2,}/g, '/');
  }

  extractData<T>(res: IRes<T>) {
    return res.data;
  }

  get<T>(args: IGetArgs) {
    const { url: originUrl, params, headers, options={} } = args;
    const url = options.skipDomain ? originUrl : this.getApiUrl(originUrl);

    return this.http
      .get<IRes<T>>(url, { params, headers })
      .pipe(map(res => this.extractData(res)));
  }

  /**
   * 发送 post 请求
   * @param url
   * @param body
   */
  post<T>(args: IPostArgs) {
    const { url: originUrl, body, options={} } = args;
    const url = options.skipDomain ? originUrl : this.getApiUrl(originUrl);
    return this.http
      .post<IRes<T>>(url, body)
      .pipe(map(res => this.extractData(res)));
  }
}

export interface IPostArgs {
  url: string;
  body?: any;
  options?: any;
}

export interface IGetArgs {
  url: string;
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  options?: any;
}

/**
 * 配置请求细节
 */
export interface IRequestOptions {
  /**
   * 是否阻止添加 env 中的 domain 到 url 中，默认 false（不阻止）
   */
  skipDomain?: boolean;
}
