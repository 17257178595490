import { Component, OnInit } from '@angular/core'; import { MessageService } from '@core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { NzMessageService, NzModalRef,NzModalService} from 'ng-zorro-antd';
import { mockUrl, baseUrl } from '@core';
import { DataService, CommonService } from '@core';
import { RecordComponent } from '../../../share/record/record.component';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less']
})
export class CheckBookDetailComponent implements OnInit {
  i:any={};
  data:any={};
  loading = false;
  searchParams:any;
  constructor(public msg: MessageService,
    private http: _HttpClient,
    private modal:ModalHelper, 
    private ref: NzModalRef,
    public commonService: CommonService,
    private modalService: NzModalService
    ) { }
  /**
   * 查看对话框
   */
  rows_see=[];
  viewImg = {
    display: false,
    data: ''
  };
  /**
   * 打开放大图片
   * @param data 
   */
  onOpenImg(data) {
    this.viewImg.display = true;
    this.viewImg.data = data;
  }

  ngOnInit() {
    this.getData()
  }
  getData(){
    let searchParams = {
      ...this.searchParams,
      id: this.i.id,
      recordType: this.i.potentialStatus
    };
    
    this.http.get<any>(`${baseUrl}/hazard/v1/records/record/`+this.i.id,searchParams).subscribe(res => {
      if (res.code == 0) {
        this.data = res.data || {};
      } else {
        this.msg.error(res.msg || '加载失败');
      }

    });
  }
  close() {
    this.ref.destroy();
  }

            /**
   * 打开文件查看对话框
   * @param data 
   */
  onOpenFile(data = []) {
    this.viewImg.display = true;
    this.rows_see=[];
    data.forEach(item=>{
     let arr= item.fileName.split(".");
      item.type=arr.pop();
      item.urlPath = this.commonService.OssSignatureUrl(item.urlPath);
    })
    this.rows_see = data;
  }
  /**
   * 打开文件查看对话框
   * @param data 
   */
  closess() {
    this.viewImg.display = false;
  }

      /**
   * 打开整改记录对话框
   * @param data 
   */
  openRrcordDialog(data='') {
    const modal = this.modalService.create({
      nzTitle: '隐患整改记录',
      nzWidth: '1200',
      nzContent: RecordComponent,
      nzMaskClosable: false,
      nzComponentParams: {
        recordId: this.i.id,
      },
      nzFooter: null
    });
  }
}
