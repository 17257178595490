
import { HttpClient, HttpRequest, HttpResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme'
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { UploadFile } from 'ng-zorro-antd/upload';
import { baseUrl } from '@core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.less']
})
export class SinglePageComponent implements OnInit {
  loading = false;
  companyData: any = [];
  uploadAddress = `${baseUrl}/hazard/v1/jzdsp/colormap?ordId=&ordKey=`;
  params: any = {};
  fileList: UploadFile[] = [];

  constructor(
    public msg: NzMessageService,
    public http: HttpClient,
    private mod: ModalHelper) { }

  ngOnInit() {
    this.getCompanyData();
  }
  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };
  /**
   * 获取企业数据
   */
  getCompanyData() {

    this.http.get<any>(`${baseUrl}/hazard/v1/jzdsp`).subscribe(res => {
      if (res.code == 0) {
        this.companyData = res.data || [];
      } else {
        this.msg.error(res.msg || '获取企业数据失败');
      }
    })
  }
  save() {
    const formData = new FormData();
    this.fileList.forEach((file: any) => {
      formData.append('files', file);
    });
    formData.append('ordId', this.params.company.ordId);
    formData.append('ordKey', this.params.company.ordKey)
    //const req = new HttpRequest('POST', `${baseUrl}/hazard/v1/jzdsp/colormap`, formData, {
      // reportProgress: true
    //});
    this.loading = true;
    this.http.post<any>(`${baseUrl}/hazard/v1/jzdsp/colormap`,formData).subscribe(res => {
   // this.http.request(req).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 0) {
        this.msg.success(res.msg || '保存成功！');
      }else{
        this.msg.error(res.msg || '保存失败！');
      }
    })

  }
}
