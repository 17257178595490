import { Component, OnInit, Inject } from '@angular/core'; 
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl,StartupService } from '@core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-water',
  templateUrl: './water.component.html',
  styleUrls: ['./water.component.scss']
})
export class WaterComponent implements OnInit {
  mobile:string;
  token:string;
  loading = false;
  params: any = {};
  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: HttpClient,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    ) {
      this.route.queryParams.subscribe((item)=>{
        this.mobile= item.mobile;
        this.token= item.token;
       });
     }

  ngOnInit() {
    this.params = this.route.snapshot.params;
    this.getLogin();
  }
  // 更新数据
  getLogin(){
    const params = {
      type:'ajh',
      mobile:this.params['mobile'] ? this.params['mobile'] : '',
      code:this.params['token'] ? this.params['token'] : ''
    }
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic eXVrb246eXVrb24')
      .set('token', '');
    this.loading = true;
    this.http.post<any>(`${baseUrl}/auth/mobile/token/sms?type=${params.type}&mobile=${params.mobile}&code=${params.code}`, 
    {}, { headers }
    ).subscribe(res =>{   
      if (res.code === 0) {     
        // 设置用户Token信息
        res.token = res.access_token;
        this.tokenService.set(res);      
        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        this.startupSrv.load().then(() => {
          this.loading = false;
          this.router.navigateByUrl('/')
        });
      } else {
        this.loading = false;
        this.router.navigateByUrl('/passport/login');
        return;
      }
    })
  }
  ngAfterViewInit() {
    // 监听路由变化
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/water')) {
          this.getLogin(); 
        }
      }
    });
  }
}
