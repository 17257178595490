import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MessageService, baseUrl } from '@core';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less'],
})
export class FileUploadComponent implements OnInit {
  // 导入地址
  @Input() uploadAction = `${baseUrl}/basics/v1/file`;
  @Input() isDisabled = false; // 是否置灰
  @Input() isButtonPic = false; // 是否是上传按钮还是上传+
  // 上传参数
  @Input() uploadParams: any = {
    isLimit: false, // 是否限制大小
    limitSize: 5, // 限制几兆
    limitMessage: '文件必须小于5M', // 超出提示
    limitNumber: 1000, // 是否限制一张
    isRequired: false, // 是否必传
    fileType: '', // 限制文件类型
    listType: 'text', // text or picture or picture-card,
    nzShowButton:true // 是否显示上传按钮
  };
  // 文件列表
  @Input() file = [];

  @Input() uploadStyle = 'upload-block'

  loading = false;

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  };

  fileList = [];
  constructor(private msg: MessageService) {}

  ngOnInit() {
  }
  
  beforeUpload = (file, _fileList) => {
    return new Observable((observer: Observer<boolean>) => {
      let isLt5M = file.size! / 1024 / 1024 < this.uploadParams.limitSize;
      if (this.uploadParams.isLimit) {
        if (!isLt5M) {
          this.msg.error(this.uploadParams.limitMessage);
          observer.complete();
          return;
        }
      } else {
        isLt5M = true;
      }

      observer.next(isLt5M);
      observer.complete();
    });
  };
  /**
   * 文件上传状态改变
   * @param UploadChangeParam
   */
  fileChange(UploadChangeParam) {

    let errorIndexs = [];
    if (UploadChangeParam.type == 'success' || UploadChangeParam.type == 'removed') {
      this.fileFormat(UploadChangeParam, errorIndexs);
    }
    errorIndexs.forEach(i => this.fileList.splice(i, 1));
    const hash = {}; // 去重
    this.fileList = this.fileList.reduce((preVal, curVal) => {
      // tslint:disable-next-line: no-unused-expression
      hash[curVal.name]
        ? ''
        : (hash[curVal.name] = true && preVal.push(curVal));
      return preVal;
    }, []);
    // this.fileList = [...this.fileList];
  }

  fileFormat(UploadChangeParam, errorIndexs) {
    this.fileList = [];
    UploadChangeParam.fileList.forEach((v, i) => {
      console.log(v);
      if (v.status == 'error') {
        this.msg.error('文件上传错误!');
        errorIndexs.push(i);
      }

      if (v.response) {
        if (v.response.code != 0) {
          this.msg.error(v.response.message);
          errorIndexs.push(i);
        } else {
          // 转换为双向数据绑定可以显示的数据
          v.response.data.forEach((item, index) => {
            let obj = {
              url: '',
              name: '',
              status: 'done',
              fileSize: (UploadChangeParam.file.size/ 1024 / 1024).toFixed(2),
              fileType: UploadChangeParam.file.name.split(".").pop(),
              fileName: UploadChangeParam.file.name
            };
            obj.url = item.fileUrl;
            obj.name = v.name;
            this.fileList.push(obj);
          });
        }
      } else {
        this.fileList.push(v);
      }
    });
  }
}
