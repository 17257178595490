import { Component, OnInit } from '@angular/core';
import { CommonService } from '@core';

@Component({
  selector: 'app-open-file',
  templateUrl: './open-file.component.html',
  styleUrls: ['./open-file.component.scss']
})
export class OpenFileComponent implements OnInit {
 
  fileList;
  videolist = ['jpg', 'png', 'jpeg', 'gif', 'pic', 'bmp'];

  constructor( public commonService: CommonService ) { }

  ngOnInit() {
    (this.fileList||[]).forEach(item=>{
      const arr= item.fileName.split(".");
      item.fileSizes =  ((item.fileSize)/1024/1024).toFixed(2);
      item.type=arr.pop();
      item.isVidio = this.commonService.isVidio(item.type);
      item.filePath = this.commonService.OssSignatureUrl(item.filePath);
      if (this.videolist.includes(item.type)) {
        item.isImg = true;
      }else{
        item.isImg = false;
      }
    })
  }
}
