import { HttpRequest } from '@angular/common/http';
import { MockRequest } from '@delon/mock';

const list = [];

for (let i = 0; i < 46; i += 1) {
  list.push({
    key: i,
    //disabled: i % 6 === 0,
    // href: 'https://ant.design',
    // avatar: [
    //   'https://gw.alipayobjects.com/zos/rmsportal/eeHMaZBwmTvLdIwMfBpg.png',
    //   'https://gw.alipayobjects.com/zos/rmsportal/udxAbMEhpwthVVcjLXik.png',
    // ][i % 2],
    id: `${i+1}`,
    truename: `张三 ${i}`,
    phone: '1388888888',
    depart: '安环部',
    position: '主任',
    positionID: 4,
    station: '安环部-主任',
    stationID:'1.4',
    updatedAt: new Date(),
    createdAt:new Date(),
    // updatedAt: new Date(`2017-07-${i < 18 ? '0'+(Math.floor(i / 2) + 1) : (Math.floor(i / 2) + 1)}`),
    // createdAt: new Date(`2017-07-${i < 18 ? '0'+(Math.floor(i / 2) + 1) : (Math.floor(i / 2) + 1)}`),
    role:'系统管理员',
    progress: Math.ceil(Math.random() * 100),
  });
}

function getUsers(params: any) {
  let ret = [...list];
  if (params.sorter) {
    const s = params.sorter.split('_');
    ret = ret.sort((prev, next) => {
      if (s[1] === 'descend') {
        return next[s[0]] - prev[s[0]];
      }
      return prev[s[0]] - next[s[0]];
    });
  }
  if (params.statusList && params.statusList.length > 0) {
    ret = ret.filter(data => params.statusList.indexOf(data.status) > -1);
  }
  if (params.id) {
    ret = ret.filter(data => data.id.indexOf(params.id) > -1);
  }
  return ret;
}
function saveUser(params: any){

}
function removeUser(nos: string): boolean {
  nos.split(',').forEach(key => {
    const idx = list.findIndex(w => w.key === key);
    if (idx !== -1) list.splice(idx, 1);
  });
  return true;
}
export const USERLIST = {
  '/userlist': (req: MockRequest) => getUsers(req.queryString),
  'POST /userlist': (req: MockRequest) => saveUser(req.body.data),
  'DELETE /userlist': (req: MockRequest) => removeUser(req.queryString.nos),
};
