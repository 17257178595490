
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MessageService } from '@core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { NzMessageService, UploadFile, NzModalService } from 'ng-zorro-antd';

import { baseUrl, mockUrl, riskUrl, uploadUrl } from '@core';
import { DataService, StartupService, CommonService } from '@core';

import { LazyService } from '@delon/util';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';

declare var CryptoJS: any;
declare var Base64: any;

@Component({
  selector: 'app-data-lsd-login',
  templateUrl: './data-lsd-login.component.html',
  styleUrls: ['./data-lsd-login.component.less']
})
export class DataLsdLoginComponent implements OnInit {
  loading = false;
  form: FormGroup = null;

  constructor(private router: Router, private startupSrv: StartupService, @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService, private lazy: LazyService, private modalSrv: NzModalService, private fb: FormBuilder, private message: MessageService, private http: HttpClient, private dataService: DataService, public commonService: CommonService) {
    this.lazy.loadScript('assets/js/crypto-js.js');
    this.modalSrv.closeAll();
    this.initEditDialogForm();
  }

  ngOnInit() {
  }

  /**
   * 初始化表单
   */
  initEditDialogForm() {
    this.form = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  /**
   * 提交
   */
  submit() {
    Object.values(this.form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });

    if (!this.form.valid) {
      return;
    }

    let data: any = {
      data: this.form.value,
      key: 'youkon0123456789',
      param: ['password']
    };
    data = this.encryption(data);

    //请求头
    let headers = new HttpHeaders({
      'Authorization': 'Basic eXVrb246eXVrb24',
      'token': '',  //标识该请求不需要 token
    });

    let url = `${baseUrl}/auth/oauth/token?password=${encodeURIComponent(data.password)}&grant_type=password&username=${data.userName}`;
    this.loading = true;
    this.http.get<any>(url, { headers }).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        //设置 token
        res.token = res.access_token;
        this.tokenService.set(res);

        //加载应用数据
        this.startupSrv.checkToken(res).then(() => {
          let loginBeforeUrl = window.localStorage.getItem('loginBeforeUrl') || '';
          this.router.navigateByUrl(loginBeforeUrl).then(() => {
            window.localStorage.removeItem('loginBeforeUrl');
          })
        });
      } else {
        this.message.error(res.msg || '登录不成功!');
      }
    });
  }

  /**
   * 加密
   * @param params 
   */
  encryption(params) {
    let {
      data,
      type,
      param,
      key
    } = params
    const result = JSON.parse(JSON.stringify(data))
    if (type === 'Base64') {
      param.forEach(ele => {
        result[ele] = btoa(result[ele])
      })
    } else {
      param.forEach(ele => {
        var data = result[ele]
        key = CryptoJS.enc.Latin1.parse(key)
        var iv = key
        // 加密
        var encrypted = CryptoJS.AES.encrypt(
          data,
          key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding
        })
        result[ele] = encrypted.toString()
      })
    }
    return result
  }

}
