import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';

import { _HttpClient, AlainThemeConfig } from '@delon/theme';

@Injectable({
  providedIn: 'root'
})
export class HttpService extends _HttpClient {
  constructor(handler: HttpClient, cog: AlainThemeConfig) {
    super(handler, cog);
  }

  postForm<T>(url: string, body: any | null, params: any | null = null, options: any = {}) {
    if (!body) {
      body = '';
    } else {
      body = Object.entries(body).map(([key, value]) => `${key}=${value}`).join('&');
    }

    options.headers = options.headers || {};
    options.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';

    return this.post<T>(url, body, params, options);
  }


}
