import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  OnInit,
} from '@angular/core'; import { MessageService } from '@core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNodeOptions ,NzMessageService} from 'ng-zorro-antd';
import { mockUrl, baseUrl, access_token ,uploadUrl} from '@core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.less']
})
export class ResetComponent implements OnInit {


  constructor(public msg: MessageService,
    private http: _HttpClient,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef ) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      newPwd: [null, [Validators.required,Validators.minLength(6),Validators.maxLength(15)]],
      oldPwd: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
  }
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.newPwd.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  get passWord() {
    return this.validateForm.controls.newPwd;
  }

  validateForm: FormGroup;
  submitForm(): void {
    Object.values(this.validateForm.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    });

    if (!this.validateForm.valid) {
      return;
    }
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  

    let data = {
      ...this.validateForm.value
    };
    this.http.put(`${baseUrl}/admin/v1/users/pwd`,data).subscribe((res:any) => {
      if (res.code == 0) {
        this.msg.success(res.msg || '重置成功');
      } else {
        this.msg.error(res.msg || '重置失败');
      }
    });

  }

}
