import * as moment from 'moment';

import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from '@core';
import { _HttpClient } from '@delon/theme';

@Component({
  selector: 'download-excel',
  templateUrl: './download-excel.component.html',
  styleUrls: ['./download-excel.component.less'],
})
export class DownloadExcelComponent implements OnInit {
  //导出地址
  @Input() url = '';
  //文件名(不含扩展名)
  @Input() fileName = '导出文件';
  //查询参数
  @Input() searchParams: any = {};
  @Input() butWord: any = '导出';
  @Input() butType: any = '';

  loading = false;

  constructor(private message: MessageService, private http: _HttpClient) {}

  ngOnInit() {}

  /**
   * 导出excel
   */
  downloadExcel() {

    if (!this.searchParams.downAll) {// 用户列表导出全部 不需要参数
      if (
        this.searchParams.ids === undefined ||this.searchParams.ids.length == '0'
      ) {
        this.message.info('请先勾选要导出的记录!');
        return;
      }
    }

    this.loading = true;
    this.http
      .get(`${this.url}`, this.searchParams, {
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe(
        (res: any) => {
          if (res.body.code == 1) {
            this.loading = false;
            this.message.error('导出不成功!');
            return;
          }

          try {
            const link = document.createElement('a');
            const blob = new Blob([<any>res.body]);
            let fileNmae = `${this.fileName}${moment().format(
              'YYYYMMDDHHmmssSSS',
            )}.xlsx`;
            // try { fileNmae = decodeURIComponent(res.headers.get('Content-Disposition').split('filename=')[1]) } catch (error) { }  //在有的浏览器中取不到响应头
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', fileNmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            this.message.error(error.toString());
          }

          this.loading = false;
        },
        err => {
          console.warn(err);

          this.loading = false;
        },
      );
  }
}
