import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MessageService } from '@core';
import { DomSanitizer } from '@angular/platform-browser'

import { _HttpClient } from '@delon/theme';
import { baseUrl, mockUrl, riskUrl, uploadUrl, domain } from '@core';
import { CommonService } from '@core';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { ActivatedRoute, Params, Router, ActivationEnd, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';

import * as Mock from 'mockjs';

import * as G2 from '@antv/g2';
import * as moment from 'moment';

let hazardUrl = `${domain}/hazard/v1`;
let basicsUrl = `${domain}/basics/v1`;

@Component({
  selector: 'app-data-lsd-build',
  templateUrl: './data-lsd-build.component.html',
  styleUrls: ['./data-lsd-build.component.scss']
})
export class DataLsdBuildComponent implements OnInit {
  window = window;

  company: string;

  // logo
  logoData: any = {};
  // 日期时间
  datetime = '';
  // 是否全屏
  isFullscreen = false;
  // 通知公告
  systemNotice = [];

  zyType = 'LEC';
  fzgsType = 'zdfx';
  systemNotice_carousel_autoPlay = true;
  fx_carousel_autoPlay = true;
  yh_carousel_autoPlay = true;
  fxgs_carousel_autoPlay = true;

  // 4个统计值
  tjCountObj: any = {};

  // 隐患统计
  yhCountObj: any = {};

  /**
   * echartsOption
   */
  // 风险统计
  fxEchartsOption = null;
  // 风险区域统计
  fxqyEchartsOption = null;
  // 风险按责任单位统计
  fxzrdwEchartsOption = null;
  // 作业风险值对比图
  zyLSEchartsOption = null;
  zyLECEchartsOption = null;
  zyMESEchartsOption = null;
  // 隐患统计
  yhEchartsOption = null;
  // 隐患区域统计
  yhqyEchartsOption = null;
  // 隐患按责任单位统计
  yhzrdwEchartsOption = null;
  // 近一年月度隐患变化趋势
  yhqsEchartsOption = null;

  // 重大风险公示
  fxgsList = [];
  // 重大隐患公示
  yhgsList = [];
  // 危险作业公示
  wxzyList = [];

  constructor(public sanitizer: DomSanitizer, private message: MessageService, private http: _HttpClient, private eleMentRef: ElementRef,
    private commonService: CommonService, private router: Router,
    private route: ActivatedRoute,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService) { }

  ngOnInit() {
    this.load_screen(); // 
    this.setDateTime(); // 
    this.load_systemNotice(); //

    this.load_fxEchartsOption();  //
    this.load_fxqyEchartsOption();  //
    this.load_fxzrdwEchartsOption(); //
    this.load_zyEchartsOption('1'); //LS 
    this.load_zyEchartsOption('2'); //LEC 
    this.load_zyEchartsOption('3'); //MES 

    this.load_tjCountObj(); // 
    this.load_fxgs('zdfx'); //
    this.load_fxgs('zdyh'); //
    this.load_fxgs('wxzy'); // *

    this.load_yhEchartsOption();  //
    this.load_yhqyEchartsOption();  //
    this.load_yhzrdwEchartsOption();  //
    this.load_yhqsEchartsOption();  //

    //添加 轮播图 播放暂停 事件
    setTimeout(() => {
      Array.from(this.eleMentRef.nativeElement.querySelectorAll('[autoPlay_field]')).forEach((carousel: HTMLElement) => {
        let autoPlay_field = carousel.getAttribute('autoPlay_field');
        carousel.addEventListener('mouseenter', (e) => {
          this[autoPlay_field] = false;
        });
        carousel.addEventListener('mouseleave', (e) => {
          this[autoPlay_field] = true;
        });
      });
    }, 0);
  }

  //全屏切换
  onSwitchFullscreen() {
    //进入全屏
    const requestFullScreen = () => {
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de['mozRequestFullScreen']) {
        de['mozRequestFullScreen']();
      } else if (de['webkitRequestFullScreen']) {
        de['webkitRequestFullScreen']();
      }
    };
    //退出全屏
    const exitFullscreen = () => {
      var de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de['mozCancelFullScreen']) {
        de['mozCancelFullScreen']();
      } else if (de['webkitCancelFullScreen']) {
        de['webkitCancelFullScreen']();
      }
    };

    if (this.isFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }

  /**
   * 退出登录
   */
  logout() {
    this.http.delete(`${baseUrl}/auth/token/logout`).subscribe(res => {
      this.tokenService.clear();
      this.commonService.loginBeforeUrl = window.location.hash.slice(1);
      if (this.company == 'mt1d') {
        // 如果是煤田一队进入， 退出返回到煤田一队登录页
        this.router.navigateByUrl('/demo/data-lsd-login');
      } else {
        this.router.navigateByUrl(this.tokenService.login_url);
      }
      this.message.success("退出成功！");
    });
  }

  //设置日期时间
  setDateTime() {
    let dayMap = {
      0: '星期日',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
    };
    this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    setInterval(() => {
      this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    }, 1000);
  }

  //获取请求参数
  getParams() {
    return {
      access_token: JSON.parse(window.localStorage.getItem('_token') || '{}').token
    };
  }

  //加载 logo
  load_screen() {
    let params = this.getParams();
    this.http.get<any>(`${basicsUrl}/companys/screen`, params).subscribe(res => {
      if (res.code == 0) {
        this.logoData = res.data || {};

        // this.logoData.companyLogo = 'https://s2.ax1x.com/2019/08/05/e2H3cR.jpg';

        if (this.logoData.companyLogo) {
          this.logoData.companyLogo = this.sanitizer.bypassSecurityTrustStyle('url(' + this.logoData.companyLogo + ')')
        }
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 通知公告
  load_systemNotice() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/systemNotice`, params).subscribe(res => {
      if (res.code == 0) {
        this.systemNotice = res.data.notice || [];
        this.systemNotice = this.systemNotice.slice(0, 1);
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //------------------------------------------------------------

  // 加载 风险统计
  load_fxEchartsOption() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/risk/statistics/risknum`).subscribe(res => {
      if (res.code == 0) {
        res.data = [
          { value: res.data.oneRiskCount || 0, name: '重大风险' },
          { value: res.data.twoRiskCount || 0, name: '较大风险' },
          { value: res.data.threeRiskCount || 0, name: '一般风险' },
          { value: res.data.fourRiskCount || 0, name: '低风险' },
        ];

        // res.data = [
        //   { value: 111 || 0, name: '重大风险' },
        //   { value:  0, name: '较大风险' },
        //   { value:  0, name: '一般风险' },
        //   { value: 0, name: '低风险' },
        // ];

        let dataMap = {};
        res.data.forEach(item => dataMap[item.name] = item.value);

        let arr = res.data || [];
        this.fxEchartsOption = {
          legend: {
            orient: 'vertical',
            left: 0,
            bottom: 0,
            data: [
              {
                name: '重大风险',
                textStyle: { color: '#baefff' },
              },
              {
                name: '较大风险',
                textStyle: { color: '#baefff' },
              },
              {
                name: '一般风险',
                textStyle: { color: '#baefff' },
              },
              {
                name: '低风险',
                textStyle: { color: '#baefff' },
              },

            ],
            formatter: function (name) {
              return `${name}: ${dataMap[name]}`
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: "{b} : {c} ({d}%)",
            confine: true,
          },
          color: Object.values(this.commonService.color),
          series: [
            {
              type: 'pie',
              radius: '40%',
              data: arr,
              label: {
                formatter: '{b}\n{c} ({d}%)'
              }
            }
          ]
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 风险区域统计
  load_fxqyEchartsOption() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/checkItemLocationStatistics`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'groupName': '区域名称',
        //     'greatRiskCount|0-100': 1,
        //     'relativeRiskCount|0-100': 1,
        //     'generalRiskCount|0-100': 1,
        //     'lowRiskCount|0-100': 1,
        //   }]
        // });

        var riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        let arr = res.data.riskLocationStatistics || [];

        this.fxqyEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 风险按责任单位统计
  load_fxzrdwEchartsOption() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/checkItemDepartmentStatistics`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'groupName': '区域名称',
        //     'greatRiskCount|0-100': 1,
        //     'relativeRiskCount|0-100': 1,
        //     'generalRiskCount|0-100': 1,
        //     'lowRiskCount|0-100': 1,
        //   }]
        // });

        var riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        let arr = res.data.riskDepartmentStatistics || [];

        this.fxzrdwEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 作业风险值对比图
  load_zyEchartsOption(type) {
    let params = { ...this.getParams(), type };
    this.http.get<any>(`${hazardUrl}/companyscreens/building/activityRisk`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'groupName': '区域名称',
        //     'count|0-100': 1,
        //   }]
        // });

        let echartsOptionMap = {
          '1': 'zyLSEchartsOption',  //LS
          '2': 'zyLECEchartsOption',  //LEC
          '3': 'zyMESEchartsOption',  //MES
        };

        // var riskTypes = [
        //   { name: '重大风险', field: 'greatRiskCount' },
        //   { name: '较大风险', field: 'relativeRiskCount' },
        //   { name: '一般风险', field: 'generalRiskCount' },
        //   { name: '低风险', field: 'lowRiskCount' },
        // ];

        let arr = res.data || [];
        //data: [{ value: 55 }, { value: 88, itemStyle:{ color: '#000' } }],

        this[echartsOptionMap[type]] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.typeName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          // color: Object.values(this.commonService.color),
          // series: riskTypes.map(type => ({
          //   data: arr.map(v => v[type.field] || 0),
          //   name: type.name,
          //   type: 'bar',
          //   barWidth: '5',
          // }))
          series: [{
            data: arr.map(v => v.valueTotal || 0),
            type: 'bar',
            barWidth: '5',
          }]
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 4个统计值
  load_tjCountObj() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/building/number`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data': {
        //     'count|0-100': 1,
        //   }
        // });

        this.tjCountObj = res.data || {};

      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 重大风险公示
  load_fxgs(fzgsType) {
    let fieldMap = {
      'zdfx': 'fxgsList',
      'zdyh': 'yhgsList',
      'wxzy': 'wxzyList',
    };

    let urlMap = {
      'zdfx': hazardUrl + '/companyscreens/majorNotification',
      'zdyh': hazardUrl + '/companyscreens/majorHiddenDangerPublicity',
      'wxzy': domain + '/jobticket/hazardous-operation-notice',
    };

    let params = this.getParams();
    this.http.get<any>(`${urlMap[fzgsType]}`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'aaaa': '区域名称区域名称区域名称',
        //   }]
        // });
        // let arr = res.data || [];

        let arr = [];
        switch (fzgsType) {
          case 'zdfx': {
            arr = res.data.riskNotification || [];
            break;
          }
          case 'zdyh': {
            arr = res.data.hiddenDangerNotification || [];
            break;
          }
          case 'wxzy': {
            arr = res.data || [];
            break;
          }
        }

        this[fieldMap[fzgsType]] = arr
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 隐患统计
  load_yhEchartsOption() {
    let params = this.getParams();
    let a = this.http.get<any>(`${hazardUrl}/companyscreens/building/number`, params).toPromise();
    let b = this.http.get<any>(`${hazardUrl}/companyscreens`, params).toPromise();
    Promise.all([a, b]).then(([aRes, bRes]) => {
      if (aRes.code == 0 && bRes.code == 0) {
        let obj = {
          yh: [
            { value: (aRes.data.majorHiddenDangersNotRectified + aRes.data.majorHiddenDangersRectified) || 0, itemStyle: { color: this.commonService.hiddencolor.zd }, name: '重大隐患' },
            { value: (aRes.data.generalHiddenDangersNotRectified + aRes.data.generalHiddenDangersRectified) || 0, itemStyle: { color: this.commonService.hiddencolor.yb }, name: '一般隐患' },
          ],
          zg: [
            { value: aRes.data.majorHiddenDangersNotRectified || 0, itemStyle: { color: this.commonService.color.zd }, name: '未整改重大隐患' },
            { value: aRes.data.generalHiddenDangersNotRectified || 0, itemStyle: { color: this.commonService.color.yb }, name: '未整改一般隐患' },
            { value: aRes.data.majorHiddenDangersRectified || 0, itemStyle: { color: this.commonService.color.jd }, name: '已整改重大隐患' },
            { value: aRes.data.generalHiddenDangersRectified || 0, itemStyle: { color: this.commonService.color.di }, name: '已整改一般隐患' },
          ],
        };

        /**
         * 隐患统计
         */
        this.yhCountObj = bRes.data || {};
        // 未闭环隐患
        this.yhCountObj.currentYearNotClosed = aRes.data.currentYearNotClosed;

        let yh = obj.yh || [];
        let zg = obj.zg || [];

        this.yhEchartsOption = {
          // title: {
          //   text: '年度隐患量及整改情况',
          //   left: 'center',
          //   bottom: '0',
          //   textStyle: {
          //     fontSize: 12,
          //     fontWeight: 'normal',
          //     color: '#bbf8ff'
          //   }
          // },
          tooltip: {
            trigger: 'item',
            formatter: "{b} : {c} ({d}%)",
            confine: true,
          },
          series: [
            {
              type: 'pie',
              radius: [0, '25%'],
              label: {
                formatter: '{b}\n{c} ({d}%)',
                normal: {
                  position: 'inner'
                },
                borderWidth: 0,
                borderColor: 'transparent',
                shadowColor: 'transparent',
                shadowBlur: 0,
                textBorderWidth: 0,
                textShadowColor: 'transparent'
              },
              data: yh,
            },
            {
              type: 'pie',
              radius: ['30%', '45%'],
              data: zg,
              label: {
                formatter: '{b}\n{c} ({d}%)'
              }
            }
          ]
        };
      }
    });
  }

  // 加载 隐患按区域统计
  load_yhqyEchartsOption() {
    let params = { ...this.getParams(), classifiedType: 'area' };
    this.http.get<any>(`${hazardUrl}/companyscreens/chartStatistics`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'groupName': '区域名称',
        //     'greatRiskCount|0-100': 1,
        //     'generalRiskCount|0-100': 1,
        //   }]
        // });

        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        let arr = res.data.areaStatistics || [];

        this.yhqyEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 隐患按责任单位统计
  load_yhzrdwEchartsOption() {
    let params = { ...this.getParams(), classifiedType: 'duty' };
    this.http.get<any>(`${hazardUrl}/companyscreens/chartStatistics`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'groupName': '区域名称',
        //     'greatRiskCount|0-100': 1,
        //     'generalRiskCount|0-100': 1,
        //   }]
        // });

        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        let arr = res.data.dutyUnitStatistics || [];

        this.yhzrdwEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 近一年月度隐患变化趋势
  load_yhqsEchartsOption() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/trendStatistics`, params).subscribe(res => {
      if (res.code == 0) {

        // //mock
        // res = Mock.mock({
        //   'data|40': [{
        //     'groupName': '区域名称',
        //     'greatRiskCount|0-100': 1,
        //     'generalRiskCount|0-100': 1,
        //   }]
        // });

        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        let arr = res.data.trendStatistics || [];

        this.yhqsEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.yearAndMonth),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            lineStyle: {
              width: 1
            }
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

}
