import { Component, OnInit } from '@angular/core'; import { MessageService } from '@core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService, NzCollapseModule } from 'ng-zorro-antd';

import { baseUrl } from '@core';

@Component({
  selector: 'app-riskcard-content-v2',
  templateUrl: './riskcard-content-v2.component.html',
  styleUrls: ['./riskcard-content-v2.component.less']
})
export class RiskcardContentV2Component implements OnInit {
  params: any = {};
  basics: any = {};
  items: any = [];
  orglist=[];

  datausccess=true;

  // checkList1= [{isactive: false},{isactive: false},{isactive: false}];
  checkList1= [];
  collDoms:any = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    public msg: NzMessageService,
    private http: _HttpClient,
    private modal: ModalHelper) { }

  async ngOnInit() {
    this.params = this.route.snapshot.params;
    await this.getData();

    this.collDoms = document.getElementsByClassName("collapseset");

  }
  getList(){
     this.http.get(`${baseUrl}/admin/v1/organization/custom?company=`+this.basics.company).subscribe((res:any) => {
      if (res.code == 0) {
       this.orglist= res.data || []
       this.items.forEach(e => {
        if(e.xycsEmOrgLevel){
          e.xycsEmOrgLevelName=this.orglist.filter(item => item.controlLevel==e.xycsEmOrgLevel)[0].customTypeName
        }
      });
      } else {
        // this.msg.error(res.msg || '加载失败');
      }
    });

  }

  closeOpenCol(checkFlags,num,index) {
    for(var i=0;i < checkFlags.length;i++) {
      if((i != index) && checkFlags[i].isactive) {
        checkFlags[i].isactive = false;
        this.collDoms[num].getElementsByClassName("collapse")[i].setAttribute("class","collapse");
        break;
      }
    }
  }
  checkIt(index,disable,num) {
    this.checkCol(index,disable,num);
  }
  checkCol(index,disable,num) {
    if(disable) {
      return;
    }
    let checkFlags;
    checkFlags = this.checkList1;
    if(checkFlags[index].isactive) {
      this.collDoms[num].getElementsByClassName("collapse")[index].setAttribute("class","collapse");
    } else {
      this.collDoms[num].getElementsByClassName("collapse")[index].setAttribute("class","collapse active");
    }
    checkFlags[index].isactive = !checkFlags[index].isactive;
  }
  /**
   * 获取风险卡告知内容
   */
  getData() {
    // this.http.get<any>(`${baseUrl}/hazard/v1/risk/evaluateobjects/cards/${this.params.objectId}`, this.params).subscribe(res => {
    return this.http.get(`${baseUrl}/hazard/v1/risk/evaluateobjects/cards/app`, this.params).toPromise().then((res:any) => {
      if (res.code === 0) {
        this.datausccess=true;
        this.basics = res.data.object || {};
        this.items = res.data.checkItems || [];
        this.items.forEach(e => {
          e.GCJS = (e.xycsGCJS || []).map(item => item.itemValue).join(',');
          e.GL = (e.xycsGL || []).map(item => item.itemValue).join(',');
          e.GTFH = (e.xycsGTFH || []).map(item => item.itemValue).join(',');
          e.JYPX = (e.xycsJYPX || []).map(item => item.itemValue).join(',');
          e.YJCZ = (e.xycsYJCZ || []).map(item => item.itemValue).join(',');
          this.checkList1.push({isactive: false});
          // if(e.xycsEmOrgLevel){
          //   e.xycsEmOrgLevelName=this.orglist.filter(item => item.controlLevel==e.xycsEmOrgLevel)[0].customTypeName
          // }

        });
       this.getList();// 管控层级下拉数据
      } else {
        this.datausccess=false;
        this.msg.error(res.msg);
      }

    })
  }
  ngAfterViewInit() {
    // 监听路由变化
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/riskcard-content-v2')) {
          this.getData();
        }
      }
    });
  }
}
