import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  NgZone
} from '@angular/core'; import { MessageService } from '@core';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyService } from '@delon/util';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { format } from 'date-fns';
import { baseUrl,baseUrlMap,AliyunOssUrl } from '@core';

import * as moment from 'moment';
import { CacheService } from '@delon/cache';
import { vi_VN } from 'ng-zorro-antd';
declare var AMap: any;  //高德地图
declare var $: any;

@Component({
  selector: 'app-amap-show',
  templateUrl: './amap-show.component.html',
  styleUrls: ['./amap-show.component.less']
})
export class AmapShowComponent implements OnInit {

  loading = false;

  @Input() x: number;
  @Input() y: number;
  @Input() isenlarge: string;
  @Input() height: any;
  @Input() issmall: any;
  @Input() isArea: any;
  @Input() isDanger = false; // 是否是重大危险源  true 是 false 否
  // 弹窗上传点击确定 上传参数是 ： pickedRec.getPath()  和 图片 和 楼层标记 floor
  map: any = {};
  amapParams: any = {
    paths: '',
    bounds: {},
    loadPolygon: {},
    imageLayer: {},
    pickedRec: {},
    factoryMenu: {},
    floorId: '',
    belongFloor: '',
    textlocation: '',
    textname: '',
    districtId: '',
    currentPlogy: '',
    exdata: {
      id: '',
      districtId: ''
    },
    factory: null,
    mouseTool: {},
    floores: [{ value: 'F1' }, { value: 'F2' }, { value: 'F3' }],
    hasFactory: null,
    infoWindow: null
  }

  auto: any = {}

  entInfo: any = {};
  dangerInfo: any = {
    total: {},
    area: [],
  };

  outHeight = '0px'

  floorValue: string = 'Z1';
  floorCurrent: number = -1;
  selectCheck: any = null;

  floorList: any = [];

  //逾期未整改隐患列表  对话框
  yqDialog = {
    display: false,
    rows: []
  };

  tenant_id: any = JSON.parse(window.localStorage.getItem('_token') || '{}').tenant_id;

  oldAreaCode: any = new Set()

  sieve: string = '';
  sieveData: any = [{
    name: '风险隐患信息',
    value: '',
  }, {
    name: '应急物资信息',
    value: 1
  }, {
    name: '危险作业信息',
    value: 2
  }];

  companyData: any = this.srv.getNone('companyData') || {};
  constructor(
    private lazy: LazyService,
    private router: Router,
    private http: _HttpClient,
    public msg: MessageService,
    public srv: CacheService,
    private httpClient: HttpClient,
  ) { }


  enlarge() {
    if (this.isArea) {
      this.router.navigateByUrl('/amapfull?isArea=1');
    } else {
      this.router.navigateByUrl('/amapfull');
    }
  }
  ensmall() {
    // 返回到小屏幕
    window.history.go(-1);
  }
  ngOnInit() {
    this.lazy
    .loadStyle('https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css');
    this.lazy
    .loadScript('https://webapi.amap.com/maps?v=1.4.14&key=9b6aa8c00d6ef8ea8fd14d082df78eb4&plugin=AMap.MouseTool,AMap.ControlBar,AMap.RectangleEditor,AMap.Autocomplete')
    .then(() => {
      this.init(this.floorValue);
    });
  }
  init(floorValue) {
    let $that = this;
    $that.map = new AMap.Map('container', {
      center: [116.33719, 39.942384],
      zoom: 10,
      expandZoomRange: true,
      zooms: [3, 20],
      mapStyle: "amap://styles/macaron",
      resizeEnable: true
    });
    AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.MouseTool'], function () {
      var autoOptions = {
        city: "北京", //城市，默认全国
        input: "keyword"//使用联想输入的input的id
      };
      var autocomplete = new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
        city: '北京',
        map: $that.map
      })
      AMap.event.addListener(autocomplete, "select", function (e) {

        placeSearch.setCity(e.poi.adcode);
        placeSearch.search(e.poi.name)
      });

      $that.amapParams.mouseTool = new AMap.MouseTool($that.map)
    });

    $that.map.on("zoomchange", () => {
      var zoomLevel = $that.map.getZoom();
      if (zoomLevel >= 15) {
        this.map.getAllOverlays('text').forEach(marker => {
          marker.show();
        });
        $that.maprange(floorValue);
      } else {
        this.map.getAllOverlays('text').forEach(marker => {
          marker.hide();
        });
      }
    })

    $that.map.on('dragend', function () {
      var zoomLevel = $that.map.getZoom();
      if (zoomLevel >= 15) {
        $that.maprange(floorValue);
      }
    });

    $that.amapParams.belongFloor = floorValue;

    //菜单
    var contextMenu = new AMap.ContextMenu();
    contextMenu.addItem("下载四色图", () => {
      this.download();
    }, 0);


    // 总览数据获取
    $that.getZonglan();



    this.getFloor();

    this.loading = true;
    $that.http.get(`${baseUrl}/basics/v1/floors?floorName=${floorValue}`).subscribe((response: any) => {
      this.loading = false;
      var floorBase = response.data;
      $that.amapParams.hasFactory = response.data;
      if (typeof (floorBase) == 'undefined' || floorBase == null) {
        // 加载地图右键绘制厂区功能
        // $that.paintFactory();
      } else {
        for (var i = 0; i < floorBase.length; i++) {
          $that.amapParams.floorId = floorBase[i].id;

          $that.amapParams.paths = JSON.parse(floorBase[i].bounds);
          var lng = [];
          var lat = [];

          for (var p = 0, len = $that.amapParams.paths.length; p < len; p++) {
            lng.push($that.amapParams.paths[p][0]);
            lat.push($that.amapParams.paths[p][1]);
          }
          lng.sort(function (a, b) {
            return a - b;
          })
          lat.sort(function (a, b) {
            return a - b;
          })
          $that.amapParams.bounds = new AMap.Bounds([lng[0], lat[0]], [lng[lng.length - 1], lat[lat.length - 1]]);
          $that.map.setCenter($that.amapParams.bounds.getCenter());
          $that.amapParams.loadPolygon = new AMap.Polygon({
            strokeColor: 'rgba(0,0,0,0)',
            strokeOpacity: 0.2,
            strokeWeight: 0.8,
            strokeDasharray: [30, 10],
            strokeStyle: 'dashed',
            fillOpacity: 0,
            path: $that.amapParams.paths,
            map: $that.map
          });

          // 图片图层
          $that.amapParams.imageLayer = new AMap.ImageLayer({
            url: floorBase[i].imgUrl,
            zIndex: 100,
            bounds: $that.amapParams.bounds,
            map: $that.map,
            zooms: [3, 20],
            visible: true
          });
          $that.amapParams.imageLayer['districtName'] = floorBase[i].districtName;

          // 图片图层覆盖物(大小相等, 用于响应右键菜单事件)
          var rectangleBg = new AMap.Rectangle({
            bounds: $that.amapParams.bounds,
            strokeOpacity: 0,
            fillOpacity: 0,
            extData: $that.amapParams.imageLayer,
            bubble: true,
          });
          $that.map.add(rectangleBg);

          //在 图片图层覆盖物 打开右键菜单
          rectangleBg.on('rightclick', function (e) {
            //保存 当前图片图层
            window['currentImageLayer'] = e.target.getExtData();
            contextMenu.open($that.map, e.lnglat);
          });

          var marker = new AMap.Marker({
            size: new AMap.Size(25, 34),
            position: $that.amapParams.paths[0],
            direction: 'center',
            offset: new AMap.Pixel(-13, -30),
            imageSize: new AMap.Size(25, 34),
          });
          marker.on("click", function (e) {
            $that.map.setZoomAndCenter(16, e.lnglat);
          });
          marker.setIcon('./assets/poi-marker-red.png')
          marker.setTitle(floorBase[i].districtName);
          marker.setMap($that.map);
          var floorObj = { img: $that.amapParams.imageLayer, id: floorBase[i].id };
          $that.amapParams.loadPolygon.setExtData(floorObj);
        }
        $that.map.setFitView();
      }
    })

    $('.ent-situation').on('mouseover', function (e) {
      var pos = $that.mousePosition(e);
      $('#ent-info').css({
        'display': 'block'
      })
      $('.content-window-card').css({
        'top': pos.y + 20,
        'left': pos.x - 480 + 10
      })
    })
    $('.ent-situation').on('mouseout', function (e) {
      $('#ent-info').css({
        'display': 'none'
      })
    })
  }

  maprange(floorValue) {
    let $that = this;
    let lnglat = $that.map.getCenter();
    $that.http.get(`${baseUrl}/basics/v1/floors/code`, {
      floor: floorValue,
      longitude: lnglat.lng,
      latitude: lnglat.lat
    }).subscribe((res: any) => {
      $that.getArea(res.data);

    })
  }

  getArea(codes) {
    let $that = this;
    let areaCodes = new Set(codes);
    areaCodes.forEach(x => {
      if ($that.oldAreaCode.has(x)) {
        areaCodes.delete(x)
      } else {
        $that.oldAreaCode.add(x)
      }
    })
    // codes 返回厂区 code  []  一个code代表一个厂区
    codes = Array.from(areaCodes);
    if (codes.length > 0) {
      $that.http.get(`${baseUrl}/basics/v1/district/details/overlays?floorName=${$that.amapParams.belongFloor}`).subscribe((response: any) => {
        var on = response.data;
        if (on.count <= 0) {
          // 无覆盖物信息
        } else {
          // 加载覆盖物信息 同时渲染颜色--添加右键删除功能
          var overlayList = on.overlayList;
          codes.forEach(code => {
            $that.reqOss(overlayList, code);
          })
          // if ($that.isArea) {
          //   $that.getFacilityArea(overlayList, $that)
          // } else {
          //   if ($that.sieve == '') {
          //     codes.forEach(code => {
          //       $that.reqOss(overlayList, $that, code);
          //     })
          //   } else if ($that.sieve == '1') {
          //     $that.getSuppliesArea(overlayList, $that);
          //   } else if ($that.sieve == '2') {
          //     $that.getJobticketArea(overlayList, $that);
          //   }
          // }

        }
      })
    }
  }
  reqOss(overlayList, code) {
    // this.http.get(`${baseUrl}/basics/v1/file/url`, {
    //   fileName: `${this.tenant_id}/${this.floorValue}/${code}/color-map.json`
    // }).subscribe((res: any) => {
    //   if (res.code == 0) {
    //     this.getAreaColor(res, overlayList, code);
    //   }
    // })
    this.getAreaColor(overlayList, code);
  }
  /*
   *  服务端请求 area color
   *  如果color.map.json 调用失败 使用此接口
  */

  reqServer(overlayList, code) {
 
    this.httpClient.post(`${baseUrl}/hazard/v1/risk/home/floor/color`, { floorId: code, floorName: this.floorValue }).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 0) {
        // this.renderColor(res, overlayList, res, 'area'); 
        if (this.isArea) {
          this.getFacilityArea(res, overlayList);
        } else {
          if (this.sieve == '') {
            this.renderColor(res, overlayList, res, 'area');
          } else if (this.sieve == '1') {
            this.getSuppliesArea(res, overlayList);
          } else if (this.sieve == '2') {
            this.getJobticketArea(res, overlayList);
          }
        }
      }
    })
  }

  // oss 返回 fileUrl 地址，请求area color
  getAreaColor(overlayList, code) {
    const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
    this.httpClient.get(`${AliyunOssUrl}/${this.tenant_id}/${this.floorValue}/${code}/color-map.json`,{ headers: headers}).subscribe((res: any) => {
      this.loading = false;
      if (res.code == 100) {
        this.reqServer(overlayList, code);
        return;
      }
      let ossColor = {
        data: res
      };
      if (this.isArea) {
        this.getFacilityArea(ossColor, overlayList); // TODO
      } else if (this.isDanger) {
        this.getDangerArea(ossColor, overlayList); // TODO
      } else {
        if (this.sieve == '') {
          this.renderColor(ossColor, overlayList, ossColor, 'area');
        } else if (this.sieve == '1') {
          this.getSuppliesArea(ossColor, overlayList);
        } else if (this.sieve == '2') {
          this.getJobticketArea(ossColor, overlayList);
        }
      }
    })
  }

  // 获取设备设施 区域位置统计
  getFacilityArea(ossColor, overlayList) {
    this.http.get(`${baseUrl}/ledger/v1/equipment/statistics/area?floorName=${this.amapParams.belongFloor}`).subscribe((res: any) => {
      if (res.code == 0) {
        let facilityObj = {};
        for (let key in res.data) {
          let item = res.data[key];
          facilityObj[key] = {
            ordinary: item['普通设备'] ? item['普通设备'][0].count : 0,
            special: item['特种设备'] ? item['特种设备'][0].count : 0,
            color: 4
          }
        }
        let areaData = {
          data: facilityObj
        };
        this.renderColor(ossColor, overlayList, areaData, 'facility');
      }
    })
  }

  // 获取应急物资统计
  getSuppliesArea(ossColor, overlayList) {
    this.http.get(`${baseUrl}/ledger/v1/statistics/supplies?floorName=${this.amapParams.belongFloor}`,).subscribe((res: any) => {
      if (res.code == 0) {
        let suppliesArea = res.data;
        for (let key in suppliesArea) {
          suppliesArea[key].color = suppliesArea[key][0].color;
        }
        let areaData = {
          data: suppliesArea
        };
        this.renderColor(ossColor, overlayList, areaData, 'supplies');
      }
    })
  }

  // 获取应急物资统计
  getJobticketArea(ossColor, overlayList) {
    this.http.get(`${baseUrl}/jobticket/v1/job-statistics/dangerCount?floorName=${this.amapParams.belongFloor}`).subscribe((res: any) => {
      if (res.code == 0) {
        let Jobticket = res.data;
        let JobticketObj = {};
        Jobticket.forEach(item => {
          JobticketObj[item.areaCode] = item.dangerJobList;
          JobticketObj[item.areaCode].color = item.color;
        });

        let areaData = {
          data: JobticketObj
        };
        this.renderColor(ossColor, overlayList, areaData, 'jobticket');
      }
    })
  }

  // 获取重大危险源统计
  getDangerArea(ossColor, overlayList) {

    this.http.get(`${baseUrl}/chemical/v1/sourcedanger/decomposition/page/distribution?floorName=${this.amapParams.belongFloor}`).subscribe((res: any) => {
      if (res.code == 0) {
        let danger = res.data['area'];
        let dangerObj = {};
        danger.forEach((item) => {
          dangerObj[item.storeLocationCode] = item;
          dangerObj[item.storeLocationCode].color = Number(item.exposedMajorSourceGrade);
        });

        let areaData = {
          data: dangerObj
        };

        this.renderColor(ossColor, overlayList, areaData, 'danger');
      }
    })
  }

  // 渲染 area color
  renderColor(res, overlayList, dataSource, type) {
    delete res.data[0];
    Object.keys(res.data).forEach(code => {
      for (let i = 0; i < overlayList.length; i++) {
        let lay = overlayList[i];
        let floorType = lay.code;
        if (this.amapParams.belongFloor !== 'Z1') {
          floorType = lay.districtId;
        }

        let color = null;
        if (this.isDanger) {
          color = dataSource.data[lay.code] ? dataSource.data[lay.code]['color'] + 1 : null;
        } else {
          color = res.data[code].color;
        }

        if ((lay.type == "polygon" || lay.type == "point") && (code == floorType)) {

          this.loadOverlay(lay, this.getColor(color), res.data[code], code, dataSource.data[lay.code], type);
        } else if (lay.type == "circle" && (code == floorType)) {
          this.loadOverlayCircle(lay, this.getColor(color), res.data[code], dataSource.data[lay.code], type);
        } else {
          // console.info("不支持的覆盖物类型！" + lay.type);
        }


      }
    });
  }

  getColor(num) {
    // let colors = this.isDanger ? ['#52C41A', '#999999', '#FFFF00', '#0099FF'] : ['#FF0000', '#FF8800', '#FFFF00', '#5abaff'];
    let colors = ['#FF0000', '#FF8800', '#FFFF00', '#5abaff'];
    if (num == null && this.isDanger) {
      return '#5abaff';
    }
   
    return colors[num - 1]
  }

  getZonglan() {
    if (this.isDanger) {
      this.http.get(`${baseUrl}/chemical/v1/sourcedanger/decomposition/page/distribution`).subscribe((res: any) => {
        if (res.code == 0) {
          this.dangerInfo = res.data;
        }
      })
    } else {
      this.http.get(`${baseUrl}/hazard/v1/risk/home/company/overview`).subscribe((res: any) => {
        if (res.code == 0) {
          this.entInfo = res.data;
        }
      })
    }
  }

  /**
   * 添加覆盖物 标签
   * @param LngLat 
   * @param name 
   */
  addOverlayText(lngLat, name) {
    var text = new AMap.Text({
      position: lngLat,
      text: name,
      style: {
        'font-size': '12px',
        'background': '#000000bf',
        'color': '#fff',
        'border': '0',
        'line-height': '1',
        'padding': '3px',
      },
      visible: false
    });
    this.map.add(text);
  }


  addMarkerCreate() {

    var markerContent = document.createElement("div");
    markerContent.className = "markerContentClass"

    markerContent.style.position = 'relative';
    markerContent.style.fontWeight = '400';
    markerContent.style.color = '#000';
    markerContent.style.width = '25px';
    markerContent.style.height = '34px';

    var markerImg = document.createElement("img");
    markerImg.className = "markerlnglat";
    markerImg.src = '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png';

    markerImg.style.width = '25px';
    markerImg.style.height = '34px';
    markerImg.style.position = 'absolute';
    markerImg.style.zIndex = '-1';
    markerImg.style.top = '0';
    markerImg.style.left = '0';

    return {
      markerContent,
      markerImg
    }
  }

  addMarker(over, name, data, code) {
    let $that = this;

    let bigDangerNum = data.greatCount;
    let dangerNum = data.generalCount;
    let notClosedGreatPotentialCount = data.notClosedGreatPotentialCount


    var marker = new AMap.Marker({
      size: new AMap.Size(40, 50),
      position: over.getPosition(),
      direction: 'center',
      offset: new AMap.Pixel(-13, -30),
      imageSize: new AMap.Size(40, 50),
      zIndex: 101
    });

    marker.on("click", (e) => {
      //逾期未整改隐患列表
      // if (data.yearUnreformCount > 0) {
      this.loading = true;
      this.http.get<any>(`${baseUrl}/hazard/v1/risk/home/overdue/notReCheck`, { potentialLocationCode: code, page: 1, limit: 10000, floorName: this.floorValue }).subscribe(res => {
        this.loading = false;
        if (res.code == 0) {

          this.yqDialog.rows = res.data.content || [];
          if (this.yqDialog.rows.length) {
            this.yqDialog.rows.forEach(item => {
              if (item.potentialStatus === 6 && (item.reformEndDate < format(new Date(), 'YYYY-MM-DD HH:mm:ss'))) {
                item.displayRed = true;
              }
            })
            this.yqDialog.display = true;
          } else {
            this.msg.info('该区域未闭环隐患列表 为空');
          }
        }
      });
      // }
    });

    // 请求重大隐患 、一般隐患 隐患点的数量
    var dangerTotal = bigDangerNum + dangerNum;

    let { markerContent, markerImg } = this.addMarkerCreate();


    if (notClosedGreatPotentialCount > 0) {  // 橙色
      markerImg.src = './assets/poi-marker-default.png';
    }
    if (dangerTotal > 0) {
      let markerImgspan = document.createElement("div");
      // markerImgspan.innerHTML = dangerTotal;
      markerImgspan.innerHTML = data.notClosedPotentialCount;
      markerImgspan.className = "markerImgspan";
      markerImgspan.style.textAlign = 'center';
      markerContent.appendChild(markerImgspan);
      markerContent.appendChild(markerImg);
    }

    // 点标记中的文本
    marker.setContent(markerContent); //更新点标记内容
    $that.map.add(marker);

    //闪烁
    if (data.yearUnreformCount > 0) {
      marker.on('mouseover', () => marker._hasOver = true);
      marker.on('mouseout', () => marker._hasOver = false);

      setInterval(() => {
        if (!marker._hasOver) {
          if (marker._isVisible) {
            marker.hide();
          } else {
            !this.loading && marker.show();
          }
          marker._isVisible = !marker._isVisible;
        }
      }, 500);
    }
  }

  addMarkerSupplies(over, name, data, code) {

    var marker = new AMap.Marker({
      size: new AMap.Size(40, 50),
      position: over.getPosition(),
      direction: 'center',
      offset: new AMap.Pixel(-13, -30),
      imageSize: new AMap.Size(40, 50),
      zIndex: 101
    });

    let { markerContent, markerImg } = this.addMarkerCreate();
    markerImg.src = './assets/poi-marker-supplies.png';
    markerContent.appendChild(markerImg);

    // 点标记中的文本
    marker.setContent(markerContent); //更新点标记内容
    this.map.add(marker);

    marker.on('mouseover', (e) => {
      // 应急物资信息
      this.openInfoWindowSupplies(e, data, name);

    });
    marker.on('mouseout', () => {
      this.map.clearInfoWindow();
    });

  }

  addMarkerJobticket(over, name, data, code) {
    var marker = null;

    for (let i = 0; i < data.length; i++) {
      var p = over.getPosition();
      p = [(p.lng * 1) - (i * 0.00005), p.lat];

      marker = new AMap.Marker({
        size: new AMap.Size(40, 50),
        position: p,
        direction: 'center',
        offset: new AMap.Pixel(-13, -30),
        imageSize: new AMap.Size(40, 50),
        zIndex: 101
      });

      let { markerContent, markerImg } = this.addMarkerCreate();
      markerImg.src = './assets/poi-marker-jobticket.png';
      markerContent.appendChild(markerImg);
      marker.extData = { 'data': data[i] };//自定义想传入的参数
      // 点标记中的文本
      marker.setContent(markerContent); //更新点标记内容
      this.map.add(marker);

      marker.on('mouseover', (e) => {
        // 
        this.openInfoWindowJobticket(e, e.target.extData.data, name);

      });
      marker.on('mouseout', () => {
        this.map.clearInfoWindow();
      });
    }



  }

  // 打开信息窗体
  addDangerJobticket(e, res, name) {
    var info = [];
    info.push("<div class='input-card content-window-card' style=' width: 380px;height:200px;top:" + parseInt(e.pixel.y + this.y) + "px; left:" + parseInt(e.pixel.x + this.x) + "px;'><div style=' '><h4 style='color:blue '>" + name + "重大危险源情况</h4></div> ");
    let oneLength = 0, twoLength = 0, threeLength = 0, fourLength = 0;
    res.forEach(item => {
      if (item.exposedMajorSourceGrade === '一级') {
        oneLength++;
      } else if (item.exposedMajorSourceGrade === '二级') {
        twoLength++;
      } else if (item.exposedMajorSourceGrade === '三级') {
        threeLength++;
      } else if (item.exposedMajorSourceGrade === '四级') {
        fourLength++;
      }
    })
    info.push("<p class='input-item'>一级重大危险源数量：" + oneLength + "</p>");
    info.push("<p class='input-item'>二级重大危险源数量：" + twoLength + "</p>");
    info.push("<p class='input-item'>三级重大危险源数量：" + threeLength + "</p>");
    info.push("<p class='input-item'>四级重大危险源数量：" + fourLength + "</p></div>");

    this.amapParams.infoWindow = new AMap.InfoWindow({
      isCustom: true,  //使用自定义窗体
      content: info.join(""),
      autoMove: true,
      anchor: "middle-left"
    });
    this.amapParams.infoWindow.open(this.map, e.lnglat);
  }


  // 打开信息窗体
  openInfoWindow(e, res, name) {
    var info = [];
    info.push("<div class='input-card content-window-card' style='display:block; width: 480px; height:230px; top:" + parseInt(e.pixel.y + this.y) + "px; left:" + parseInt(e.pixel.x + this.x) + "px;'><div style=' '><h4 style='color:blue '>" + name + "总体概况</h4></div> ");
    info.push("<div style=\"padding:7px 0px 0px 0px; width:33.3%;float:left;\"><h5>风险总数：" + res.yearRiskTotalCount + "</h5>");
    info.push("<p class='input-item'>重大风险：" + res.yearGreatRiskCount + "</p>");
    info.push("<p class='input-item'>较大风险：" + res.yearRelativeRiskCount + "</p>");
    info.push("<p class='input-item'>一般风险：" + res.yearGeneralRiskCount + "</p>");
    info.push("<p class='input-item'>低风险：" + res.yearLowRiskCount + "</p></div>");

    info.push("<div style=\"padding:7px 0px 0px 0px;width:33.3%;float:left;\"><h5>风险点总数：" + res.riskPointCount + "</h5>");
    info.push("<p class='input-item'>红色风险点：" + res.redRiskPointCount + "</p>");
    info.push("<p class='input-item'>橙色风险点：" + res.orangeRiskPointCount + "</p>");
    info.push("<p class='input-item'>黄色风险点：" + res.yellowRiskPointCount + "</p>");
    info.push("<p class='input-item'>蓝色风险点：" + res.blueRiskPointCount + "</p></div>");

    info.push("<div style=\"padding:7px 0px 0px 0px;width:33.3%;float:left;\"><h5>本年隐患总数：" + res.yearPotentialTotalCount + "</h5>");
    info.push("<p class='input-item'>本年重大隐患：" + res.yearGreatCount + "</p>");
    info.push("<p class='input-item'>当前未闭环隐患：" + res.notClosedPotentialCount + "</p>");
    info.push("<p class='input-item'>隐患整改率：" + res.yearReformCountPercent + "%</p>");
    info.push("<p class='input-item'>本月新增隐患：" + res.monthAddCount + "</p></div>");
    this.amapParams.infoWindow = new AMap.InfoWindow({
      isCustom: true,  //使用自定义窗体
      content: info.join(""),
      autoMove: true,
      anchor: "middle-left"
    });
    this.amapParams.infoWindow.open(this.map, e.lnglat);
  }

  // 打开设备设施
  openInfoWindowFacility(e, res, name) {
    var info = [];
    info.push("<div class='input-card content-window-card' style='display:block; width: 280px; height:auto; bottom:auto; top:" + parseInt(e.pixel.y + this.y) + "px; left:" + parseInt(e.pixel.x + this.x) + "px;'><div style=' '><h4 style='color:blue '>" + name + "总体概况</h4></div> ");
    info.push("<div style=\"padding:7px 0px 0px 0px;\"><h5>设备总数：" + parseInt(res.ordinary + res.special) + "</h5>");
    info.push("<p class='input-item'>普通设备：" + res.ordinary + "</p>");
    info.push("<p class='input-item'>特种设备：" + res.special + "</p>");
    info.push("</div>");
    this.amapParams.infoWindow = new AMap.InfoWindow({
      isCustom: true,  //使用自定义窗体
      content: info.join(""),
      autoMove: true,
      anchor: "middle-left"
    });
    this.amapParams.infoWindow.open(this.map, e.lnglat);
  }

  // 打开应急物资
  openInfoWindowSupplies(e, res, name) {
    var info = [];
    info.push("<div class='input-card content-window-card' style='display:block; width: 280px; height:auto; bottom:auto; top:" + parseInt(e.pixel.y + this.y) + "px; left:" + parseInt(e.pixel.x + this.x) + "px;'><div style=' '><h4 style='color:blue '>" + name + "应急物资</h4></div> ");
    info.push("<div style=\"padding:7px 0px 0px 0px;\">");

    for (var i = 0; i < res.length; i++) {
      if (res[i].suppliesName && res[i].count) {
        info.push("<p class='input-item'>" + res[i].suppliesName + "：" + res[i].count + "</p>");
      }
    }

    info.push("</div>");
    this.amapParams.infoWindow = new AMap.InfoWindow({
      isCustom: true,  //使用自定义窗体
      content: info.join(""),
      autoMove: true,
      anchor: "middle-left"
    });
    this.amapParams.infoWindow.open(this.map, e.lnglat);
  }

  // 打开作业票信息
  openInfoWindowJobticket(e, res, name) {
    var info = [];
    info.push("<div class='input-card content-window-card' style='display:block; width: 380px; height:auto; bottom:auto; top:" + parseInt(e.pixel.y + this.y) + "px; left:" + parseInt(e.pixel.x + this.x) + "px;'><div style=' '><h4 style='color:blue '>" + name + "</h4></div> ");
    info.push("<div style=\"padding:7px 0px 0px 0px;\">");

    info.push("<p class='input-item'>作业票名称：" + res.jobName + "</p>");
    info.push("<p class='input-item'>作业时间：" + res.planStartTime + " - " + res.planEndTime + "</p>");
    info.push("<p class='input-item'>作业地点：" + res.jobAreaName + "</p>");
    info.push("<p class='input-item'>监护人：" + res.guardianName + "</p>");
    info.push("<p class='input-item'>作业负责人：" + res.principalName + "</p>");
    info.push("<p class='input-item lines'>负责人联系电话：" + res.principalPhone + "</p>");
    info.push("</div>");
    this.amapParams.infoWindow = new AMap.InfoWindow({
      isCustom: true,  //使用自定义窗体
      content: info.join(""),
      autoMove: true,
      anchor: "middle-left"
    });
    this.amapParams.infoWindow.open(this.map, e.lnglat);
  }



  mousePosition(ev) {
    if (ev.pageX || ev.pageY) {
      return { x: ev.pageX, y: ev.pageY };
    }
    return {
      x: ev.clientX + document.body.scrollLeft - document.body.clientLeft,
      y: ev.clientY + document.body.scrollTop - document.body.clientTop
    };
  }
  loadOverlayCircle(lay, color, res, dataSource, type) {
    var $that = this;
    var over = JSON.parse(lay.overlay)
    var circle = new AMap.Circle({
      center: new AMap.LngLat(over.center.lng, over.center.lat),
      radius: over.radius, //半径
      strokeColor: 'black',
      strokeOpacity: 0,
      strokeWeight: 0,
      strokeStyle: 'dashed',
      strokeDasharray: [10, 10],
      // 线样式还支持 'dashed'
      fillColor: color,
      zIndex: 50,
    })

    let dataList = [];
    if (dataSource) {
      this.http.get(`${baseUrl}/chemical/v1/sourcedanger/decomposition/page/distribution/list`, { 'storeLocationCode': dataSource.storeLocationCode }).subscribe((res: any) => {
        if (res.code === 0) {
          dataList[dataSource.storeLocationCode] = res.data.content;
        }
      })
    }

    circle.on("mouseover", function (e) {
      if ($that.isArea) {
        $that.openInfoWindowFacility(e, res, lay.name);
      } else if ($that.isDanger) {
        if (dataSource) {
          $that.addDangerJobticket(e, dataList[dataSource['storeLocationCode']], lay.name);
        }

      } else {
        if ($that.sieve == '') {
          $that.openInfoWindow(e, res, lay.name);
        }
      }
    });
    circle.on("mouseout", function (e) {
      $that.map.clearInfoWindow();
    });
    circle.setExtData({ id: lay.id, districtId: lay.districtId });
    circle.setMap(this.map);
  }

  loadOverlay(lay, color, res, code, dataSource, type) {
    var $that = this; // this指针
    var gge = new AMap.GeoJSON();
    var ovobj = JSON.parse(lay.overlay);
    gge.importData(ovobj);
    var over = null;
    over = gge.getOverlays()[0].getOverlays()[0];
    over.setExtData({ id: lay.id, districtId: lay.districtId });

    if (over.CLASS_NAME == "AMap.Polygon" || over.CLASS_NAME == "AMap.Rectangle") {
      over.setOptions({
        fillColor: color,
        bubble: true,
        strokeColor: 'transparent',
        strokeWeight: 0,
      })

      let dataList = [];

      if (dataSource) {
        this.http.get(`${baseUrl}/chemical/v1/sourcedanger/decomposition/page/distribution/list`, { 'storeLocationCode': dataSource.storeLocationCode }).subscribe((res: any) => {
          if (res.code === 0) {
            dataList[dataSource.storeLocationCode] = res.data.content;
          }
        })
      }


      // 鼠标滑过加载信息窗体事件
      over.on("mouseover", function (e) {
        if ($that.isArea) {
          $that.openInfoWindowFacility(e, dataSource, lay.name);
        } else if ($that.isDanger) {
          if (dataSource) {
            $that.addDangerJobticket(e, dataList[dataSource['storeLocationCode']], lay.name);
          }
        } else {
          if ($that.sieve == '') {
            $that.openInfoWindow(e, res, lay.name);
          }
        }
      });
      over.on("mouseout", function (e) {
        $that.map.clearInfoWindow();
      });
      this.map.add(over);

    } else if (over.CLASS_NAME == "AMap.Marker") {
      this.addOverlayText(over.getPosition(), lay.name);
      if (type === 'area') {
        if (res.notClosedPotentialCount && res.notClosedPotentialCount !== 0) {
          $that.addMarker(over, lay.name, res, code);
        }
      } else if (type === 'supplies') {
        if (this.isArray(dataSource) && dataSource[0].count) {
          $that.addMarkerSupplies(over, lay.name, dataSource, code);
        }
      } else if (type === 'jobticket') {
        if (this.isArray(dataSource) && dataSource.length > 0) {
          $that.addMarkerJobticket(over, lay.name, dataSource, code);
        }
      }
    }
  }

  isArray(o) {
    return Object.prototype.toString.call(o) == '[object Array]';
  }

  //切换楼层

  changeFloor(index) {

    this.floorCurrent = index;
    if (index === -1) {
      this.floorValue = 'Z1'
    } else {
      this.floorValue = this.floorList.filter(v => v.index === index)[0].value
    }
    this.init(this.floorValue)

    this.oldAreaCode = new Set();
  }

  getFloor() {
    this.http.get(`${baseUrl}/basics/v1/companys/floor`).subscribe((res: any) => {
      if (res.code == 0) {
        this.floorList = res.data.map((v, index) => {
          return {
            text: v.text,
            value: v.value,
            index: index
          }
        });
      } else {
        this.msg.error(res.msg);
      }
    })
  }

  //下载 4色图
  download() {
    this.loading = true;

    try {
      //隐藏 标注
      this.map.getAllOverlays('marker').forEach(marker => {
        marker.hide();
      });
      // //显示 区域名称
      // this.map.getAllOverlays('text').forEach(marker => {
      //   marker.show();
      // });

      let imageLayerElement = window['currentImageLayer'].getElement();
      let imageLayerRectData = imageLayerElement.getBoundingClientRect();
      let containerElement = document.getElementById('container');
      let containerRectData = containerElement.getBoundingClientRect();

      window['domtoimage'].toPng(containerElement, { cacheBust: true })
        .then((dataUrl) => {
          // //隐藏 区域名称
          // this.map.getAllOverlays('text').forEach(marker => {
          //   marker.hide();
          // });
          //显示 标注
          this.map.getAllOverlays('marker').forEach(marker => {
            marker.show();
          });

          var containerImg = new Image();
          containerImg.src = dataUrl;
          containerImg.onload = () => {
            //地图容器 画布
            var containerCanvas = document.createElement("canvas");
            containerCanvas.width = containerRectData.width;
            containerCanvas.height = containerRectData.height;
            var containerCtx = containerCanvas.getContext("2d");
            containerCtx.drawImage(containerImg, 0, 0);
            // document.body.appendChild(containerCanvas);

            //剪裁出 图片图层 数据
            let x = imageLayerRectData.left - containerRectData.left;
            let y = imageLayerRectData.top - containerRectData.top;
            var imageLayerImgData = containerCtx.getImageData(x, y, imageLayerRectData.width, imageLayerRectData.height);

            //图片图层 画布
            var imageLayerCanvas = document.createElement("canvas");
            imageLayerCanvas.width = imageLayerRectData.width;
            imageLayerCanvas.height = imageLayerRectData.height;
            var imageLayerCtx = imageLayerCanvas.getContext("2d");
            imageLayerCtx.putImageData(imageLayerImgData, 0, 0);
            // document.body.appendChild(imageLayerCanvas);

            //下载
            const link = document.createElement('a');
            link.setAttribute('href', imageLayerCanvas.toDataURL());
            link.setAttribute('download', `${window['currentImageLayer'].districtName} ${moment().format('YYYYMMDDHHmmssSSS')}.png`);
            link.click();

            setTimeout(() => {
              this.loading = false;
            }, 0);
          };
          // document.body.appendChild(containerImg);
        })
    } catch (error) {
      this.loading = false;
      this.msg.error('导出发生异常');
    }
  }


  sieveChange(value) {
    this.sieve = value;
    this.map = null;
    this.oldAreaCode = new Set();
    this.init(this.floorValue);
  }


}
