import { Component, OnInit, ChangeDetectorRef, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { baseUrl } from '@core';
import { _HttpClient, ModalHelper,SettingsService } from '@delon/theme';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LazyService } from '@delon/util';
import { Router, ActivatedRoute } from '@angular/router';

declare var AMap: any; //高德地图

@Component({
  selector: 'app-safe-online-registration',
  templateUrl: './safe-online-registration.component.html',
  styleUrls: ['./safe-online-registration.component.scss']
})
export class SafeOnlineRegistrationComponent implements OnInit {
  current = 0;
  token;
  form: FormGroup;
  mobile:any;
  // 省市区数据
  provinceData: any[] = [];
  cityData: any[] = [];
  districtData: any[] = [];
  aiBusinessData: any = null;
  map: any = {};
  zoom: number = 14;

  //坐标位置
  provinceName;
  cityName;
  districtName;
  provinceId;
  cityId;
  districtId;

  posterId;
  referrer;

  //当前坐标
  latitude;
  longitude
  
  constructor(
    private fb: FormBuilder,
    public http: HttpClient,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private lazy: LazyService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    this.position()
    this.LoadProvince();

    
    
    this.form = this.fb.group({
      //userName: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      mobile: [this.mobile, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      code: [null],
      // remember: [true],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
      provinceCode: [null, [Validators.required]],
      cityCode: [null, [Validators.required]],
      districtCode: [null, [Validators.required]],
    });
    // this.form.controls.provinceCode = 410000;
  }
  get city() {
    return this.form.controls.city;
  }

  get district() {
    return this.form.controls.district;
  }

  ngAfterViewInit() {
    // 获取推广链接的参数
    this.route.queryParams.subscribe(map => {
      this.posterId = map.posterId ? map.posterId : null;
      this.referrer = map.referrer ? map.referrer : null;
      console.log(this.posterId,this.referrer)
    });
    
     
  }
  getLocation(){
    let $that = this
    // 加载省市区

    // this.commonService.getOneOSSParams();
    this.lazy
      .loadScript(
        'https://webapi.amap.com/maps?v=1.4.14&key=9b6aa8c00d6ef8ea8fd14d082df78eb4&plugin=AMap.MouseTool,AMap.ControlBar,AMap.RectangleEditor,AMap.Autocomplete',
      )
      .then(() => {
        // this.getLangLat(1);

        // 高德地图
        // this.map = new AMap.Map('allmap', {
        //   center: [113.698816, 34.788032],
        //   zoom: this.zoom,
        //   expandZoomRange: true,
        //   zooms: [3, 20],
        //   mapStyle: 'amap://styles/macaron',
        //   resizeEnable: true,
        // });
        // console.log(this.longitude, this.latitude)
        console.log(this.longitude,111111111)
        // if(this.longitude != null && this.latitude != null){
          var lnglatXY = [this.longitude, this.latitude];//地图上所标点的坐标
          // var lnglatXY = [113.698816, 34.788032];
          AMap.service('AMap.Geocoder',function() {//回调函数
              let geocoder = new AMap.Geocoder({
              });
              geocoder.getAddress(lnglatXY, function (status, result) {
                  if (status === 'complete' && result.info === 'OK') {
                     console.log(result.regeocode.addressComponent)
                    //  this.form.value.province = 
                     $that.provinceName = result.regeocode.addressComponent.province;
                     $that.cityName = result.regeocode.addressComponent.city;
                     $that.districtName = result.regeocode.addressComponent.district;
                     
                      // var address = result.regeocode.formattedAddress;
                    } else {
                      //获取地址失败
                      $that.msg.warning('获取地址失败')
                      
                    }
                    $that.LoadProvince();
              });
          })
        // }
      });
  }

  /**
   * 加载省数据
   *
   */
   LoadProvince() {
    let params = {
      name: 'province',
      parentCode: '0',
    };
    const headers = new HttpHeaders()
      .set('Authorization', '')
      .delete('Authorization')
      .set('token', '');

    this.http
      .get<any>(`${baseUrl}/basics/v1/districts/data`, {
        params: params,
        headers: headers,
      })
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.provinceData = res.data || [];
          if(this.provinceName != null){
            this.msg.success(this.provinceName)
            this.provinceId=this.provinceData.filter(item => (item.name == this.provinceName))[0].code;
            this.form.patchValue({ provinceCode: this.provinceId});
            this.loadCities(this.provinceId,true,true)
          }

          this.cdr.detectChanges();
        } else {
          this.msg.warning(res.msg);
        }
      });
  }
  /**
   * 选择省时加载市
   *
   */
  loadCities(pid: string, cleanCity = true,auto = false) {
    if (pid) {
      let params = {
        name: 'city',
        parentCode: pid,
      };
      const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
      this.http
        .get<any>(`${baseUrl}/basics/v1/districts/data`, {
          params: params,
          headers: headers,
        })
        .subscribe((res: any) => {
          if (res.code == 0) {
            this.cityData = res.data || [];
            if(auto){
              if(this.cityName != null){
                this.cityId=this.cityData.filter(item => (item.name == this.cityName))[0].code;
                this.form.patchValue({ cityCode: this.cityId});
                this.loadDistrict(this.cityId,true,true)
              }
            }

            if (this.aiBusinessData) {
              let cityCode = this.cityData.filter(
                item =>
                  item.name ===
                  this.aiBusinessData.enterpriseRegisterAddressCity,
              )[0];

              setTimeout(() => {
                this.city.setValue(!!cityCode ? cityCode.code : '');
              }, 500);
            }

            if (cleanCity) {
              this.form.patchValue({
                city: null,
                district: null,
                // town: null,
                // village: null,
              });
              this.districtData = [];
              // this.townData = [];
              // this.villageData = [];
            }
            this.cdr.detectChanges();
          } else {
            this.msg.warning(res.msg);
          }
        });
    }
  }
  /**
   * 选择市时加载区
   *
   */
  loadDistrict(pid: string, cleanDistrict = true,auto = false) {
    if (pid) {
      let params = {
        name: 'district',
        parentCode: pid,
      };
      const headers = new HttpHeaders()
        .set('Authorization', '')
        .delete('Authorization')
        .set('token', '');
      this.http
        .get<any>(`${baseUrl}/basics/v1/districts/data`, {
          params: params,
          headers: headers,
        })
        .subscribe((res: any) => {
          if (res.code == 0) {
            this.districtData = res.data || [];
            if(auto){
              if(this.districtName != null){
                this.districtId=this.districtData.filter(item => (item.name == this.districtName))[0].code;
                this.form.patchValue({ districtCode: this.districtId});
              }
            }

            if (this.aiBusinessData) {
              let districtCode = this.districtData.filter(
                item =>
                  item.name ===
                  this.aiBusinessData.enterpriseRegisterAddressDistrict,
              )[0];
              setTimeout(() => {
                this.district.setValue(!!districtCode ? districtCode.code : '');
              }, 500);
            }

            if (cleanDistrict) {
              this.form.patchValue({
                district: null,
                // town: null,
                // village: null,
              });
              // this.townData = [];
              // this.villageData = [];
            }
            this.cdr.detectChanges();
          } else {
            this.msg.warning(res.msg);
          }
        });
    }
  }

  submitForm(){
    let data = {
      ...this.form.value,
      referrer:this.referrer,
      posterId:this.posterId,
      province: this.provinceName,
      city: this.cityName,
      district: this.districtName,
    }
    if(!data.mobile && !data.code){
      this.msg.error('请填写手机号和验证码')
      return;
    }
    if(!data.confirmPassword && !data.password){
      this.msg.error('请填写设置密码和确认密码')
      return;
    }
    if(data.provinceCode && data.cityCode && data.districtCode){
      data.province=this.provinceData.filter(item => (item.code == data.provinceCode))[0].name;
      data.city=this.cityData.filter(item => (item.code == data.cityCode))[0].name;
      data.district=this.districtData.filter(item => (item.code == data.districtCode))[0].name;
      this.http.get<any>(`${baseUrl}/cooperation/v1/message/sms/check?smsType=commonCode&mobile=` + data.mobile + `&code=` + data.code)
      .subscribe(res => {
        console.log(123321,res)
        if (res.code == 0) {
          this.current += 1;
          this.token = res.data;

          this.http.post<any>(`${baseUrl}/admin/v1/ahj/users/register`,data)
          .subscribe(res => {
            console.log(666888,res)
            if (res.code == 0) {
              this.msg.success(res.msg)
              setTimeout(() => {
                this.router.navigateByUrl('registra-success');
              }, 2000);
            } else {
              this.msg.error(res.msg);
            }
        })
          
        } else {
          this.msg.error(res.msg);
        }

    })
    }else {
      this.msg.error('请选择您的所在区域')
    }
    
    
  }

  position(){
    var that = this
    if (navigator.geolocation) {               
        navigator.geolocation.getCurrentPosition(function (position) {
            // alert(position.coords.latitude);
            // alert(position.coords.longitude);
            
          console.log(navigator.geolocation);
          that.latitude = position.coords.latitude;
          that.longitude = position.coords.longitude;
          that.getLocation()
        }, function (error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    alert('用户拒绝对获取地理位置的请求。')
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert('位置信息是不可用的。')
                    break;
                case error.TIMEOUT:
                    alert('请求用户地理位置超时。')
                    break;
            }
        }, { });
    } else {
        // alert('不支持地理位置')
        that.msg.error('不支持地理位置');
    }
  }

  /**
    * 获取验证码
    */
   count = 0;
   interval$: any;
 
   getCaptcha() {
     if (this.form.controls.mobile.invalid) {
       this.form.controls.mobile.markAsDirty({ onlySelf: true });
       this.form.controls.mobile.updateValueAndValidity({ onlySelf: true });
       return;
     }
     let data = {
       ...this.form.value,
     };
     console.log(data)
 
     this.count = 59;
     this.interval$ = setInterval(() => {
       this.count -= 1;
       if (this.count <= 0) clearInterval(this.interval$);
     }, 1000);
 
     this.http.get<any>(`${baseUrl}/cooperation/v1/message/sms?smsType=commonCode&mobile=`+data.mobile)
       .subscribe(res => {
         console.log(res)
         if (res.code == 0) {
           this.msg.success(res.msg);
         }else{
 
           this.msg.error(res.msg);
         }
       })
 
   }

}
