import { NzModalService } from 'ng-zorro-antd';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { access_token, baseUrl, CommonService, MessageService, StartupService } from '@core';
import { ReuseTabService } from '@delon/abc';
import { DA_SERVICE_TOKEN, ITokenService, SocialOpenType, SocialService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { SettingsService } from '@delon/theme';
import { LazyService } from '@delon/util';
import { environment } from '@env/environment';

declare var CryptoJS: any;
declare var Base64: any;
@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [SocialService],
})
export class UserLoginComponent implements OnDestroy {
  form: FormGroup;
  error = '';
  type = 0;
  loading = false;
  isVisible = true;

  referrer = '';
  showData:any= {};

  constructor(
    fb: FormBuilder,
    modalSrv: NzModalService,
    private router: Router,
    private settingsService: SettingsService,
    private socialService: SocialService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    public http: HttpClient,
    public msg: MessageService,
    private lazy: LazyService,
    private commonService: CommonService,
    public srv: CacheService,
    private route: ActivatedRoute,
  ) {
   // window.localStorage.removeItem('_token');
    this.form = fb.group({
      //userName: [null, [Validators.required, Validators.minLength(3)]],
      userName: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      password: [null, Validators.required],
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, [Validators.required]],
      remember: [true],
    });
    this.lazy.loadScript('assets/js/crypto-js.js');
    modalSrv.closeAll();

    route.queryParams.subscribe(queryParams => {
      if (typeof queryParams.referrer !== 'undefined') {
        this.referrer = queryParams.referrer;
      }
    });
    
  }

  // #region fields

  get userName() {
    return this.form.controls.userName;
  }
  get password() {
    return this.form.controls.password;
  }
  get mobile() {
    return this.form.controls.mobile;
  }
  get captcha() {
    return this.form.controls.captcha;
  }
  showModal() {
    this.isVisible = false;
  }

  // #endregion

  switch(ret: any) {
    this.type = ret.index;
  }

  // #region get captcha

  count = 0;
  interval$: any;

  getCaptcha() {
    if (this.mobile.invalid) {
      this.mobile.markAsDirty({ onlySelf: true });
      this.mobile.updateValueAndValidity({ onlySelf: true });
      return;
    }
    this.count = 59;
    this.interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) clearInterval(this.interval$);
    }, 1000);
  }

  encryption(params) {
    let { data, type, param, key } = params;
    const result = JSON.parse(JSON.stringify(data));
    if (type === 'Base64') {
      param.forEach(ele => {
        result[ele] = btoa(result[ele]);
      });
    } else {
      param.forEach(ele => {
        var data = result[ele];
        key = CryptoJS.enc.Latin1.parse(key);
        var iv = key;
        // 加密
        var encrypted = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding,
        });
        result[ele] = encrypted.toString();
      });
    }
    return result;
  }

  // #endregion

  submit() {
    this.error = '';
    if (this.type === 0) {
      this.userName.markAsDirty();
      this.userName.updateValueAndValidity();
      this.password.markAsDirty();
      this.password.updateValueAndValidity();
      if (this.userName.invalid || this.password.invalid) return;
    } else {
      this.mobile.markAsDirty();
      this.mobile.updateValueAndValidity();
      this.captcha.markAsDirty();
      this.captcha.updateValueAndValidity();
      if (this.mobile.invalid || this.captcha.invalid) return;
    }
    this.loading = true;
    // 默认配置中对所有HTTP请求都会强制 [校验](https://ng-alain.com/auth/getting-started) 用户 Token
    // 然一般来说登录请求不需要校验，因此可以在请求URL加上：`/login?_allow_anonymous=true` 表示不触发用户 Token 校验
    const user = this.encryption({
      data: this.form.value,
      key: 'youkon0123456789',
      param: ['password'],
    });

    const headers = new HttpHeaders()
      .set('Authorization', 'Basic eXVrb246eXVrb24')
      .set('token', '');

    let enCodePwd = encodeURIComponent(user.password);

    //清除 token
    window.localStorage.removeItem('_token');
    this.tokenService.clear();
    //手机号去除空格

    user.userName = 'pc_' + user.userName.split(' ').join('');
    this.http
      .get<any>(
        `${baseUrl}/auth/oauth/token?password=${enCodePwd}&grant_type=password&username=${user.userName}`,
        {
          headers: headers,
        },
      )
      .subscribe((res: any) => {
        if (res.code === 0) {
          // 自动跳转到对应的 App
          this.redirectIfShould(res);
          // 清空路由复用信息
          this.reuseTabService.clear();
          // 设置用户Token信息
          res.token = res.access_token;
          this.tokenService.set(res);
          if (this.referrer !== '') {
            window.location.href = this.referrer + '?token=' + res.access_token;
            return;
          }
          // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
          this.startupSrv.checkToken(res).then(() => {
           
            let loginBeforeUrl = this.commonService.loginBeforeUrl || '/';
            if (loginBeforeUrl === '/passport/login') {
              loginBeforeUrl = '/';
            }
            this.loading = false;
            // 根据企业审核状态自动跳转到对应的 页面
            this.getAduitStatus(loginBeforeUrl);
           
           
          });
        } else {
          this.loading = false;
          this.error = res.msg;
          return;
        }
      });
  }

  /**
   * 检测当前用户客户端权限并进行自动跳转。
   * @param res
   */
  redirectIfShould(res: any) {
    const { product, product_list: productList, access_token: token } = res;
    const {
      appMap,
      appRedirectTokenParamName: name,
      client,
    } = environment.appRedirectConfig;
    // 如果当前应用包含在 productList 中，则不跳转
    if (productList.includes(client)) {
      return;
    }
    // 如果当前应用不在 projectList 中，但是和 product 一致，也不跳转（推荐应用和当前应用一致）
    if (client.includes(product)) {
      return;
    }
    // 其他情况，跳转
    const key = `${product}产品`;
    const redirectUrl = `${appMap[key]}?${name}=${token}`;
    window.location.href = location.protocol + '//' + redirectUrl;
  }
  // #region social

  open(type: string, openType: SocialOpenType = 'href') {
    let url = ``;
    let callback = ``;
    if (environment.production) {
      callback = 'https://ng-alain.github.io/ng-alain/#/callback/' + type;
    } else {
      callback = 'http://localhost:4200/#/callback/' + type;
    }
    switch (type) {
      case 'auth0':
        url = `//cipchk.auth0.com/login?client=8gcNydIDzGBYxzqV0Vm1CX_RXH-wsWo5&redirect_uri=${decodeURIComponent(
          callback,
        )}`;
        break;
      case 'github':
        url = `//github.com/login/oauth/authorize?client_id=9d6baae4b04a23fcafa2&response_type=code&redirect_uri=${decodeURIComponent(
          callback,
        )}`;
        break;
      case 'weibo':
        url = `https://api.weibo.com/oauth2/authorize?client_id=1239507802&response_type=code&redirect_uri=${decodeURIComponent(
          callback,
        )}`;
        break;
    }
    if (openType === 'window') {
      this.socialService
        .login(url, '/', {
          type: 'window',
        })
        .subscribe(res => {
          if (res) {
            this.settingsService.setUser(res);
            this.router.navigateByUrl('/');
          }
        });
    } else {
      this.socialService.login(url, '/', {
        type: 'href',
      });
    }
  }
  /**
   * 获取企业初始化状态
   */
  getAduitStatus(loginBeforeUrl){
    let companyId = JSON.parse(window.localStorage._token || '{}').tenant_id;
    if(companyId){
      this.http.get<any>(`${baseUrl}/platform/v1/sysAudit/status/${companyId}`).subscribe((res:any) => {
        if (res.code == 0) {
          this.showData = res.data || {};
          // 存储审核状态信息
          res.data && this.srv.set('auditStatus', res.data.auditStatus);
          
          if (res.data && (this.showData.auditStatus != '1')) {
             // 如果是初始化未申请企业，跳转到初始化页面
             this.router.navigateByUrl('/rapid-deployment-account').then(() => {
              this.commonService.loginBeforeUrl = null;
            });       
          } else {            
            this.router.navigateByUrl(loginBeforeUrl).then(() => {
              this.commonService.loginBeforeUrl = null;
            });
          }
        } else {
          this.router.navigateByUrl(loginBeforeUrl).then(() => {
            this.commonService.loginBeforeUrl = null;
          });
          //this.msg.error(res.msg || '加载失败');
        }
      });
    }
   
  }
  // #endregion

  ngOnDestroy(): void {
    if (this.interval$) clearInterval(this.interval$);
  }
}
