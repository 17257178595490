import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TitleService } from '@delon/theme';
import { VERSION as VERSION_ALAIN } from '@delon/theme';
import { VERSION as VERSION_ZORRO, NzModalService, NzIconService } from 'ng-zorro-antd';

import { CommonService } from '@core';
import { _HttpClient } from '@delon/theme';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    private router: Router,
    private titleSrv: TitleService,
    private modalSrv: NzModalService,
    private nzIconService: NzIconService,
    private commonService: CommonService,
    private http: _HttpClient
  ) {
    
    renderer.setAttribute(
      el.nativeElement,
      'ng-alain-version',
      VERSION_ALAIN.full,
    );
    renderer.setAttribute(
      el.nativeElement,
      'ng-zorro-version',
      VERSION_ZORRO.full,
    );

    // this.nzIconService.fetchFromIconfont({
    //  scriptUrl: '//at.alicdn.com/t/font_1161121_20f80zyooya.js'
    this.nzIconService.fetchFromIconfont({
     scriptUrl: '//at.alicdn.com/t/font_1161121_6zo613eza03.js'
    });
    // 增加监控 start
    const BrowserLogger = require('alife-logger');
    const __bl = BrowserLogger.singleton({pid:"c72s4qkpjs@114fdda91531f48",appType:"web",imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",sendResource:true,enableLinkTrace:true,behavior:true, environment:environment.controlvar,release:environment.version});
    console.log(__bl) ;
    // __bl.setConfig({
    //   environment:environment.controlvar,
    //   release:environment.version
    // });
    this.nzIconService.fetchFromIconfont({
      scriptUrl: '../assets/js/tubiao.js'
     });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.titleSrv.setTitle();
        this.modalSrv.closeAll();

        // if(e.urlAfterRedirects == '/dashboard') {
        //   this.commonService.isDashboard = true;
        // } else {
        //   this.commonService.isDashboard = false;
        // }
      });
  }
}
