import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * 操作 document 对象以及 window 对象的服务，避免在 app 中直接使用 window, document 对象。
 */
@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  get isFullscreen() {
    const doc = this.document as any;
    return (
      doc.fullscreenElement ||
      doc.webkitCurrentFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.webkitIsFullScreen
    );
  }
  /**
   * 进入全屏
   */
  requestFullScreen() {
    const de = this.document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    } else if (de['mozRequestFullScreen']) {
      de['mozRequestFullScreen']();
    } else if (de['webkitRequestFullScreen']) {
      de['webkitRequestFullScreen']();
    }
  }
  /**
   * 退出全屏
   */
  exitFullscreen() {
    const de = this.document as any;
    if (this.isFullscreen) {
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.webkitExitFullscreen) {
        de.webkitExitFullscreen();
      } else if (de['mozCancelFullScreen']) {
        de['mozCancelFullScreen']();
      } else if (de['webkitCancelFullScreen']) {
        de['webkitCancelFullScreen']();
      }
    }
  }

  toggleFullscreen() {
    this.isFullscreen ? this.exitFullscreen() : this.requestFullScreen();
  }

  setBodyOverflowHidden() {
    setTimeout(() => {
      this.document.body.style.overflow = 'hidden';
    }, 1000);
  }
  setBodyOverflowScroll() {
    setTimeout(() => {
      this.document.body.style.overflow = 'scroll';
    }, 1000);
  }
}
