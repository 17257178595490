import { ChangeDetectionStrategy, OnInit, Component, HostBinding, Input} from '@angular/core';
import { CommonService } from '@core';
import { CacheService } from '@delon/cache';
import { SettingsService } from '@delon/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {


  useServerMenu = this.srv.getNone('useServerMenu');
  moduleListMenu = this.srv.getNone('moduleListMenu') || [];

  index: number;
  top: number;
  left: number;


  @HostBinding('class.isHideModuleSidebar') @Input() isHideModuleSidebar = true;
  @HostBinding('class.isDashboard') @Input() isDashboard = false;
  constructor(public settings: SettingsService, public commonService: CommonService, public srv: CacheService, private router: Router) { }

  linkTo() {
    window.location.href = "https://download.ys7.com/assets/deps/EzvizStudioSetups.exe";
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

}
