import { Injectable, Injector } from '@angular/core';
import { BaseApi } from '@base';
import { CacheService } from '@delon/cache';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuickDeployService extends BaseApi {

  private applicationList = ['sys', 'risk', 'hazard', 'train'];

  constructor(protected injector: Injector, private srv: CacheService) {
    super(injector);
  }

  private getQuickDeployApplications(){
    const applications = this.srv.getNone<string[]>('applications');
    if(applications == null){
      return [];
    }
    return this.applicationList.map(app => applications.includes(app) ? app : null).filter(Boolean);
  }
  getDeployConfig() {
    const url = 'hazard/v1/rapid/deployments';
    const applications = this.getQuickDeployApplications();
    return this.get<IQuickDeployData[]>({ url, params: {applications} });
  }
}


/**
 * 快速部署模块
 */
export interface IQuickDeployData {
  module:     string;
  moduleName: string;
  nodes:      IQuickDeployNode[];
}

/**
 * 快速部署模块节点
 */
export interface IQuickDeployNode {
  nodeName:       string;
  nodePath:       string;
  skip:           number;
  anyData:        number | null;
  operate:        boolean | null;
  typicalCaseUrl: null | string;
  sort:           number;
  haveTypical:    boolean;
}
