import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[fullScreenDirective]',
})
export class FullScreenDirective {
  @Input() targetElement: HTMLElement;

  @Output() isFullscreenMode: EventEmitter<boolean> = new EventEmitter();

  isFullscreen = false;

  constructor() {}

  @HostListener('click', ['$event.target']) onClick($event) {
    //进入全屏
    const requestFullScreen = () => {
      var de = this.targetElement || document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de['mozRequestFullScreen']) {
        de['mozRequestFullScreen']();
      } else if (de['webkitRequestFullScreen']) {
        de['webkitRequestFullScreen']();
      }
    };
    //退出全屏
    const exitFullscreen = () => {
      var de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de['mozCancelFullScreen']) {
        de['mozCancelFullScreen']();
      } else if (de['webkitCancelFullScreen']) {
        de['webkitCancelFullScreen']();
      }
    };

    if (this.isFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.isFullscreen = !this.isFullscreen;

    this.isFullscreenMode.next(this.isFullscreen);
  }
}
