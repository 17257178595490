import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MessageService } from '@core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { zip } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd';
import { mockUrl, baseUrl, access_token, uploadUrl } from '@core';
  import { DataService, CommonService } from '@core';
import { format } from 'date-fns';
import { NgForm } from '@angular/forms';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less'],
})
export class InfoComponent implements OnInit {
  @ViewChild('f') form: NgForm;
  @ViewChild(FileUploadComponent) fileUpload: FileUploadComponent;
  uploadAction: any = `${uploadUrl}`;
  uploadParams = {
    fileType: 'picture'
  }
  fileList = [];
  avatar = '';
  userLoading = true;
  user: any = {};
  rows = [];
  //对话框
  editDialog = {
    display: false,
    loading: false,
    data: <any>{},
    form: <FormGroup>null,
    uploadAction: <any>`${uploadUrl}`,
    fileList: [],
  };
  arrList: any = [
    '无职称',
    '技术员',
    '助理工程师',
    '工程师',
    '高级工程师',
    '正高级工程师',
    '研究实习员',
    '助理研究员',
    '副研究员',
    '研究员',
    '实验员',
    '助理实验师',
    '实验师',
    '高级实验师',
    '正高级实验师',
  ];

  constructor(
    private http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private msg: MessageService,
    public commonService: CommonService,
  ) {}

  /**
   * 文件上传
   */
  beforeUpload = (file): boolean => {
      let OSS = this.commonService.OSS;
     let path = this.commonService.path + this.commonService.getFileType(file);
      OSS.put(path, file).then((res) => {
        this.editDialog.fileList = [
          ...this.editDialog.fileList,
          {
            uid: this.editDialog.fileList.length,
            status: 'done',
            name: file.name,
            url: this.commonService.OssSignatureUrl(path),
            filePath: this.commonService.getFilePath(path, file),
            urlPath: this.commonService.getFilePath(path, file)
          }
  
        ];
      }).catch(() => {
        this.msg.error('文件上传不成功!');
      });
      return false;
    }

  /**
   * 加载数据
   * @param reset
   */
  search(reset: any = '') {
    this.http.get(`${baseUrl}/admin/v1/users/myinfo`).subscribe((res: any) => {
      if (res.code == '0') {
        if(res.data.avatar){
          //res.data.avatar = this.commonService.OssSignatureUrl(res.data.avatar);
         this.avatar = this.commonService.OssSignatureUrl(res.data.avatar);
        }
        res.data.sex = res.data.sex + '';
        res.data.dep =
          res.data.jobList.length > 0 ? res.data.jobList[0].deptName : '';
        res.data.pos =
          res.data.jobList.length > 0 ? res.data.jobList[0].postName : '';
        if (
          this.arrList.indexOf(res.data.positionalTitle) == -1 &&
          res.data.positionalTitle !== ''
        ) {
          res.data.new_positionalTitle = res.data.positionalTitle;
          res.data.positionalTitle = '其他';
        }
       
        this.user = res.data || [];
        this.userLoading = false;
      } else {
        this.userLoading = false;
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

  ngOnInit(): void {
    this.search();
  }

  save() {

    let img = this.fileUpload.fileList;
    console.log(img);
    if (img.length > 0) {
      this.user.avatar = img[0].url;
      this.avatar = this.user.avatar;
    }
    // console.log(img);return;
    const data = {
      contactMobile: this.user.contactMobile,
      username: this.user.username,
      avatar: this.user.avatar ? this.user.avatar : '',
      userId: this.user.userId,
      // tslint:disable-next-line: radix
      sex: parseInt(this.user.sex),
      card: this.user.card,
      positionalTitle:
        this.user.positionalTitle === '其他'
          ? this.user.new_positionalTitle
          : this.user.positionalTitle,
      university: this.user.university,
      specialty: this.user.specialty,
      schoolRecord: this.user.schoolRecord,
      birthday: this.user.birthday,
      entryTime: this.user.entryTime
        ? format(this.user.entryTime, 'YYYY-MM-DD')
        : '',
      entryPostTime: this.user.entryPostTime
        ? format(this.user.entryPostTime, 'YYYY-MM-DD')
        : '',
    };
    this.http
      .put(`${baseUrl}/admin/v1/users/my`, data)
      .subscribe((res: any) => {
        if (res.code == 0) {
          this.editDialog.fileList = [];
          this.msg.success(res.msg || '更新成功');
        } else {
          this.msg.error(res.msg || '更新失败');
        }
      });
  }
  /**
   * 验证函数s
   */
  getNzValidateStatus(name) {
    let formControl = this.form.controls[name];
    if (formControl && formControl.dirty && !formControl.valid) {
      return 'error';
    }
    return null;
  }
  birandsex(e=''){
    this.user.sex=this.getSexForCard(e)||'';
    this.user.birthday=this.getAge(e);
  }
  getSexForCard(str) {
    var inputStr = str.toString();
    var sex;
    if (inputStr.length == 18) {
      sex = inputStr.charAt(16);
      if (sex % 2 == 0) {
        return '1';
      } else {
        return '0';
      }
    } else {
      sex = inputStr.charAt(14);
      if (sex % 2 == 0) {
        return '1';
      } else {
        return '0';
      }
    }
  }
    //根据身份证号获取年龄
    getAge(identityCard) {
      var len = (identityCard + "").length;
      var strBirthday = "";
      if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
      {
        strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
      }
      if (len == 15) {
        var birthdayValue = '';
        birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
        if (parseInt(birthdayValue) < 10) {
          strBirthday = "20" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        } else {
          strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        }
  
      }
      return strBirthday;
      //时间字符串里，必须是“/”
      // var birthDate = new Date(strBirthday);
      // var nowDateTime = new Date();
      // var age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      // if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
      //   age--;
      // }
      // tslint:disable-next-line: radix
      // return birthDate.getFullYear()+'-'+birthDate.getMonth()+'-'+birthDate.getDate();
    }
}
