import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-photo-frame-container',
  templateUrl: './photo-frame-container.component.html',
  styleUrls: ['./photo-frame-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoFrameContainerComponent implements OnInit {
  @Input() bcgColor: string = 'transparent';

  constructor() {}

  ngOnInit() {}
}
