import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { MessageService } from '@core';
import { DomSanitizer } from '@angular/platform-browser'

import { _HttpClient } from '@delon/theme';
import { baseUrl, mockUrl, riskUrl, uploadUrl, domain } from '@core';
import { CommonService } from '@core';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import { ActivatedRoute, Params, Router, ActivationEnd, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';

import * as G2 from '@antv/g2';
import * as moment from 'moment';

let hazardUrl = `${domain}/hazard/v1`;
let basicsUrl = `${domain}/basics/v1`;

@Component({
  selector: 'app-data-traffic-v2',
  templateUrl: './data-traffic-v2.component.html',
  styleUrls: ['./data-traffic-v2.component.scss']
})
export class DataTrafficV2Component implements OnInit {
  window = window;

  company: string;

  systemNotice_carousel_autoPlay = true;
  risk_gongshi_carousel_autoPlay = true;
  yinhuan_gongshi_carousel_autoPlay = true;
  risk1_carousel_autoPlay = true;
  risk2_carousel_autoPlay = true;
  yh1_carousel_autoPlay = true;
  yh2_carousel_autoPlay = true;

  // logo
  logoData: any = {};
  // 日期时间
  datetime = '';
  // 是否全屏
  isFullscreen = false;
  // 统计数量
  totalData: any = {};
  // 通知公告
  systemNotice = [];
  // 重大风险公示
  majorNotificationList = [];
  // 重大隐患公示
  majorHiddenDangerPublicity = [];

  /**
   * echartsOption
   */
  //<风险统计>:
  //风险点统计 按类型
  lxEchartsOption = null;
  //风险点统计 按部门
  bmEchartsOption = null;

  //作业活动风险值对比图
  zyhdEchartsOption = null;
  ways = '1';

  //<风险统计>:
  //按区域
  fxqyEchartsOption = null;
  //按部门
  fxbmEchartsOption = null;

  //隐患统计 area"（区域统计）、"category"（类别统计）、"duty"（责任单位统计）
  yhqyEchartsOption = null;
  yhlbEchartsOption = null;
  yhzrdwEchartsOption = null;


  //隐患变化趋势
  yhqsEchartsOption = null;

  constructor(public sanitizer: DomSanitizer, private message: MessageService, private http: _HttpClient, private eleMentRef: ElementRef,
    private commonService: CommonService, private router: Router,
    private route: ActivatedRoute,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.company = params['c'];
      }
    );
    this.load_screen();
    this.setDateTime();
    this.load_companyscreens();
    this.load_systemNotice();
    this.load_majorNotification();
    this.load_majorHiddenDangerPublicity();

    //风险点统计  按部门  按类型
    this.load_checkItemLocationStatistics(1);
    this.load_checkItemLocationStatistics(2);

    //作业活动风险值对比图
    this.load_checkItemDepartmentStatistics(1);

    //风险统计
    this.load_chartStatistics('checkItemLocationStatistics');
    this.load_chartStatistics('checkItemDepartmentStatistics');

    //隐患统计
    this.load_chartYh('area');
    this.load_chartYh('category');
    this.load_chartYh('duty');

    this.load_trendStatistics();


    //添加 轮播图 播放暂停 事件
    setTimeout(() => {
      Array.from(this.eleMentRef.nativeElement.querySelectorAll('[autoPlay_field]')).forEach((carousel: HTMLElement) => {
        let autoPlay_field = carousel.getAttribute('autoPlay_field');
        carousel.addEventListener('mouseenter', (e) => {
          this[autoPlay_field] = false;
        });
        carousel.addEventListener('mouseleave', (e) => {
          this[autoPlay_field] = true;
        });
      });
    }, 0);
  }

  //全屏切换
  onSwitchFullscreen() {
    //进入全屏
    const requestFullScreen = () => {
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de['mozRequestFullScreen']) {
        de['mozRequestFullScreen']();
      } else if (de['webkitRequestFullScreen']) {
        de['webkitRequestFullScreen']();
      }
    };
    //退出全屏
    const exitFullscreen = () => {
      var de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de['mozCancelFullScreen']) {
        de['mozCancelFullScreen']();
      } else if (de['webkitCancelFullScreen']) {
        de['webkitCancelFullScreen']();
      }
    };

    if (this.isFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }

  //获取请求参数
  getParams() {
    return {
      access_token: JSON.parse(window.localStorage.getItem('_token') || '{}').token
    };
  }

  //设置日期时间
  setDateTime() {
    let dayMap = {
      0: '星期日',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
    };
    this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    setInterval(() => {
      this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    }, 1000);
  }

  //加载 logo
  load_screen() {
    let params = this.getParams();
    this.http.get<any>(`${basicsUrl}/companys/screen`, params).subscribe(res => {
      if (res.code == 0) {
        this.logoData = res.data || {};

        // this.logoData.companyLogo = 'https://s2.ax1x.com/2019/08/05/e2H3cR.jpg';

        if (this.logoData.companyLogo) {
          this.logoData.companyLogo = this.sanitizer.bypassSecurityTrustStyle('url(' + this.logoData.companyLogo + ')')
        }
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 统计数量
  load_companyscreens() {
    /**
     * 隐患
     */
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens`, params).subscribe(res => {
      if (res.code == 0) {
        this.totalData = {
          ...this.totalData,
          ...res.data
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });

    /**
     * 风险
     */
    params = this.getParams();
    this.http.get<any>(`${hazardUrl}/risk/statistics/risknum`).subscribe(res => {
      if (res.code == 0) {
        this.totalData = {
          ...this.totalData,
          ...res.data
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 通知公告
  load_systemNotice() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/systemNotice`, params).subscribe(res => {
      if (res.code == 0) {
        this.systemNotice = res.data.notice[0] || '暂无公告';
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 重大风险公示
  load_majorNotification() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/majorNotification`, params).subscribe(res => {
      if (res.code == 0) {
        this.majorNotificationList = res.data.riskNotification || [];
        let lists = [];
        for (var i = 0; i < this.majorNotificationList.length; i += 2) {
          this.majorNotificationList.slice(i, i + 2).forEach((item, index) => {
            item.index = index + i + 1;

          })
          lists.push(this.majorNotificationList.slice(i, i + 2));
        }
        //取前10个
        this.majorNotificationList = lists;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 重大隐患公示
  load_majorHiddenDangerPublicity() {
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/companyscreens/majorHiddenDangerPublicity`, params).subscribe(res => {
      if (res.code == 0) {
        this.majorHiddenDangerPublicity = res.data.hiddenDangerNotification || [];
        let lists = [];
        for (var i = 0; i < this.majorHiddenDangerPublicity.length; i += 2) {
          this.majorHiddenDangerPublicity.slice(i, i + 2).forEach((item, index) => {
            item.index = index + i + 1;

          })
          lists.push(this.majorHiddenDangerPublicity.slice(i, i + 2));
        }
        //取前10个
        this.majorHiddenDangerPublicity = lists;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 风险点统计 按部门 || 按类型
  load_checkItemLocationStatistics(type) {
    let params = { ...this.getParams(), type };
    this.http.get<any>(`${hazardUrl}/companyscreens/traffic/riskCountByType`, params).subscribe(res => {
      if (res.code == 0) {
        var bmTypes = [
          { name: '重大风险点', field: 'greatRiskCount' },
          { name: '较大风险点', field: 'relativeRiskCount' },
          { name: '一般风险点', field: 'generalRiskCount' },
          { name: '低风险点', field: 'lowRiskCount' }
        ]

        var riskTypes = [
          { name: '作业活动', field: 'activityWork' },
          { name: '设备设施', field: 'equipment' }
        ];

        var seriesType = [];

        let option = '';
        switch (type) {
          case 1: {
            option = 'bmEchartsOption'; break;
          }
          case 2: {
            option = 'lxEchartsOption'; break;
          }
        }

        type == 1 ? seriesType = bmTypes : seriesType = riskTypes ;

        let arr = res.data || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            x: 'center',
            y: 'top',
            itemWidth: 10,  // 设置宽度
            itemHeight: 10, // 设置高度
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.group),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: seriesType.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //作业活动风险值对比图 
  load_checkItemDepartmentStatistics(type) {
    let params = { ...this.getParams(), type };
    this.http.get<any>(`${hazardUrl}/companyscreens/traffic/activityRisk`, params).subscribe(res => {
      if (res.code == 0) {
        var riskTypes = [
          { field: 'valueTotal' }
        ];

        let arr = res.data || [];

        this.zyhdEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.typeName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            type: 'bar',
            barWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  waysChange() {
    this.load_checkItemDepartmentStatistics(this.ways);
  }


  //风险统计 按区域 || 按部门
  load_chartStatistics(classifiedType) {
    this.http.get<any>(`${hazardUrl}/companyscreens/${classifiedType}`).subscribe(res => {
      if (res.code == 0) {
        let option = '';
        let resField = '';
        switch (classifiedType) {
          case 'checkItemLocationStatistics': { option = 'fxqyEchartsOption'; resField = 'riskLocationStatistics'; break; }
          case 'checkItemDepartmentStatistics': { option = 'fxbmEchartsOption'; resField = 'riskDepartmentStatistics'; break; }
        }

        var riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        let arr = res.data[resField] || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            x: 'center',
            y: 'top',
            itemWidth: 10,  // 设置宽度
            itemHeight: 10, // 设置高度
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          }))
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 隐患统计
  load_chartYh(type) {
    let params = { ...this.getParams(), classifiedType: type };
    this.http.get<any>(`${hazardUrl}/companyscreens/chartStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        let option = '';
        let resField = '';
        switch (type) {
          case 'area': { option = 'yhqyEchartsOption'; resField = 'areaStatistics'; break; }
          case 'category': { option = 'yhlbEchartsOption'; resField = 'potentialCategoryStatistics'; break; }
          case 'duty': { option = 'yhzrdwEchartsOption'; resField = 'dutyUnitStatistics'; break; }
        }

        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' }
        ];

        let arr = res.data[resField] || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            x: 'center',
            y: 'top',
            itemWidth: 10,  // 设置宽度
            itemHeight: 10, // 设置高度
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          }))
        };

      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  //加载 隐患变化趋势
  load_trendStatistics() {
    let params = { ...this.getParams() };
    this.http.get<any>(`${hazardUrl}/companyscreens/trendStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        var riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        let arr = res.data.trendStatistics || [];

        // arr = [{"greatCount":81,"generalCount":74,"yearAndMonth":"1月"},{"greatCount":77,"generalCount":20,"yearAndMonth":"2月"},{"greatCount":59,"generalCount":67,"yearAndMonth":"3月"},{"greatCount":44,"generalCount":78,"yearAndMonth":"4月"},{"greatCount":66,"generalCount":35,"yearAndMonth":"5月"},{"greatCount":54,"generalCount":79,"yearAndMonth":"6月"},{"greatCount":49,"generalCount":45,"yearAndMonth":"7月"},{"greatCount":40,"generalCount":91,"yearAndMonth":"8月"},{"greatCount":8,"generalCount":94,"yearAndMonth":"9月"},{"greatCount":80,"generalCount":32,"yearAndMonth":"10月"},{"greatCount":43,"generalCount":55,"yearAndMonth":"11月"},{"greatCount":9,"generalCount":69,"yearAndMonth":"12月"}];

        this.yhqsEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            textStyle: {
              color: '#c7fffe'
            }
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.yearAndMonth),
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c7fffe'
            },
            splitLine: {
              lineStyle: {
                color: '#044d67'
              }
            }
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1
                }
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf'
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent'
              },
              bottom: 0
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            lineStyle: {
              width: 1
            }
          }))
        };

      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 退出登录
   */
  logout() {
    this.http.delete(`${baseUrl}/auth/token/logout`).subscribe(res => {
      this.tokenService.clear();
      this.commonService.loginBeforeUrl = window.location.hash.slice(1);
      if (this.company == 'mt1d') {
        // 如果是煤田一队进入， 退出返回到煤田一队登录页
        this.router.navigateByUrl('/demo/data-lsd-login');
      } else {
        this.router.navigateByUrl(this.tokenService.login_url);
      }
      this.message.success("退出成功！");
    });
  }

}
