import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { CacheService } from '@delon/cache';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';

import { CommonService } from '../common.service';
import { logUrl } from '../constant';

// const CODEMESSAGE = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private http: _HttpClient,
    public srv: CacheService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {}

  private get notification(): NzNotificationService {
    return this.injector.get(NzNotificationService);
  }

  private get commonService(): CommonService {
    return this.injector.get(CommonService);
  }

  private goTo(url: string) {
    if (!this.commonService.loginBeforeUrl) {
      this.commonService.loginBeforeUrl = window.location.hash.slice(1);
    }
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private putParams(req, event: any, type: string, url: string) {
    if (!req.url.startsWith(logUrl)) {
      let loginData: any = this.srv.getNone('loginData') || {};

      let request_params = {};
      if (req.method == 'GET' || req.method == 'DELETE') {
        req.params
          .keys()
          .forEach(key => (request_params[key] = req.params.get(key)));
      } else {
        request_params = req.body;
      }

      let params = {
        userid: loginData.user_id || '', //用户id
        company: loginData.company_name || '', //公司id
        url: url, //请求url
        method: req.method, //请求方法 GET POST ...
        request_params: JSON.stringify(request_params) || '', //请求的参数 json
        request_time: moment().format('YYYY-MM-DD HH:mm:ss'), //请求时间
        ua: window.navigator.userAgent, //ua信息
        request_id: event.error ? event.error.requestId : '', //返回的request_id
        response_status: event.status || -1, //请求状态 200 500 ... 超时为-1
        response_data: type == 'error' ? JSON.stringify(event.error) : '', //返回数据有则填
        profile: environment.profile, //环境 dev prod test
      };

      this.http.get(logUrl, params, { headers: { token: '' } }).subscribe();
    }
  }

  project = 'bugly';
  host = 'cn-beijing.log.aliyuncs.com';
  logstore = 'web_bug_log';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let ok: string;
    let started: any = Date.now();
    /**
     * 拦截请求
     */
    //设置请求头
    let headers = req.headers;
    const noAuthorUrl = [
      '/auth/mobile/token/sms'
    ]

    //如果预设了 token = '', 则代表该请求不需要token
    if (headers.has('token')) {
      if (headers.get('token') === '') {
        headers = headers.delete('token');
      }
      
    } else {
      // 优先从cookies读取token
      const superviseToken  = document.cookie.replace(/ /g, '').split(';').find(item => item.includes('token')) ? document.cookie.replace(/ /g, '').split(';').find(item => item.includes('token')).split('=')[1]: '';

      if(superviseToken){
        //this.tokenService.set({ token: superviseToken });
        const tenant_id = document.cookie.replace(/ /g, '').split(';').find(item => item.includes('tenant_id')) ? document.cookie.replace(/ /g, '').split(';').find(item => item.includes('tenant_id')).split('=')[1] : '';
        if(tenant_id){
          const res = {
            token:superviseToken,
            tenant_id:tenant_id
          };
          this.tokenService.set(res);
        }else{
          this.tokenService.set({ token: superviseToken });
        }
      }
       const authToken = this.tokenService.get().token;
       
      if (authToken && !noAuthorUrl.includes(req.url.indexOf("?") != -1 ? req.url.split("?")[0] : req.url) ) {
        if(!/_zip_url=true/.test(req.url)){
          headers = headers.append('Authorization', 'Bearer ' + authToken);
        }
      }

    }

    req = req.clone({
      headers,
    });

    /**
     * 拦截响应
     */
    return next.handle(req).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
        // Operation failed; error is an HttpErrorResponse
        error => (ok = 'failed'),
      ),
      finalize(() => {
        const elapsed = Date.now() - started;
        if (elapsed > 500) {
          this.putParams(req, event, 'longtime', req.urlWithParams);
        }
      }),
      //异常处理
      catchError(event => {
        /**
         * 上报异常
         */
        this.putParams(req, event, 'error', event.url);

        switch (event.status) {
          case 400:
          case 401: {
            //登录认证排除的路由(开头部分)
            let loginExcludePaths = [
              '/single-page',
              '/notice-page',
              '/health-report',
              //风险告知卡 查看
              '/riskcard-content', // /riskcard-content/:type/:planId/:objectId
				// 应急预案内容
              '/ledger-content',
			         '/dangercheck-h5', // 隐患排查情况
              '/work-rule', //
              //数据大屏 登录页
              '/data-lsd-login',
              //员工详情
              '/files-info',

              //演示 数据大屏 登录页
              '/demo/data-lsd-login',
              //演示 数据大屏 企业大屏
              '/demo/data-lsd-v2',
              //演示 数据大屏 监管大屏
              '/demo/data-lsd-jg',
              //演示 数据大屏 监管大屏
              '/demo/amap-full',
              //演示 数据大屏 监管大屏
              '/passport/login',
              // 自来水自动登录
              '/water',
              //h5风险点列表
              '/risk-point',
              //h5风险点风险告知卡
              '/risk-card',
              //事故推送详情
              '/accident-case',
              //风险告知卡查看 v2
              '/riskcard-content-v2',
              '/enterprise-apply',
              // 安全专家在线注册
              '/safe-online-registration',
              //安全专家下载页面
              'registra-success'
            ];
            let hasLoginExcludePath = loginExcludePaths.find(path =>
              location.hash.slice(1).startsWith(path),
            );

            if (!hasLoginExcludePath) {
              (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
              this.goTo('/passport/login');
            }
            break;
          }
        }

        // 对于json请求, 则回放一个正常的响应体
        if (req.responseType == 'json' || !req.responseType) {
          const httpResponse = new HttpResponse({
            body: {
              code: 100, //code = 100, 代表这是一个HTTP异常请求, 经过异常处理后的响应体
              msg:
                (event.error
                  ? event.error.msg || '请求发生异常'
                  : event.message) || '请求发生异常',
              response: event,
            },
          });
          // const httpResponse = new HttpResponse({
          //   body: {
          //     code: 1,
          //     msg: (event.error ? (event.error.msg || '请求发生异常') : event.message) || '请求发生异常'
          //   }
          // });
          return of(httpResponse);
        }

        throw event;
      }),
    );
  }
}
