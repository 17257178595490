import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AlienLineBcgComponent } from './alien-line-bcg/alien-line-bcg.component';
import { BigScreenComponent } from './big-screen/big-screen.component';
import { ClockComponent } from './clock/clock.component';
import { FullScreenDirective } from './directives/full-screen.directive';
import {
    PhotoFrameContainerComponent
} from './photo-frame-container/photo-frame-container.component';
import {
    SquareFrameContainerComponent
} from './square-frame-container/square-frame-container.component';

@NgModule({
  declarations: [
    AlienLineBcgComponent,
    BigScreenComponent,
    PhotoFrameContainerComponent,
    SquareFrameContainerComponent,
    ClockComponent,
    FullScreenDirective,
  ],
  imports: [CommonModule, FlexLayoutModule],
  exports: [
    AlienLineBcgComponent,
    BigScreenComponent,
    PhotoFrameContainerComponent,
    SquareFrameContainerComponent,
    ClockComponent,
    FullScreenDirective,
  ],
})
export class WidgetModule {}
