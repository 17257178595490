import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http'
import { filter } from 'rxjs/operators';
@Component({
  selector: 'card-upload',
  templateUrl: './card-upload.component.html',
  styleUrls: ['./card-upload.component.less']
})
export class CardUploadComponent implements OnInit {
  @Input() title: any = '';
  loading = false;
  avatarUrl: string;
  headers: string = '266826407743586304';
  SessionID: any;
  action = "http://api.ahjdev.top/upload.dox";
  data: any = { }

  //审核状态
  status: any = '';

  constructor(private msg: NzMessageService, private http: HttpClient, private cdr: ChangeDetectorRef) { }


  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: any): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.loading = false;
        break;
    }
  }

  getSessionID() {
    var headers = new HttpHeaders().set('__sid', this.headers)
    let params = {
      _METHOD: 'UploadExecutor.getSessionID',
      _DATA: JSON.stringify({})
    }
    let p = new HttpParams({
      fromObject: params
    });
    return this.http.post(`http://api.ahjdev.top/execute`, p, {
      headers: headers
    }).subscribe((res: any) => {
      this.data.SessionID = res.SessionID;
      this.data._Uploader_TaskID = (new Date().getTime() + '' + res.SessionID);
      // this.data.CoverOrBack = type == 'positive' ? 'Cover': 'Back';
    }, error => {
      console.log(error);
    })
  }


  ngOnInit() {
    this.getSessionID();
  
    this.data = {
      CoverOrBack: this.title,
      Version: 1,
      SessionID: '',
      _Uploader_TaskID: '',
      _Method: 'CertificateFile.userUpload',
      _Uploader_Total: '1',
      IdentityType: '01'
    }
  }

}
