import { MockRequest } from '@delon/mock';
type DATAType = {
  home: any;
  domain: any;
  domainLog: any;
  dnsGroup: any;
  dnsSetting: any;
  apis: any;
  region: any;
  summary_status: any;
  summary_month: any;
  summary_healthy: any;
  help_categories: any;
  help_product_menus: any;
  notifications: any;
  user: any;
  userDefaultAvatar: any;
  finance: any;
};
const DATA: DATAType = {
  home: null,
  domain: null,
  domainLog: null,
  dnsGroup: null,
  dnsSetting: null,
  apis: null,
  region: null,
  summary_status: null,
  summary_month: null,
  summary_healthy: null,
  help_categories: null,
  help_product_menus: null,
  notifications: null,
  user: null,
  userDefaultAvatar: null,
  finance: null,
};
// #region user

function user() {
  if (DATA.user) return DATA.user;
  DATA.user = {
    id: 1,
    enterpriseYear: 3,
    enterpriseSize: 1,
    mainBizCategory: 1,
    biz: 'ng-alain',
    website: 'https://ng-alain.com/',
    nationalityCode: '中国',
    geo: '310105',
  };
  return DATA.user;
}

export const BASICINFO = {
  '/user-ms': () => user(),
  'POST /user-ms': (req: MockRequest) => (DATA.user = req.body),
  '/sts': {
    code: 0,
    data: {
      accessKeyId: 'LTAIVBm1yiqtxFnl',
      accessKeySecret: 'Uw4dMdZ1LvZZircPbeaHRQc8TDqiHL',
      expiration: '2020-11-04T09:58:12Z',
    }
  }
};
