import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { baseUrl, CommonService, MessageService, StartupService,ReliBaseUrl } from '@core';
import { ReuseTabService } from '@delon/abc';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { _HttpClient, SettingsService } from '@delon/theme';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'header-userrapid',
  template: `
    <nz-dropdown nzPlacement="bottomRight">
      <div
        class="alain-default__nav-item d-flex align-items-center px-sm"
        nz-dropdown
      >
        <nz-avatar
          [nzSrc]="commonService.OssSignatureUrl(settings.user.avatar)"
          nzSize="small"
          class="mr-sm"
        ></nz-avatar>
        {{ settings.user.name }}
      </div>
      <div nz-menu class="width-sm">
        <div nz-menu-item (click)="logout()">
          <i nz-icon type="logout" class="mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserRapidComponent {
  constructor(
    public settings: SettingsService,
    public commonService: CommonService,
    private router: Router,
    private http: _HttpClient,
    private httpClient: HttpClient,
    public msg: MessageService,
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    private el: ElementRef,
  ) { }
  switchVisible = false;
  companyData: any = [];
  userId = null;
  domain = '';
  loading = false;
  /**
   * 切换企业
   */
  switchOpen() {
    this.switchVisible = true;
    this.http.get<any>(`${baseUrl}/admin/v1/users/company`).subscribe(res => {
      if (res.code === 0) {
        this.companyData = res.data || [];
        this.companyData.forEach(e => {
          if (e.isNow === 1) {
            e.disabled = true;
          } else {
            e.disabled = false;
          }
        });
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  get companyType(){
    return this.companyData.filter(item=>item.id == this.userId)[0].companyType;
  }
  /**
   * 切换企业保存
   */
  async switchSave() {
    await this.getDomain();
    this.loading = true;
    let headers = new HttpHeaders()
      .set('Authorization', 'Basic eXVrb246eXVrb24')
      .set('token', '');
      if(this.companyType =='EntG'){  
        headers = new HttpHeaders()
        .set('Authorization', 'Basic eXVrb246eXVrb24')
        .set('Company-Type', 'EntG')
        .set('token', '');
        
      }
      console.log(this.tokenService.get().token,55555)
    this.httpClient
      .post<any>(
        `${baseUrl}/auth/mobile/token/sms?code=${this.tokenService.get().token}&type=Co,${this.userId}`,{}, { headers }
      )
      .subscribe(res => {
        this.loading = false;
        if (res.code === 0) {
          // 清空路由复用信息
          this.reuseTabService.clear();
          res.token = res.access_token;
          this.tokenService.set(res);
          // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
         
            this.startupSrv.checkToken(res).then(() => {
              // this.router.navigateByUrl('/');
              document.cookie = `token=${res.access_token}; domain=.xinanyun.cn`;
              if(this.companyType =='EntG'){   
               //document.cookie = `token=${res.access_token}; domain=localhost`;
                window.location.href = `${ReliBaseUrl}/#/dashboard`;
              }else{
                if (this.domain === '' || this.domain == null) {
                  window.location.reload();
                } else {
                  const can = window.location.href.split('#');
                  this.domain.replace('https://', '');
                  this.domain.replace('http://', '');
                  const domain = this.domain + can[1];
                  window.location.href = 'http://' + domain;
                }
              }
            });
         
         
        } else {
          this.msg.error(res.msg);
          return;
        }
      });
  }
  logout() {
    this.http.delete(`${baseUrl}/auth/token/logout`).subscribe(res => {
      // if(res.code == 0){
      // 清除cookies的token   
      document.cookie = `token=; domain=.xinanyun.cn`;
      this.tokenService.clear();
      this.router.navigateByUrl(this.tokenService.login_url);
      this.msg.error('退出成功！');
      // }else{
      //   this.msg.error(res.msg);
      //   return;
      // }
    });
  }

  getDomain() {
    const companyId = this.settings.user.company_id;
    return this.http
      .get<any>(
        `${baseUrl}/basics/v1/basicsinfo/getLoginDomain?companyId=` + companyId,
      )
      .toPromise()
      .then(res => {
        this.domain = res.data;
      });
  }
}
