import { Component, ChangeDetectionStrategy, ChangeDetectorRef,OnInit } from '@angular/core'; import { MessageService } from '@core';
import * as distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivationEnd } from '@angular/router';
import { NoticeItem, NoticeIconList } from '@delon/abc';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { mockUrl, baseUrl, access_token } from '@core';
/**
 * 菜单通知
 */
@Component({
  selector: 'header-notify',
  template: `
  <notice-icon
    [data]="data"
    [count]="count"
    [loading]="loading"
    btnClass="alain-default__nav-item"
    btnIconClass="alain-default__nav-item-icon"
    (select)="select($event)"
    (clear)="clear($event)"
    (popoverVisibleChange)="loadData()"></notice-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNotifyComponent implements OnInit{
  data: NoticeItem[] = [
    {
      title: '通知',
      list: [],
      emptyText: '你已查看所有通知',
      emptyImage:
        'https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg',
      clearText: '更多通知',
    },
    {
      title: '消息',
      list: [],
      emptyText: '您已读完所有消息',
      emptyImage:
        'https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg',
      clearText: '更多消息',
    },
    {
      title: '待办',
      list: [],
      emptyText: '你已完成所有待办',
      emptyImage:
        'https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg',
      clearText: '更多待办',
    },
  ];
  count = 0;
  loading = false;

  constructor(private msg: MessageService, private cdr: ChangeDetectorRef, private http: _HttpClient, private router: Router) { }

  private updateNoticeData(notices: NoticeIconList[]): NoticeItem[] {
    const data = this.data.slice();
    data.forEach(i => (i.list = []));

    notices.forEach(item => {
      const newItem = { ...item };
      if (newItem.createTime)
        newItem.createTime = distanceInWordsToNow(item.createTime, {
          locale: (window as any).__locale__,
        });
      if (newItem.extra && newItem.status) {
        newItem.color = {
          todo: undefined,
          processing: 'blue',
          urgent: 'red',
          doing: 'gold',
        }[newItem.status];
      }
      data.find(w => w.title === newItem.type).list.push(newItem);
    });
    return data;
  }

  loadData() {
    if (this.loading) return;
    this.loading = true;

    this.http.get<any>(`${baseUrl}/cooperation/v1/schedules/remind`).subscribe(res => {

      this.loading = false;
      if (res.code == 0) {
        let notice = [];
        let msg = []
        let todo = [];
        let aggregate = [];  
        this.count = res.data.count;
        res.data.toDo.forEach((item, index) => {
          if (item.title == '通知') {
            item.list.forEach(ele => {
              ele.type = '通知';
              ele.avatar =
                'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png';
              ele.datetime = ele.createTime;
            });
            notice = item.list
          } else if (item.title == '消息') {
            item.list.forEach(ele => {
              ele.type = '消息';
              ele.avatar =
                'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png';
              ele.datetime = ele.createTime;
            });
            msg = item.list
          } else {
            item.list.forEach(ele => {
              ele.type = '待办';
              ele.avatar =
                'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png';
              ele.datetime = ele.createTime;
            });
            todo = item.list
          }
        });
        aggregate = [...notice, ...msg, ...todo]

        this.data = this.updateNoticeData(aggregate);
        this.loading = false;
        this.cdr.detectChanges();


      } else {
        this.msg.error(res.msg || '加载失败');
      }

    });

  }

  clear(type: string) {
    let url;
    if (type == '通知') {
      url = 'notice'
    } else if (type == '消息') {
      url = 'news'
    } else {
      url = 'todo'
    }
    this.router.navigateByUrl(`/message/${url}/0`);
  }

  select(res: any) {
    let type = res.item.type;
    let url;
    if (type == '通知') {
      url = 'readDetail'
    } else if (type == '消息') {
      url = 'readDetailMsg'
    } else {
      return;
    }
    this.router.navigateByUrl(`/message/${url}/${res.item.id}`);
  }

  ngOnInit () {
    this.loadData()
  }
}
