//import { IRes } from 'app/routes/passport/app-register/app-register.model';
import { IRes } from '../../base/base.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';

import { BaseService } from './base.service';

export class BaseApiService extends BaseService {
  baseUrl: string;
  http: HttpClient;
  constructor(protected injector: Injector) {
    super(injector);
    this.http = injector.get(HttpClient);
    this.baseUrl = this.env.domain;
  }

  getApiUrl(path: string) {
    return this.baseUrl + ('/' + path).replace(/\/+/g, '/');
  }

  get<T>(url: string, params: any = {}): Observable<T> {
    return this.http
      .get<IRes<T>>(url, { params })
      .pipe(
        map(res => this.checkError<T>(res)),
        map(res => this.extractData(res)),
      );
  }

  post<T>(url: string, params: any = {}) {
    return this.http.post<IRes<T>>(url, params).pipe(
      map(res => this.checkError(res)),
      map(res => this.extractData(res)),
    );
  }

  checkError<T>(res: IRes<T>): IRes<T> {
    if (res.code === 0) {
      return res;
    }
    throw res;
  }

  extractData<T>(res: IRes<T>): T {
    return res.data;
  }
}
